import React, {useState} from 'react';
import {
    Button,
    Grid,
    Paper,
    Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatesPeriodSelector({startDateStr, endDateStr, onChange}) {
    const [mirroredProps, setMirroredProps] = useState({startDateStr, endDateStr});
    const [startDate, setStartDate] = useState(dayjs(startDateStr ? startDateStr : '01/01/2015'));
    const [endDate, setEndDate] = useState(dayjs(endDateStr ? endDateStr : new Date()));

    const changeStartDate = (val) => {
        setStartDate(val);
        onChange({
            startDate: val.$d,
            endDate: endDate.$d
        });
    }

    const changeEndDate = (val) => {
        setEndDate(val);
        onChange({
            startDate: startDate.$d,
            endDate: val.$d
        });
    }

    const setAllPeriod = () => {
        const start = dayjs(startDateStr ? startDateStr : '01/01/2015');
        const end = dayjs(endDateStr ? endDateStr : new Date());
        setStartDate(start);
        setEndDate(end);
        onChange({
            startDate: start.$d,
            endDate: end.$d
        });
    };

    const setLastDays = (days) => () => {
        let d = new Date();
        d.setDate(d.getDate() - days);
        setStartDate(dayjs(d));
        setEndDate(dayjs(new Date()));
        onChange({
            startDate: d,
            endDate: dayjs(new Date()).$d
        });
    };

    const daysButtonDisabled = (days) => {
        if (!endDateStr) {
            return false;
        }
        let d = new Date();
        d.setDate(d.getDate() - days);
        return new Date(endDateStr) <= d;
    }

    if (mirroredProps.startDateStr !== startDateStr || mirroredProps.endDateStr !== endDateStr) {
        setMirroredProps({startDateStr, endDateStr});
        setAllPeriod(); // May be only start date. if it is early by state
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="en-gb">
            <Grid item xs={12} lg={6}>
                <Paper sx={{p:2}}>
                    <Grid container alignItems="center">
                        <Grid item xs="auto" sx={{mr:1}}>
                            <Typography color="primary" component="b">
                                Start date:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker value={ startDate } onChange={changeStartDate} />
                            </DemoContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper sx={{p:2}}>
                    <Grid container alignItems="center">
                        <Grid item xs="auto" sx={{mr:1}}>
                            <Typography color="primary" component="b">
                                End date:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker value={ endDate } onChange={changeEndDate} />
                            </DemoContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Paper sx={{p:2, textAlign: 'center'}}>
                        <Typography color="primary" component="b" sx={{mr: 1}}>Presets:</Typography>
                            {/* <ButtonGroup variant="text" aria-label="text button group"> */}
                                <Button variant="outlined" sx={{mr: 1, mb:1}} onClick={ setAllPeriod }>All pool data</Button>
                                <Button variant="outlined" sx={{mr: 1, mb:1}} onClick={ setLastDays(7) } disabled={ daysButtonDisabled(7) }>Last week</Button>
                                <Button variant="outlined" sx={{mr: 1, mb:1}} onClick={ setLastDays(14) } disabled={ daysButtonDisabled(14) }>Last 2 weeks</Button>
                                <Button variant="outlined" sx={{mr: 1, mb:1}} onClick={ setLastDays(30) } disabled={ daysButtonDisabled(30) }>Last 30 days</Button>
                                <Button variant="outlined" sx={{mr: 1, mb:1}} onClick={ setLastDays(60) } disabled={ daysButtonDisabled(60) }>Last 60 days</Button>
                                <Button variant="outlined" sx={{mr: 1, mb:1}} onClick={ setLastDays(90) } disabled={ daysButtonDisabled(90) }>Last 90 days</Button>
                            {/* </ButtonGroup> */}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}