import React, {Fragment} from 'react';
import {
    Paper,
    Grid,
    Divider,
    Collapse,
    IconButton,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import {
    ArrowCircleUp,
    ArrowCircleDown,
    CurrencyExchange
} from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Reserve from '../../features/assets/Reserve.jsx';
import AssetCode from '../../features/assets/AssetCode.jsx';
import WalletPooolTableBadge from './WalletPooolTableBadge.jsx';

const textTCStyle = { fontSize: 12, px: '6px' };
const badgeStyle = {whiteSpace: 'nowrap', fontSize: 12};

export default function WalletPoolTableRow({walletPoolHistoryItem, opened = false, diffHistoryItem}) {
    const {
        effect,
        exchangeRates,
        poolData,
        walletData,
    } = walletPoolHistoryItem || {};

    poolData.tradedAmount -= diffHistoryItem?.poolData?.tradedAmount || 0;
    Object.keys(poolData.traded).forEach(
        asset => poolData.traded[asset] -= (diffHistoryItem?.poolData?.traded || {})[asset] || 0
    );

    poolData.earnedAmount -= diffHistoryItem?.poolData?.earnedAmount || 0;
    Object.keys(poolData.earned).forEach(
        asset => poolData.earned[asset] -= (diffHistoryItem?.poolData?.earned || {})[asset] || 0
    );

    let {
        currentShares,
        earned,
        earnedAmount,
        currentReserves,
        reservesPrice,
        incomes,
        deposited,
        withdrawed,
        depositedAmount,
        withdrawedAmount
    } = walletData || {};
    
    earnedAmount -= diffHistoryItem?.walletData?.earnedAmount || 0;
    Object.keys(earned).forEach(
        asset => earned[asset] -= (diffHistoryItem?.walletData?.earned || {})[asset] || 0
    );
    
    depositedAmount -= diffHistoryItem?.walletData?.depositedAmount || 0;
    Object.keys(deposited).forEach(
        asset => deposited[asset] -= (diffHistoryItem?.walletData?.deposited || {})[asset] || 0
    );
    withdrawedAmount -= diffHistoryItem?.walletData?.withdrawedAmount || 0;
    Object.keys(withdrawed).forEach(
        asset => withdrawed[asset] -= (diffHistoryItem?.walletData?.withdrawed || {})[asset] || 0
    );
    Object.keys(incomes).forEach(
        asset => incomes[asset] -= (diffHistoryItem?.walletData?.incomes || {})[asset] || 0
    );

    const [open, setOpen] = React.useState(opened);

    return (
        <Fragment>
            <TableRow key={ effect.created_at }>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell size="small" sx={textTCStyle}>{ 
                    walletPoolHistoryItem.date
                        .toLocaleDateString('en-gb', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            // hour: 'numeric',
                            // minute: 'numeric',
                            // second: 'numeric'
                        })
                }</TableCell>
                <TableCell title={ effect.type } size="small" sx={textTCStyle}>{
                    effect.type === 'liquidity_pool_deposited'
                    ? (<ArrowCircleUp />)
                    : (effect.type === 'liquidity_pool_withdrew'
                        ? (<ArrowCircleDown />)
                        : (<CurrencyExchange />)
                    )
                }</TableCell>
                {/* <TableCell size="small" sx={textTCStyle}>{
                    poolData.currentReserves
                        .map(r => (<Fragment key={r.asset}><Reserve reserve={r} /><br /></Fragment>))
                }</TableCell> */}
                {/* <TableCell size="small" sx={textTCStyle}>{ poolData.currentShares }</TableCell> */}
                {/* <TableCell size="small" sx={textTCStyle}>{
                    Object.keys(poolData.traded).map(
                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ poolData.traded[asset].toFixed(7) }<br /></Fragment>)
                    )
                }</TableCell> 
                <TableCell size="small" sx={textTCStyle}>{
                    Object.keys(poolData.earned).map(
                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ poolData.earned[asset].toFixed(7) }<br /></Fragment>)
                    )
                }</TableCell> */}
                <TableCell size="small" sx={textTCStyle}>{ currentShares.toFixed(7) }<br />/{ poolData.currentShares }</TableCell>
                <TableCell size="small" sx={textTCStyle}>{ (currentShares/poolData.currentShares*100).toFixed(3) }%</TableCell>
                <TableCell size="small" sx={textTCStyle} align="right">{
                    // Object.keys(earned).map(
                    //     asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ earned[asset].toFixed(7) }<br /></Fragment>)
                    // )
                    earnedAmount.toFixed(7)
                }&nbsp;USDC</TableCell>
                <TableCell size="small" sx={textTCStyle}>{
                    Object.keys(incomes).map(
                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ incomes[asset].toFixed(7) }<br /></Fragment>)
                    )
                }</TableCell>
                <TableCell size="small" sx={textTCStyle}>{
                    currentReserves.length > 0
                    ? currentReserves
                        .map(r => (<Fragment key={r.asset}><Reserve reserve={r} /><br /></Fragment>))
                    : '-'
                }</TableCell>
                <TableCell size="small" sx={textTCStyle} align="right">{ reservesPrice }&nbsp;USDC
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{
                        backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],

                    }}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={9}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {/* <Container sx={{ mt: 1, mb: 1 }}> */}
                        <Grid sx={{ mt: 0.1, mb: 1 }} container spacing={2}>
                            <Grid item sx={badgeStyle} xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        p: 1,
                                        fontSize: 12,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography component="span" sx={badgeStyle}>
                                            {walletPoolHistoryItem.date
                                                .toLocaleDateString('en-gb', {
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric'
                                                })}
                                    </Typography>
                                    <Typography noWrap>
                                        <Typography component="span" color="primary" sx={badgeStyle}>
                                            Pool shares:
                                        </Typography>&nbsp;<Typography component="span" sx={badgeStyle}>
                                            { poolData.currentShares }
                                        </Typography>&nbsp;<Typography component="span" color="primary" sx={badgeStyle}>
                                            Trustlines:
                                        </Typography>&nbsp;
                                        <Typography component="span" sx={{ fontSize: 12 }}>
                                            { effect.liquidity_pool.total_trustlines }
                                        </Typography>
                                    </Typography>
                                    <Typography>
                                        <Typography component="span" color="primary" sx={badgeStyle}>
                                            Pool reserves:
                                        </Typography>&nbsp;<Typography component="span" sx={{fontSize: 12}}>
                                            {
                                                poolData.currentReserves
                                                    .map((r, idx) => (<Fragment key={r.asset}>{ idx > 0 && <span>&nbsp;+&nbsp;</span> }<Reserve reserve={r} /></Fragment>))
                                            } =&nbsp;{ poolData.reservesPrice.toFixed(7) }&nbsp;USDC
                                        </Typography>
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item sx={badgeStyle} xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        p: 1,
                                        fontSize: 12,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Grid container>
                                        <Grid item sx={badgeStyle} xs={12} lg={6}>
                                            <Typography color="primary" component="b">
                                                Exchange rates:
                                            </Typography>
                                            <Typography variant="p" component="div">
                                                {
                                                    Object.keys(exchangeRates).map(
                                                        asset => (<Fragment key={asset}>1&nbsp;<AssetCode asset={asset} />&nbsp;=&nbsp;{ exchangeRates[asset].toFixed(7) }&nbsp;USDC<br /></Fragment>)
                                                    )
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={badgeStyle} xs={12} lg={6}>
                                            <Typography color="primary" component="b">
                                                &nbsp;
                                            </Typography>
                                            {/* <Typography variant="p" component="div" sx={{ flex: 1 }}>
                                                {
                                                    Object.keys(exchangeRates).map(
                                                        asset => (<Fragment key={asset}>1&nbsp;USDC&nbsp;=&nbsp;{ (1 / exchangeRates[asset]).toFixed(7) }&nbsp;<AssetCode asset={asset} /><br /></Fragment>)
                                                    )
                                                }
                                            </Typography>
                                            <Divider sx={{ flex: 1 }} /> */}
                                            <Typography variant="p" component="div">
                                                1&nbsp;<AssetCode asset={ Object.keys(exchangeRates)[0] } />&nbsp;=&nbsp;{ (Object.values(exchangeRates)[0]/Object.values(exchangeRates)[1]).toFixed(7) }&nbsp;<AssetCode asset={ Object.keys(exchangeRates)[1] } />
                                                <br />
                                                1&nbsp;<AssetCode asset={ Object.keys(exchangeRates)[1] } />&nbsp;=&nbsp;{ (Object.values(exchangeRates)[1]/Object.values(exchangeRates)[0]).toFixed(7) }&nbsp;<AssetCode asset={ Object.keys(exchangeRates)[0] } />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            
                            <WalletPooolTableBadge
                                header={ <>Pool trading<br />volume:<br /></> }
                                total={ <>{ (poolData.tradedAmount).toFixed(7) }&nbsp;USDC</> }
                            >
                                {
                                    Object.keys(poolData.traded).map(
                                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ 
                                            poolData.traded[asset].toFixed(7)
                                        }<br /></Fragment>)
                                    )
                                }
                            </WalletPooolTableBadge>

                            <WalletPooolTableBadge
                                header={ <>Commission<br />earnings (pool):</> }
                                total={ <>{ poolData.earnedAmount.toFixed(7) }&nbsp;USDC</> }
                            >
                                {
                                    Object.keys(poolData.earned).map(
                                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{
                                            poolData.earned[asset].toFixed(7) 
                                        }<br /></Fragment>)
                                    )
                                }
                            </WalletPooolTableBadge>

                            <WalletPooolTableBadge
                                header={ <>Commission<br />earnings (wallet):</> }
                                total={ <>{ earnedAmount.toFixed(7) }&nbsp;USDC</> }
                            >
                                {
                                    Object.keys(earned).map(
                                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{
                                            earned[asset].toFixed(7)
                                        }<br /></Fragment>)
                                    )
                                }
                            </WalletPooolTableBadge>

                            <WalletPooolTableBadge
                                header={ <>Wallet<br />deposited:</> }
                                total={ <>{ depositedAmount.toFixed(7) }&nbsp;USDC</> }
                            >
                                {
                                    Object.keys(deposited).map(
                                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ deposited[asset].toFixed(7) }<br /></Fragment>)
                                    )
                                }
                            </WalletPooolTableBadge>

                            { withdrawedAmount > 0 && (
                                <WalletPooolTableBadge
                                    header={ <>Wallet<br />withdrawed:</> }
                                    total={ <>{ withdrawedAmount.toFixed(7) }&nbsp;USDC</> }
                                >
                                    {
                                        Object.keys(withdrawed).map(
                                            asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ withdrawed[asset].toFixed(7) }<br /></Fragment>)
                                        )
                                    }
                                </WalletPooolTableBadge>
                            ) }

                            <Grid item sx={badgeStyle} xs={12} lg={2}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography color="primary" component="b" aria-label="profit">
                                        Profit:
                                    </Typography>
                                    <Typography variant="p" component="div" align='right'>
                                        { (reservesPrice - (diffHistoryItem?.walletData?.reservesPrice || 0)).toFixed(7) }&nbsp;USDC
                                    </Typography>
                                    { withdrawedAmount > 0 && (<Typography variant="p" component="div" align='right'>
                                        +{ withdrawedAmount.toFixed(7) }&nbsp;USDC
                                    </Typography> )}
                                    <Typography variant="p" component="div" align='right' gutterBottom>
                                        -{ depositedAmount.toFixed(7) }&nbsp;USDC
                                    </Typography>
                                    <Divider />
                                    <Typography variant="p" component="div" align='right'>
                                        { (reservesPrice - (diffHistoryItem?.walletData?.reservesPrice || 0) - depositedAmount + withdrawedAmount).toFixed(7) }&nbsp;USDC
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}
