import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootSlice';
import walletReducer from '../features/wallet/walletSlice';
import walletconnectReducer from '../features/walletconnect/walletconnectSlice';
import poolsReducer, { fetchCommonPools, fetchCustomPools } from '../features/pools/poolsSlice';
import pricesReducer from '../features/prices/pricesSlice';
import poolsHistoryReducer from '../features/poolsHistory/poolsHistorySlice';
import assetsReducer, { fetchCustomAssets } from '../features/assets/assetsSlice';
import freighterReducer from '../features/freighter/freighterSlice';

const store = configureStore({
    reducer: {
        root: rootReducer,
        assets: assetsReducer,
        wallet: walletReducer,
        pools: poolsReducer,
        prices: pricesReducer,
        poolsHistory: poolsHistoryReducer,
        walletconnect: walletconnectReducer,
        freighter: freighterReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

store.dispatch(fetchCommonPools());
store.dispatch(fetchCustomAssets());
store.dispatch(fetchCustomPools());

export default store;