import React, {useState, useMemo} from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Grid,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel
    // TableFooter
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import AssetCode from '../assets/AssetCode.jsx';
import AssetCodeIcon from '../assets/AssetCodeIcon.jsx';

import Claimable2PayAsset from './Claimable2PayAsset.jsx';

import WalletAssetBadge from './WalletAssetBadge.jsx';
import AssetsPie from './AssetsPie.jsx';

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const textTCStyle = { px: '6px' };

function notBeforeDateFromPredicate(predicate) {
    if (predicate?.not?.abs_before) {
        const date = new Date(predicate.not.abs_before);
        return date > new Date() ? date : null;
    }

    if (predicate?.and) {
        return predicate.and.map(notBeforeDateFromPredicate).reduce((maxDate, date) => ((date && (!maxDate || maxDate < date)) ? date : maxDate), null);
    }

    if (predicate?.or) {
        return predicate.or.map(notBeforeDateFromPredicate).reduce((minDate, date) => ((date && (!minDate || minDate > date)) ? date : minDate), null);
    }
    return null;
}

function getClaimDate(cb, accountId) {
    const asClaimant = cb.claimants.find(({destination}) => (destination === accountId));
    if (!asClaimant?.predicate) {
        return null;
    }
    return notBeforeDateFromPredicate(asClaimant.predicate);
}

function descendingComparator(a, b, orderBy) {
    if (['amount', 'price'].includes(orderBy)) {
        return (parseFloat(b[orderBy]) || 0) - (parseFloat(a[orderBy]) || 0); //isNaN
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
      id: 'lastModifiedDate',
      numeric: false,
      label: 'Date',
    },
    {
      id: 'asset',
      numeric: false,
      label: 'Asset',
    },
    {
      id: 'amount',
      numeric: true,
      label: 'Amount',
    },
    {
      id: 'claimDate',
      numeric: false,
      label: 'Claimable',
    },
    {
      id: 'price',
      numeric: true,
      label: 'Price',
    },
  ];

export default function WalletClaimableDashboard() {
    const accountId = useSelector((state) => state.wallet.accountId);
    const balances = useSelector((state) => state.wallet.balances)
        .filter(b => b.asset_type !== 'liquidity_pool_shares')
        .map(b => ({
            ...b,
            asset: b.asset_type === 'native' ? 'native' : `${b.asset_code}:${b.asset_issuer}`
        }));
    const lastPrices = useSelector((state) => state.prices.lastPrice);
    const assets = Object.keys(useSelector(state => state.assets.assets));
    const claimableBalances = useSelector((state) => state.wallet.claimableBalances)
        .map(cb => ({
            ...cb,
            lastModifiedDate:new Date(cb.last_modified_time),
            claimDate: getClaimDate(cb, accountId),
            isAssetInWallet: !!balances.find(b => b.asset === cb.asset),
            isLoadedAsset: assets.includes(cb.asset),
            price: isNaN(parseFloat(lastPrices[cb.asset])) ? NaN : parseFloat(lastPrices[cb.asset]) * parseFloat(cb.amount)
        }));
        // .sort((a, b) => (b.lastModifiedDate - a.lastModifiedDate));
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('lastModifiedDate');

    const handleRequestSort = (event, property) => {
        const isAsc = (orderBy === property && order === 'asc') || (orderBy !== property && ['lastModifiedDate'].includes(property));
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const total = claimableBalances.reduce((total, {price}) => (isNaN(price) ? total : total + price), 0);
    
    // console.log(balances);

    // console.log(claimableBalances);

    const tableRows = useMemo(
        () =>
          stableSort(claimableBalances, getComparator(order, orderBy)),
          [order, orderBy, claimableBalances],
    );

    return (<>
        <Claimable2PayAsset />
        <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
                <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'left'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        component="th"
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { tableRows
                                .map(cb => (
                                    <TableRow key={ cb.id }>
                                        <TableCell size="small" sx={textTCStyle}>
                                            { cb.lastModifiedDate
                                                .toLocaleDateString('en-gb', {
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric'
                                                })
                                            }
                                        </TableCell>
                                        <TableCell size="small" sx={textTCStyle}>
                                            { (true || cb.isAssetInWallet || cb.isLoadedAsset)
                                                ? <AssetCodeIcon asset={ cb.asset } showSite={ true } />
                                                : <AssetCode asset={ cb.asset } />
                                            }
                                        </TableCell>
                                        <TableCell size="small" align="right" sx={textTCStyle}>
                                            { cb.amount }
                                        </TableCell>
                                        <TableCell size="small" sx={textTCStyle}>
                                            { cb.claimDate
                                                ? cb.claimDate
                                                    .toLocaleDateString('en-gb', {
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric'
                                                    })
                                                : '+'
                                            }
                                        </TableCell>
                                        <TableCell size="small" align="right" sx={textTCStyle}>
                                            { isNaN(cb.price) ? '-' : (cb.price.toFixed(7) + ' USDC') }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} />
                                <TableCell component="th" align="right" size="small" sx={textTCStyle}>
                                    Total:
                                </TableCell>
                                <TableCell component="th" align="right" size="small" sx={{...textTCStyle, fontWeight: 600}}>
                                    { total.toFixed(7)} USDC
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
        {/* {
            (balances || []).filter(b => (b.asset_type !== 'liquidity_pool_shares')).length > 0 && (
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                        height: 675,
                        textAlign: 'center'
                        }}
                    >
                        <Box sx={{p:2, height: 675, maxHeight:675, maxWidth:640, display: 'inline-block', width: '100%' }}>
                            <AssetsPie />
                        </Box>
                    </Paper>
                </Grid>
            )
        } */}
        {/* <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
            { 
                assetsArray.slice(0, 6)
                    .map((balance, idx) => (<Grid key={ balance.asset_issuer || 'native' } item xs={12} md={6} lg={6}>
                        <Paper
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 210,
                            }}
                        >
                            <WalletAssetBadge balance={ balance } />
                        </Paper>
                    </Grid>))
            }
            </Grid>
        </Grid>
        { assetsArray.length > 6 && assetsArray.slice(6)
            .map((balance, idx) => (<Grid key={ balance.asset_issuer || 'native' } item xs={12} md={6} lg={3}>
                <Paper
                    sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    }}
                >
                    <WalletAssetBadge balance={ balance } />
                </Paper>
            </Grid>)) 
        } */}
    </>);
}