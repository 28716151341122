import { getAssetPrice, getReservesByRatesPrice } from './assetPrices';

export function getWalletPoolHistoryState(
    prevState,
    currentState,
    priceHistory,
    pool,
    prewStateItem,
    wallet
) {
    const assetsConfigDummie = {
        [pool.reserves[0].asset]: 0,
        [pool.reserves[1].asset]: 0
    };

    let effect = currentState.effect;


    const exchangeRates = {
        [pool.reserves[0].asset]: getAssetPrice(priceHistory, effect.created_at, pool.reserves[0].asset),
        [pool.reserves[1].asset]: getAssetPrice(priceHistory, effect.created_at, pool.reserves[1].asset)
    };

    let earnedDiff = Object.fromEntries(
        Object.entries(currentState.earned)
            .map(([asset, amount]) => ([
                asset,
                amount - (prevState ? prevState.earned[asset] : 0)
            ]))
    );
    let tradedDiff = Object.fromEntries(
        Object.entries(currentState.traded)
            .map(([asset, amount]) => ([
                asset,
                amount - (prevState ? prevState.traded[asset] : 0)
            ]))
    );

    let stepPoolEarned = getReservesByRatesPrice(
        exchangeRates,
        Object.entries(earnedDiff).map(([asset, amount]) => ({asset, amount}))
    );

    let newPoolState = {
        date: new Date(effect.created_at),
        effect,
        exchangeRates,
        poolData: {
            currentShares: currentState.currentShares,
            currentReserves: [].concat(currentState.currentReserves),
            earned: {...currentState.earned},
            earnedAmount: (prewStateItem?.poolData?.earnedAmount || 0) + stepPoolEarned,
            traded: {...currentState.traded},
            tradedAmount: (prewStateItem?.poolData?.tradedAmount || 0) + getReservesByRatesPrice(
                exchangeRates,
                Object.entries(tradedDiff).map(([asset, amount]) => ({asset, amount}))
            ),
            reservesPrice: getReservesByRatesPrice(exchangeRates, currentState.currentReserves)
        }
    };
    if (!wallet) {
        return newPoolState;
    }
    let walletData = {
        currentShares: prewStateItem?.walletData?.currentShares || 0,
        earned: prewStateItem ? {...prewStateItem.walletData.earned} : {...assetsConfigDummie},
        earnedAmount: prewStateItem?.walletData?.earnedAmount || 0,
        deposited: prewStateItem ? {...prewStateItem.walletData.deposited} : {...assetsConfigDummie},
        depositedAmount: prewStateItem?.walletData?.depositedAmount || 0,
        withdrawed: prewStateItem ? {...prewStateItem.walletData.withdrawed} : {...assetsConfigDummie},
        withdrawedAmount: prewStateItem?.walletData?.withdrawedAmount || 0,
        incomes: {...assetsConfigDummie},
    }
    // if no prevState or prewStateItem - no earning
    if (prevState && prewStateItem) {
        currentState.currentReserves.forEach(r => {
            walletData.earned[r.asset] +=
                (currentState.earned[r.asset] - prevState.earned[r.asset])
                * prewStateItem.walletData.currentShares / prevState.currentShares;
        });
        walletData.earnedAmount += stepPoolEarned * prewStateItem.walletData.currentShares / prevState.currentShares;
    }
    if (effect.account === wallet) {
        if (effect.type === 'liquidity_pool_deposited') {
            walletData.currentShares += parseFloat(effect.shares_received);
            effect.reserves_deposited.forEach(r => {
                walletData.deposited[r.asset] += parseFloat(r.amount);
            });
            walletData.depositedAmount += getReservesByRatesPrice(
                exchangeRates,
                effect.reserves_deposited
            );
        }
        if (effect.type === 'liquidity_pool_withdrew') {
            walletData.currentShares -= parseFloat(effect.shares_redeemed);
            effect.reserves_received.forEach(r => {
                walletData.withdrawed[r.asset] += parseFloat(r.amount);
            });
            walletData.withdrawedAmount += getReservesByRatesPrice(
                exchangeRates,
                effect.reserves_received
            );
        }
    }

    walletData.currentReserves = currentState.currentReserves.map(r => ({
        ...r,
        amount: (walletData.currentShares/currentState.currentShares*r.amount).toFixed(7)
    }));

    walletData.currentReserves.forEach(r => {
        walletData.incomes[r.asset] =
            parseFloat(r.amount)
            - walletData.deposited[r.asset]
            + walletData.withdrawed[r.asset];
    });

    walletData.reservesPrice = getReservesByRatesPrice(exchangeRates, walletData.currentReserves).toFixed(7);

    newPoolState.walletData = walletData;

    return newPoolState;
}

export function getPoolHistorySlice(poolHistory, startDate, endDate) {
    let limitEndDate = new Date(endDate.valueOf());
    limitEndDate.setDate(endDate.getDate() +1);
    return [
        poolHistory.filter(ph => (ph.date >= startDate && ph.date <= limitEndDate)),
        poolHistoryItemDummie(poolHistory.filter(ph => (ph.date < startDate)).pop())
    ];
}

function poolHistoryItemDummie(poolHistoryItem) {
    return poolHistoryItem ? {
        ...poolHistoryItem,
        poolData: {
            ...poolHistoryItem?.poolData
        },
        walletData: {
            ...poolHistoryItem?.walletData
        }
    }
    : {poolData: {}, walletData: {}};
}