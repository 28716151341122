import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toml from 'toml';
import LZString from 'lz-string';
import { CACHE_VERSION } from '../../app/constants';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

const unpacked = LZString.decompress(localStorage.getItem('customAssets'));
const parsed = unpacked ? JSON.parse(unpacked) : null;

const initialState = {
    assets: {
        native: {
            asset_code: 'XLM',
            site: '',
            image: '/xlm.svg'
        }
    },
    custom: (parsed && parsed._VERSION >= CACHE_VERSION ? parsed.assets : []),
    issuersByCode: {},
    codesByIssuer: {},
    fetchStatuses: {},
    fetchLock: {}
};

function storeCache(assets) {
    localStorage.setItem('customAssets', LZString.compress(JSON.stringify(
        {
            _VERSION: CACHE_VERSION,
            assets
    })));
}

export const fetchCustomAssets = createAsyncThunk(
    'pool/fetchCustomAssets',
    async (dummie, thunkAPI) => {
      const state = thunkAPI.getState();
      state.assets.custom.forEach(asset => thunkAPI.dispatch(fetchAsset(asset)));
      return state.assets.custom;
    }
  );

export const fetchAsset = createAsyncThunk(
    'asset/fetchAsset',
    async (asset, thunkAPI) => {
        const state = thunkAPI.getState();
        if (state.assets.assets[asset]) {
            return;
        }
        if (state.assets.fetchLock[asset] && state.assets.fetchLock[asset] > ((new Date()).getTime() - 120000)) {
            return;
        }
        thunkAPI.dispatch(lockAssetLoading(asset));
        const assetObj = new StellarSdk.Asset(...(asset.split(':')));
        const assetsData = await server.assets().forIssuer(assetObj.getIssuer()).forCode(assetObj.getCode()).call();
        let assetData = assetsData.records[0];
        loadAssetData(assetData, thunkAPI.dispatch, addAssetData);
        
        return assetData;
    }
);

export const fetchAddCustomAsset = createAsyncThunk(
    'asset/fetchAddCustomAsset',
    async (asset, thunkAPI) => {
        const state = thunkAPI.getState();
        if (state.assets.custom.includes(asset)) {
            return new Promise(resolve => resolve(asset));
        }

        if (state.assets.assets[asset]) {
            return new Promise(resolve => resolve(asset));
        }

        const assetObj = new StellarSdk.Asset(...(asset.split(':')));
        const assetsData = await server.assets().forIssuer(assetObj.getIssuer()).forCode(assetObj.getCode()).call();
        let assetData = assetsData.records[0];
        if (!assetData) {
            return null;
        }
        thunkAPI.dispatch(addCustomAsset(asset));

        return loadAssetData(assetData, thunkAPI.dispatch, addAssetData);
    }
);

export const fetchIssuersByCode = createAsyncThunk(
    'asset/fetchIssuersByCode',
    async (assetCode, thunkAPI) => {
        const state = thunkAPI.getState();
        if (state.assets.issuersByCode[assetCode]) {
            return;
        }
        // if (state.assets.fetchLock[asset] && state.assets.fetchLock[asset] > ((new Date()).getTime() - 120000)) {
        //     return;
        // }
        // thunkAPI.dispatch(lockAssetLoading(asset));
        const resp = await server.assets().forCode(assetCode).limit(200).call();
        if (resp.records) {
            resp.records.forEach(asset => {
                return loadAssetData(asset, thunkAPI.dispatch, addIssuerByCode);
            });
        }
        
        return resp;
    }
);

export const fetchCodesByIssuer = createAsyncThunk(
    'asset/fetchCodesByIssuer',
    async (assetIssuer, thunkAPI) => {
        const state = thunkAPI.getState();
        if (state.assets.codesByIssuer[assetIssuer]) {
            return;
        }
        // if (state.assets.fetchLock[asset] && state.assets.fetchLock[asset] > ((new Date()).getTime() - 120000)) {
        //     return;
        // }
        // thunkAPI.dispatch(lockAssetLoading(asset));
        const resp = await server.assets().forIssuer(assetIssuer).limit(200).call();
        if (resp.records) {
            resp.records.forEach(asset => {
                return loadAssetData(asset, thunkAPI.dispatch, addCodesByIssuer);
            });
        }
        
        return resp;
    }
);

function loadAssetData(asset, dispatch, action) {
    let assetData = {
        ...asset,
        site: '',
        image: ''
    };
    if (!asset._links.toml.href) {
        return dispatch(action(assetData));
    }
    setTimeout(() => {
        return fetch(asset._links.toml.href)//, {mode: 'no-cors'}
                .then(resp => resp.text())
                .then(tomlStr => {
                    let tomlData = {};
                    try {
                        tomlData = toml.parse(tomlStr);
                    } catch(err) {
                        tomlData = {};
                    }

                    if (tomlData?.DOCUMENTATION?.ORG_URL) {
                        assetData.site = tomlData.DOCUMENTATION.ORG_URL.replace(/^https?:\/\//, '').replace(/(\/.*)$/, '');
                    }

                    if (tomlData?.CURRENCIES) {
                        assetData.image = tomlData.CURRENCIES
                            .find(c => (c.code === asset.asset_code && c.issuer === asset.asset_issuer))?.image || '';
                    }

                    return dispatch(action(assetData));
                })
                .catch(err => {
                    return dispatch(action(assetData));
                });
    }, 10);
}

export const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        lockAssetLoading: (state, action) => {
            state.fetchLock[action.payload] = (new Date()).getTime();
        },
        addAssetData: (state, action) => {
            const key = action.payload.asset_code === 'native' ? 'native' : `${action.payload.asset_code}:${action.payload.asset_issuer}`;
            state.assets[key] = action.payload;
        },
        addIssuerByCode: (state, action) => {
            if (!state.issuersByCode[action.payload.asset_code]) {
                state.issuersByCode[action.payload.asset_code] = [];
            }
            state.issuersByCode[action.payload.asset_code].push(action.payload);
        },
        addCodesByIssuer: (state, action) => {
            if (!state.codesByIssuer[action.payload.asset_issuer]) {
                state.codesByIssuer[action.payload.asset_issuer] = [];
            }
            state.codesByIssuer[action.payload.asset_issuer].push(action.payload);
        },
        addCustomAsset: (state, action) => {
            if (!state.custom.includes(action.payload)) {
                state.custom.push(action.payload);
                storeCache([].concat(state.custom));
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsset.pending, (state, action) => {
            state.fetchStatuses[action.meta.arg] = 'pending';
        });
        builder.addCase(fetchAsset.rejected, (state, action) => {
            state.fetchStatuses[action.meta.arg] = 'error';
        });
        builder.addCase(fetchAsset.fulfilled, (state, action) => {
            state.fetchStatuses[action.meta.arg] = 'ready';
            if (!action.payload) {
                return;
            }
        });
    }
  });
  
const { lockAssetLoading, addIssuerByCode, addCodesByIssuer, addAssetData, addCustomAsset } = assetsSlice.actions;
  
export default assetsSlice.reducer;
