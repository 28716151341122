import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AssetCodeIcon from './AssetCodeIcon.jsx';

function codeByAsset(asset) {
    return asset === 'native' ? 'XLM' : asset.split(':')[0];
}

export default function AssetSelector({
    asset = '',
    showSite = true,
    defaultOption = {value: '', title: 'None'},
    assets,
    onChose
}) {
    const assetsList = (assets || []).sort((a, b) => codeByAsset(a).localeCompare(codeByAsset(b)));
    const idRef = React.useRef();

    if (!idRef.current) {
        idRef.current = `AssetSelector${Math.random().toString(36).slice(2)}`;
    }

    const handleChange = (event) => {
        onChose(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 100 }} size="small" variant="standard">
            <InputLabel id={idRef.current}>Asset</InputLabel>
                <Select
                    labelId={idRef.current}
                    value={asset}
                    label="Asset"
                    onChange={handleChange}
                >
                    <MenuItem value={ defaultOption.value }>
                        <em>{ defaultOption.title }</em>
                    </MenuItem>
                    { assetsList.map(a => (
                        <MenuItem key={a} value={a}><AssetCodeIcon asset={ a } showSite={ showSite } /></MenuItem>
                    )) }
                </Select>
        </FormControl>
    );
}