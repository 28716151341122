import React, {useState} from "react";
import { NavLink } from 'react-router-dom';
import {
    Box,
    Button,
    Icon,
    // IconButton,
    Menu,
    MenuItem,
    Tooltip
} from '@mui/material';

export default function NotConnectedButton() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Connect Wallet">
                <Button
                    onClick={ handleOpenMenu }
                    sx={{ color: 'white', borderColor: 'white' }}
                    variant="outlined"
                >
                    Connect&nbsp;Wallet
                </Button>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-wcbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
                <MenuItem component={ NavLink } to="/wc" onClick={ handleCloseMenu }>
                    <Icon><img src="/wc.svg" style={{width: '100%', marginBottom: 8}} alt="WalletConnect" /></Icon>&nbsp;&nbsp;WalletConnect
                </MenuItem>
                <MenuItem component={ NavLink } to="/freighter" onClick={ handleCloseMenu }>
                    <Icon><img src="/freighter.svg" style={{width: '100%', marginBottom: 3}} alt="Freighter" /></Icon>&nbsp;&nbsp;Freighter
                </MenuItem>
            </Menu>
        </Box>
    );
}
