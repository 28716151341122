import React, {Fragment, useEffect, useRef} from 'react';
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    // TableFooter,
    Typography
} from '@mui/material';
import WalletPoolTableRow from './WalletPoolTableRow.jsx';

const textTCHStyle = { px: '6px' };
 
export default function WalletPoolTable({walletPoolHistory, diffHistoryItem}) {
    // const diffHistoryItem = {
    //     ...walletPoolHistory[0],
    //     poolData: {
    //         ...walletPoolHistory[0]?.poolData
    //     },
    //     walletData: {
    //         ...walletPoolHistory[0]?.walletData
    //     }
    // };

    // const tableFooterRef = useRef(null);
    const tableRef = useRef(null);
    const tableContainerRef = useRef(null);
    const scrollToBottom = () => {
        // tableFooterRef.current?.scrollIntoView({ behavior: "smooth" })
        tableContainerRef.current?.scrollTo({
            top: tableRef.current?.clientHeight,
            behavior: 'smooth'
        })
    };

    useEffect(() => {
        scrollToBottom()
    }, [walletPoolHistory]);

    const step = walletPoolHistory.length < 100 ? 1 : Math.ceil(walletPoolHistory.length / 100);
    const filterLPefects = walletPoolHistory.filter(wph => (['liquidity_pool_deposited', 'liquidity_pool_withdrew'].includes(wph.effect.type))).length > 50;

    return (
        <>
            <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ maxHeight: 500 }} ref={tableContainerRef}>
                    <Table size="small" stickyHeader ref={tableRef}>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell component="th" sx={textTCHStyle}>Date</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>Type</TableCell>
                                {/* <TableCell component="th" sx={textTCHStyle}>Reserves</TableCell> */}
                                {/* <TableCell component="th" sx={textTCHStyle}>Shares</TableCell> */}
                                {/* <TableCell component="th" sx={textTCHStyle}>Traded</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>Earned</TableCell> */}
                                <TableCell component="th" sx={textTCHStyle}>My shares</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>My %</TableCell>
                                <TableCell component="th" sx={textTCHStyle} align="right">Commission earned</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>Wallet rebalance</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>Wallet reserves</TableCell>
                                <TableCell component="th" sx={textTCHStyle} align="right">In USDC</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { walletPoolHistory
                                .map((wph, idx) => {
                                    if ((filterLPefects  || !['liquidity_pool_deposited', 'liquidity_pool_withdrew'].includes(wph.effect.type))
                                        && (idx > 0 && idx < (walletPoolHistory.length - 1) && idx % step)
                                        // || !(wph.currentShares.toFixed(7) > 0
                                        //     || (walletPoolHistory[idx - 1] ? walletPoolHistory[idx - 1].currentShares.toFixed(7) > 0 : false)
                                        // )
                                    ) {
                                        return <Fragment key={ wph.effect.paging_token } />;
                                    }

                                    return (
                                        <WalletPoolTableRow
                                            key={ wph.effect.paging_token }
                                            walletPoolHistoryItem={wph}
                                            diffHistoryItem={diffHistoryItem}
                                            opened={ idx === (walletPoolHistory.length - 1) }
                                        />
                                    );
                                })
                            }
                        </TableBody>
                        {/* <TableFooter>
                            {
                                !['liquidity_pool_deposited', 'liquidity_pool_withdrew'].includes(walletPoolHistory[walletPoolHistory.length -1]?.effect?.type)
                                && (<WalletPoolTableRow opened={true} walletPoolHistoryItem={walletPoolHistory[walletPoolHistory.length -1]} diffHistoryItem={ diffHistoryItem } />)
                            }
                            <TableRow ref={tableFooterRef} />
                        </TableFooter> */}
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="p" component="div" gutterBottom>
                        All conversions of both coins in USDC are made at the average daily rate on a specific date.
                    </Typography>
                    <Typography variant="p" component="div" gutterBottom>
                        The pool and wallet earnings are calculated cumulatively for each day. Daily earnings in both coins are estimated in USDC at the average daily rate for the corresponding date.
                    </Typography>
                </Paper>
            </Grid>
        </>
    );
}