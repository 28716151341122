import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Pool and wallet shares and reserves cost in USDC chart',
      },
      // tooltip: {
      //   callbacks: {
      //   }
      // }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
                return value + 'USDC';
            }
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
};

  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function PollSharesChart({poolHistory, diffHistoryItem}) {
    const startPoolEarned = diffHistoryItem?.poolData?.earnedAmount || 0;
    const labels = poolHistory.map(({date}) => date.toLocaleDateString('en-gb', {year: 'numeric', month: 'numeric', day: 'numeric'}));
    const data = {
        labels,
        datasets: [
          {
            label: 'Pool shares',
            data: poolHistory.map(({poolData}) => (poolData.currentShares)),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y',
          },
          {
            label: 'Pool reserves in USDC',
            data: poolHistory.map(({poolData}) => (poolData.reservesPrice)),
            borderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y1',
          },
          {
            label: 'Pool comission earned in USDC',
            data: poolHistory.map(({poolData}) => (poolData.earnedAmount - startPoolEarned)),
            borderColor: 'rgb(153, 102, 255)',
            backgroundColor: 'rgba(153, 102, 255, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y1',
          },
        ],
    };
    
    return (<Line options={options} data={data} />);
}