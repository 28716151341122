import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    // useLocation,
    // useNavigate,
    Navigate
} from "react-router-dom";
import {
    Button,
    Grid,
    Paper,
    Typography
} from '@mui/material';
import {
    PAY_ASSET,
    // PAY_WALLET,
    PAY_ASSET_HODL_AMOUNT,
    PAY_ASSET_HODL_PERIOD,
    PAY_ASSET_LOCK_AMOUNT,
    PAY_ASSET_LOCK_PERIOD,
    PAY_VARIANTS
} from '../../app/constants';
import {
    fetchAccountById,
    // checkPermsByPay,
    // checkPermsByHodl
} from './walletSlice';
import AddAsset from '../../components/AddAsset.jsx';
import PayMemoForm from './PayMemoForm.jsx';
import LockBalanceForm from './LockBalanceForm.jsx';
import Swap2PayAsset from './Swap2PayAsset.jsx';

export default function WalletAuth({children}) {
    const auth = useSelector((state) => state.wallet.auth);
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const location = useLocation();
    const fromRoute = '/'; //location.state?.from?.pathname || '/';
// Temporary no auth true && 
    if (auth.granted) {
        return <Navigate to={ fromRoute } replace={ true } />;
    }

    const checkGrants = () => {
        dispatch(fetchAccountById(auth.accountId));
    };

    return (
        <>
            <Grid item xs={12} lg={12}>
                <Paper sx={{p: 2}}>
                    <Typography variant="p" gutterBottom component="div">
                        This service is provided only under certain conditions.
                    </Typography>
                    <Grid sx={{ mt: 0.1, mb: 1 }} container spacing={2} justifyContent="center">
                        <Grid item xs={12} lg={8}>
                            <Typography variant="p"  gutterBottom component="ol">
                                There are three options:
                                <Typography variant="p" component="li" sx={{ml: 2}}>
                                the service is provided free of charge for wallets that hold a { PAY_ASSET.asset_code } token in the amount of { PAY_ASSET_HODL_AMOUNT } coins over the last { PAY_ASSET_HODL_PERIOD } days.
                                </Typography>
                                <Typography variant="p" component="li" sx={{ml: 2}}>
                                analysis of wallet statistics can be obtained by paying for this service for a certain period. For payment, the { PAY_ASSET.asset_code } token is used. For { PAY_VARIANTS[0].amount } tokens you will get access for a day, for { PAY_VARIANTS[1].amount } tokens - for a week, for { PAY_VARIANTS[2].amount } tokens for a { PAY_VARIANTS[2].duration } days.
                                </Typography>
                                <Typography variant="p" component="li" sx={{ml: 2}}>
                                block { PAY_ASSET_LOCK_AMOUNT } { PAY_ASSET.asset_code } coins for { PAY_ASSET_LOCK_PERIOD } days by creating a claimable balance.
                                </Typography>
                            </Typography>
                    
                            <Typography variant="p" gutterBottom component="div">
                            You can add the { PAY_ASSET.asset_code } token to your stellar wallet using the asset code: { PAY_ASSET.asset_code }, and the issuer address: { PAY_ASSET.asset_issuer }
                            </Typography>
                            <Typography variant="p" gutterBottom component="div">
                            Also, in order to add the { PAY_ASSET.asset_code } token to your stellar wallet, you can scan the QR-code if your wallet supports this option:
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" sx={{ textAlign: 'center' }}>
                            <AddAsset />
                        </Grid>
                    </Grid>
                    <Swap2PayAsset />
                </Paper>
            </Grid>

            <Grid item xs={12} lg={12}>
                <Paper sx={{p: 2}}>
                    <Typography variant="h4" component="div">
                        Payment constructor:
                    </Typography>
                    </Paper>
            </Grid>

            <Grid item xs={12} lg={12}>
                <Paper sx={{p: 2}}>
                    <PayMemoForm />
                </Paper>
            </Grid>

            <Grid item xs={12} lg={12}>
                <Paper sx={{p: 2}}>
                    <LockBalanceForm />
                </Paper>
            </Grid>

            <Grid item xs={12} lg={12}>
                <Paper sx={{p: 2, textAlign: 'center'}}>
                    <Button
                        variant="contained"
                        onClick={ checkGrants }
                    >
                        Check grants now
                    </Button>
                </Paper>
            </Grid>
        </>
    );

}