import React, {Fragment, useEffect, useRef} from 'react';
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    // TableFooter,
    Typography
} from '@mui/material';
import PoolTableRow from './PoolTableRow.jsx';

const textTCHStyle = { px: '6px' };
 
export default function PoolTable({poolHistory, diffHistoryItem}) {

    // const tableFooterRef = useRef(null);
    const tableRef = useRef(null);
    const tableContainerRef = useRef(null);
    const scrollToBottom = () => {
        // tableFooterRef.current?.scrollIntoView({ behavior: "smooth" })
        tableContainerRef.current?.scrollTo({
            top: tableRef.current?.clientHeight,
            behavior: 'smooth'
        })
    };

    useEffect(() => {
        scrollToBottom()
    }, [poolHistory]);

    const step = poolHistory.length < 100 ? 1 : Math.ceil(poolHistory.length / 100);
    const filterLPefects = poolHistory.filter(ph => (['liquidity_pool_deposited', 'liquidity_pool_withdrew'].includes(ph.effect.type))).length > 50;

    return (
        <>
            <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ maxHeight: 500 }} ref={tableContainerRef}>
                    <Table size="small" stickyHeader ref={tableRef}>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell component="th" sx={textTCHStyle}>Date</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>Type</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>Reserves</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>Shares</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>Traded</TableCell>
                                <TableCell component="th" sx={textTCHStyle}>Earned</TableCell>
                                <TableCell component="th" sx={textTCHStyle} align="right">Reserves in USDC</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { poolHistory
                                .map((ph, idx) => {
                                    if ((filterLPefects || !['liquidity_pool_deposited', 'liquidity_pool_withdrew'].includes(ph.effect.type))
                                        && (idx > 0 && idx < (poolHistory.length - 1) && idx % step)
                                        // || !(ph.currentShares.toFixed(7) > 0
                                        //     || (poolHistory[idx - 1] ? poolHistory[idx - 1].currentShares.toFixed(7) > 0 : false)
                                        // )
                                    ) {
                                        return <Fragment key={ ph.effect.paging_token } />;
                                    }

                                    return (
                                        <PoolTableRow
                                            key={ ph.effect.paging_token }
                                            poolHistoryItem={ph}
                                            diffHistoryItem={diffHistoryItem}
                                            opened={ idx === (poolHistory.length - 1) }
                                        />
                                    );
                                })
                            }
                        </TableBody>
                        {/* <TableFooter>
                            {
                                !['liquidity_pool_deposited', 'liquidity_pool_withdrew'].includes(poolHistory[poolHistory.length -1]?.effect?.type)
                                && (<PoolTableRow opened={true} poolHistoryItem={poolHistory[poolHistory.length -1]} diffHistoryItem={ diffHistoryItem } />)
                            }
                            <TableRow ref={tableFooterRef} />
                        </TableFooter> */}
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography variant="p" component="div" gutterBottom>
                    All conversions of both coins in USDC are made at the average daily rate on a specific date.
                </Typography>
                <Typography variant="p" component="div" gutterBottom>
                    The pool earnings are calculated cumulatively for each day. Daily earnings in both coins are estimated in USDC at the average daily rate for the corresponding date.
                </Typography>
            </Paper>
        </Grid>
    </>
    );
}