import React from 'react';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import chroma from 'chroma-js';

ChartJS.register(ArcElement, Tooltip, Legend);

const borderColor = [
    '#aaaaaa'
];

const options = {
    plugins: {
        title: {
            display: true,
            text: 'Wallet assets',
        },
    },
};

export default function AssetsPie() {
    const balances = useSelector((state) => state.wallet.balances);
    const pools = useSelector((state) => state.pools.pools);
    const walletPools = useSelector((state) => state.wallet.pools);
    const lastPrice = useSelector((state) => state.prices.lastPrice);

    let labels = [];
    let dataPoints = [];

    (balances || []).filter(b => (b.asset_type !== 'liquidity_pool_shares'))
        .forEach(balance => {
            const asset = balance.asset_type === 'native' ? 'native' : `${balance.asset_code}:${balance.asset_issuer}`;
            labels.push(balance.asset_type === 'native' ? 'XLM' : balance.asset_code);

            const poolsWithAsset = Object.values(pools).filter(pool => (walletPools[pool.id] && pool.reserves.find(r => (r.asset === asset))));

            const balanceInPools = poolsWithAsset.length > 0
                ? poolsWithAsset.reduce((total, {id, total_shares, reserves}) => {
                    return total + parseFloat(reserves.filter(r => r.asset === asset)[0].amount)*(walletPools[id] || {balance: 0}).balance/total_shares;
                    },
                    0
                )
                : 0;
            dataPoints.push((lastPrice[asset] ? lastPrice[asset]*(balanceInPools + parseFloat(balance.balance)) : 0));
        });

    let data = {
        labels,
        datasets: [
            {
                label: 'In USDC',
                data: dataPoints,
                backgroundColor: chroma.scale(['00429d', '96ffea', 'ffffe0', 'ff005e', '93003a']).colors(dataPoints.length),
                borderColor,
                borderWidth: 1,
            }
        ]
    };

    return <Pie data={data} options={options} />;//<></>;//
}