import React, {Fragment, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import memoize from 'memoize-one';
import {
    Box,
    Button,
    Collapse,
    Icon,
    IconButton,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    ExpandMore
} from '@mui/icons-material';
import AssetSelector from './AssetSelector.jsx';
import {
    fetchIssuersByCode,
    fetchCodesByIssuer,
    fetchAddCustomAsset
} from './assetsSlice.js';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;

export default function CustomAsset({onChose}) {
    const {assets, issuersByCode, codesByIssuer} = useSelector(state => state.assets);
    const [asset, setAsset] = useState('');
    const [assetCode, setAssetCode] = useState('');
    const [assetIssuer, setAssetIssuer] = useState('');
    const [hideCodesList, setHideCodesList] = useState(false);
    const [hideIssuersList, setHideIssuersList] = useState(false);
    const [codesListLimit, setCodesListLimit] = useState(10);
    const [issuersListLimit, setIssuersListLimit] = useState(10);
    
    const dispatch = useDispatch();

    const choseAsset = asset => {
        setAsset(asset);
        onChose(asset);
        // const [assetCode, assetIssuer = ''] = asset.split(':');
        // if (assetCode) {setAssetCode(assetCode);}
        // if (assetIssuer) {setAssetIssuer(assetIssuer);}
    };

    const changeAssetCode = e => {
        const assetCode = e.target.value;
        setAssetCode(assetCode);
        setHideIssuersList(false);
        if (assetCode.length > 1) {
            setIssuersListLimit(10);
            dispatch(fetchIssuersByCode(assetCode));
        }
    };

    const changeAssetIssuer = e => {
        const assetIssuer = e.target.value;
        setAssetIssuer(assetIssuer);
        setHideCodesList(false);
        if (StellarSdk.StrKey.isValidEd25519PublicKey(assetIssuer)) {
            setCodesListLimit(10);
            dispatch(fetchCodesByIssuer(assetIssuer));
        }
    };

    const addCustomAsset = () => {
        const asset = `${assetCode}:${assetIssuer}`;
        dispatch(fetchAddCustomAsset(asset))
            .then(() => {
                choseAsset(asset);
                setAssetCode('');
                setAssetIssuer('');
                setIssuersListLimit(10);
                setCodesListLimit(10);
            });
    }

    if (Object.keys(assets).includes(`${assetCode}:${assetIssuer}`)) {
        choseAsset(`${assetCode}:${assetIssuer}`);
        setAssetCode('');
        setAssetIssuer('');
        setIssuersListLimit(10);
        setCodesListLimit(10);
    }

    const issuerErrored = !!(assetIssuer && !StellarSdk.StrKey.isValidEd25519PublicKey(assetIssuer));

    const issuersToChose = [].concat(issuersByCode[assetCode] || []).sort((a,b) => (b.num_accounts - a.num_accounts));
    const codesToChose = [].concat(codesByIssuer[assetIssuer] || []).sort((a,b) => (b.num_accounts - a.num_accounts));
    
    return (
        <Fragment>
            <Typography component="div" variant="body">
                <AssetSelector assets={ Object.keys(assets) } asset={ asset } defaultOption={{value: '', title: 'Custom'}} onChose={ choseAsset } />
            </Typography>
            <Collapse in={!asset} timeout="auto" unmountOnExit>
                <Typography component="h5" variant="h6">
                    Enter asset code
                </Typography>
                <TextField
                    sx={{ m: 1, width: 100 }}
                    size="small"
                    label="Asset code"
                    variant="standard"
                    value={ assetCode }
                    onFocus={ () => setHideIssuersList(false) }
                    onChange={ changeAssetCode } />
                { assetCode !== '' && !hideIssuersList && (issuersByCode[assetCode] || []).length > 0 && (<>
                    <Box sx={{ m: 0, p: 2, position: 'relative' }}>
                        Issuers by asset code {
                            (!!issuersListLimit
                            && issuersListLimit < issuersToChose.length)
                                ? `(${issuersListLimit}/${issuersToChose.length})`
                                : `(${issuersToChose.length})`
                        }
                        <IconButton
                            aria-label="close"
                            onClick={() => setHideIssuersList(true)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Paper sx={{ maxHeight: 250, overflow: 'auto' }}>
                        { (!!issuersListLimit ? issuersToChose.slice(0, issuersListLimit) : issuersToChose).map(assetData => (
                            <AssetPresetsList
                                key={ assetData.asset_issuer }
                                assetData={assetData}
                                assetField="asset_issuer"
                                assetFieldValue={ assetIssuer }
                                assets={ assets }
                                cb={ (issuer) => {setAssetIssuer(issuer); setHideIssuersList(true);} } />
                        )
                        )}
                        { !!issuersListLimit && issuersListLimit < issuersToChose.length && (
                            <MoreButton cb={ () => setIssuersListLimit(0) } />
                        ) }
                    </Paper>
                </>)}                
                <Typography component="h5" variant="h6">
                    Enter (or chose) asset issuer
                </Typography>
                <TextField
                    sx={{ m: 1, width: '97%' }}
                    error={ issuerErrored }
                    size="small"
                    label="Asset issuer"
                    variant="standard"
                    value={ assetIssuer }
                    onFocus={ () => setHideCodesList(false) }
                    onChange={ changeAssetIssuer } />
                { assetIssuer !== '' && !hideCodesList && (codesByIssuer[assetIssuer] || []).length > 0 && (<>
                    <Box sx={{ m: 0, p: 2, position: 'relative' }}>
                        Asset codes by issuer {
                            (!!codesListLimit
                            && codesListLimit < codesToChose.length)
                                ? `(${codesListLimit}/${codesToChose.length})`
                                : `(${codesToChose.length})`
                        }
                        <IconButton
                            aria-label="close"
                            onClick={() => setHideCodesList(true)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Paper sx={{ maxHeight: 250, overflow: 'auto' }}>
                        { (!!codesListLimit ? codesToChose.slice(0, codesListLimit) : codesToChose).map(assetData => (
                            <AssetPresetsList
                                key={ assetData.asset_code }
                                assetData={assetData}
                                assetField="asset_code"
                                assetFieldValue={ assetCode }
                                assets={ assets }
                                cb={ (code) => {setAssetCode(code); setHideCodesList(true);} } />
                        )
                        )}
                        { !!codesListLimit && codesListLimit < codesToChose.length && (
                            <MoreButton cb={ () => setCodesListLimit(0) } />
                        ) }
                    </Paper>
                </>)}
                <Button variant="outlined" disabled={ !assetCode || !assetIssuer || issuerErrored } onClick={ addCustomAsset }>Add custom asset</Button>
            </Collapse>
        </Fragment>
    );
}

function AssetPresetsList({assetData, assetField, assetFieldValue, assets, cb}) {
    // const exists = Object.keys(assets).includes(`${assetData.asset_code}:${assetData.asset_issuer}`); exists ? { pointerEvents: 'none!important', color: '#B4B4B4!important' } : 
    return (<ListItemButton
        size="small"
        selected={ assetData[assetField] === assetFieldValue }
        sx={ { cursor: 'pointer' } }
        onClick={ () => {
            // if (exists) {return;}
            cb(assetData[assetField])
        } }
    >
        <ListItemText sx={{ wrap: 'nowrap' }} primary={ <Stack direction="row" alignItems="center" sx={{display: 'inline-flex'}}>
            { assetData?.image && <Icon sx={{textAlign: 'center', mr: 1}}><img src={ assetData.image } style={{width: '100%', marginBottom: 3}} alt={ assetData.asset_code } /></Icon> }
            { assetField === 'asset_issuer'
                ? <Typography component="span" variant="bodySmall">{`${assetData.asset_issuer.substr(0,8)}...${assetData.asset_issuer.substr(-8)}`}</Typography>
                : assetData.asset_code
            }
            { assetData.site && (<Typography variant="bodySmall" element="i">({assetData.site})</Typography>) }
            { !!assetData.num_accounts && (<Typography variant="bodySmall" element="i">(trustlines:{assetData.num_accounts})</Typography>) }
        </Stack> } />
    </ListItemButton>);
}

function MoreButton({cb}) {
    return (
        <ListItemButton onClick={ cb }>
            <ExpandMore />
            <ListItemText sx={{ textAlign: 'center' }}><Typography component="span" variant="bodySmall">MORE</Typography></ListItemText>
            <ExpandMore />
        </ListItemButton>
    );
}
