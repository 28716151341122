import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {
    PAY_ASSET,
    PAY_WALLET,
    PAY_VARIANTS
} from '../../app/constants';
import PayQR from './PayQR.jsx';

export default function PayMemoForm() {
    const rootWallet = useSelector((state) => state.root.wallet);
    const [days, setDays] = useState(PAY_VARIANTS[PAY_VARIANTS.length - 1].duration);
    const [wallet, setWallet] = useState(rootWallet || '');

    const handleChangeDays = (e) => {
        setDays(e.target.value);
    };

    const handleChangeWallet = (e) => {
        setWallet(e.target.value);
    };

    const chosenVariant = PAY_VARIANTS.find(v => v.duration === days);
    const memo = `p${chosenVariant.duration}d${chosenVariant.amount}` + (wallet && `w${wallet.substr(1,10)}${wallet.substr(-10)}`);

    return (
        <>
        <Box sx={{mb: 2}}>
            <Typography variant="h5" gutterBottom component="div">
                You want to get acces to extra services by paying:
            </Typography>
            <Stack direction="row" alignItems="center" sx={{display: 'flex'}}>
                <Typography variant="p" component="span">for:</Typography>
                <Box sx={{ ml: 2, mr: 2, minWidth: 100, display: 'inline-block' }}>
                    <InputLabel id="days-selector-label">Days</InputLabel>
                    <FormControl variant="standard" fullWidth>
                        <Select
                            label="Days"
                            size='small'
                            id="days selector"
                            value={days}
                            onChange={handleChangeDays}
                        >
                            { PAY_VARIANTS.map(
                                v => (<MenuItem key={ v.duration } value={ v.duration }>{v.duration} days by {v.amount} {PAY_ASSET.asset_code}</MenuItem>))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Typography variant='p' component="span">for the wallet:</Typography>
                <TextField sx={{ ml: 2, width: 610 }} size="small" label="Wallet" variant="standard" value={ wallet } onChange={ handleChangeWallet } />
            </Stack>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="p" component="div">
                You may pay choosen amount to the wallet <Typography variant='p' component="strong">{ PAY_WALLET }</Typography>
            </Typography>
            <Typography variant="p" component="div">
                Amount <Typography variant='p' component="strong">{ chosenVariant.amount } {PAY_ASSET.asset_code}</Typography> with memo: <Typography variant='p' component="strong">{ memo }</Typography>
            </Typography>
            <Typography variant="p" component="div">
                You can pay using the link QR code:
            </Typography>
            <PayQR wallet={ PAY_WALLET } amount={ chosenVariant.amount } asset={ PAY_ASSET } memo={ memo } />
        </Box>
        </>
    );
}
/* <Typography variant="p" component="div">
{ memo }
<TextField
    sx={{ width: 300 }}
    size="small"
    label="Memo"
    variant="standard"
    value={ memo } />
</Typography>
*/