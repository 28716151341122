import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from "react-router-dom";
import { setWallet } from '../../app/rootSlice';
import { fetchAccountById } from './walletSlice';
import {
  TextField,
  Button,
  Box,
} from '@mui/material';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;

export default function WalletForm(props) {
  const rootWallet = useSelector((state) => state.root.wallet);
  const { accountId } = useParams();
  const [wallet, editWallet] = useState(accountId || '');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const submitForm = () => {
    dispatch(setWallet(wallet));
    dispatch(fetchAccountById(wallet));
    if (location.pathname === '/' && StellarSdk.StrKey.isValidEd25519PublicKey(wallet)) {
      navigate(`/wallet/${wallet}`);
    }
  }

  if (!rootWallet && accountId) {
    submitForm();
  }

  const changeWallet = e => {
    editWallet(e.target.value);
  };

  const walletErrored = !!(wallet && !StellarSdk.StrKey.isValidEd25519PublicKey(wallet));

  useEffect(() => {
      editWallet(accountId);
  }, [accountId]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="on"
    >
      <TextField sx={{ width: 610 }} error={ walletErrored } size="small" label="Wallet" variant="standard" value={ wallet } onChange={ changeWallet } />
      <Button
        variant="outlined"
        sx={{ verticalAlign: 'bottom' }}
        onClick={ submitForm }>Draw</Button>
    </Box>
  );
}
