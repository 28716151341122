import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import {
    List,
    ListItemButton,
    // ListItemIcon,
    ListItemText,
    Collapse
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore
} from '@mui/icons-material';
import LeftMenuItem from '../../components/LeftMenuItem.jsx';
import AssetCodeIcon from '../../features/assets/AssetCodeIcon.jsx';
import AssetsPairByReserves from '../../features/assets/AssetsPairByReserves.jsx';

export default function CommonPoolLeftMenuItem({assetData, isExpanded}) {
    const poolId = useParams()?.poolId;
    const [open, setOpen] = useState(!!assetData.pools.find(({id}) => (id === poolId)));

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Fragment>
            <ListItemButton onClick={handleClick}>
                <ListItemText
                    primary={ <AssetCodeIcon asset={ assetData.asset } hideCodes={ !isExpanded } /> }
                    title={ (assetData.asset === 'native' ? 'XLM' : assetData.asset.split(':').shift()) }
                />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { assetData.pools.map (pool => (
                        <LeftMenuItem
                            key={ pool.id }
                            sx={{ pl: (isExpanded ? 4 : 2) }}
                            title={ pool.reserves.map(({asset}) => (asset === 'native' ? 'XLM' : asset.split(':').shift())).join('/') }
                            to={ `/pool/${pool.id}` }
                        >
                            <ListItemText primary={ <AssetsPairByReserves reserves={ pool.reserves } hideCodes={ !isExpanded } /> } />
                        </LeftMenuItem>
                    )) }
                </List>
            </Collapse>
        </Fragment>
    );
}
