import React, { Fragment, useState } from 'react';
import { connect }         from 'react-redux';
import {
  Outlet,
  // useMatch,
  // Navigate
} from 'react-router';
import { 
  Link,
  // useNavigate,
  useLocation
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import {
    Grid,
    // FormControlLabel,
    // TextField,
    // Button,
  
    CssBaseline,
    AppBar,
    Drawer,
    Box,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    // Badge,
    Container,
    Paper
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { APP_NAME, VERSION, BASE_URL } from '../app/constants';
import LeftMainMenu from './LeftMainMenu.jsx';
import LeftPoolsMenu from '../features/pools/LeftPoolsMenu.jsx';
import LeftCommonPoolsMenu from '../features/pools/LeftCommonPoolsMenu.jsx';
import LeftCustomPoolsMenu from '../features/pools/LeftCustomPoolsMenu.jsx';
import WalletForm from '../features/wallet/WalletForm.jsx';
import WalletTotalBallance from '../features/wallet/WalletTotalBallance.jsx';
import WalletGrants from '../features/wallet/WalletGrants.jsx';
import Copyright from './Copyright.jsx';
import WalletConnectButton from './ConnectWalletButton.jsx';

const drawerWidth = 280;

const AmmAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AmmDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default connect(state => ({
    wallet: state.root.wallet,
    commonPools: state.pools.common,
    chosenPool: state.root.poolId,
}))(function Layout({wallet, chosenPool, commonPools}) {
    const [open, setOpen] = useState(true);
    // const match = useMatch('/');
    // const navigate = useNavigate();
    const location = useLocation();
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Fragment>
            <Helmet>
            <link rel="canonical" href={ BASE_URL + location.pathname } />
            </Helmet>
            {/* location.pathname === '/' && wallet && (<Navigate to={`/wallet/${wallet}`} replace={ false } />) */}
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AmmAppBar position="absolute" open={open}>
                    <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                    >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        <Link to="/" style={{ textDecoration: 'none', color: 'white' }}><HomeIcon sx={{mb: '-4px'}} />{ `${APP_NAME} (v.${VERSION})`}</Link>{ wallet && ` - ${wallet}` }
                    </Typography>
                    <WalletConnectButton />
        {/*            <IconButton color="inherit">
                      <Badge badgeContent={4} color="secondary">
                        <NotificationsIcon />
                        </Badge>
                    </IconButton>*/}
                    </Toolbar>
                </AmmAppBar>
                <AmmDrawer variant="permanent" open={open}>
                    <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                    >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                      <LeftMainMenu isOpen={ open } />
                      <LeftCustomPoolsMenu isOpen={ open } />
                      <LeftPoolsMenu isOpen={ open } />
                      <Divider sx={{ my: 1 }} />
                      <LeftCommonPoolsMenu isOpen={ open } />
                    </List>
                </AmmDrawer>
                <Box
                    component="main"
                    sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    // height: '100vh',
                    overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth={ false } sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        {/* Chart */}
                        <Grid item xs="auto">
                        <Paper
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            // height: 240,
                            minWidth: 640
                            }}
                        >
                            <WalletForm />
                        </Paper>
                        </Grid>
                        { wallet && (
                        <Grid item xs="auto">
                            <Paper
                            sx={{
                                px: 2,
                                py: 1.85, //3.25,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            >
                              <WalletTotalBallance />
                              <WalletGrants />
                            </Paper>
                        </Grid>
                        )}

                        <Outlet />

                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
                </Box>
        </Fragment>
    );
});