import React, {Fragment, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,

    Icon,
} from '@mui/material';
import { transact } from './walletconnectSlice';

export default function WCTransact({
        buttonText = 'Transaction',
        operations,
        memo,
        feeFactor = 10,
        callback,
        hideUsing = false,
        variant = "outlined",
        disabled = false,
        confirmMessage = '',
        ...rest
}) {
    const {account, hasPendingTransaction, appMeta} = useSelector((state) => state.walletconnect);
    const defaultMessage = `The transaction has been sent to ${appMeta?.name || 'WalletConnect'} app for confirmation.`;
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(defaultMessage);

    const handleCloseDialog = () => {
        setShowDialog(false);
        setDialogMessage(defaultMessage);
        setConfirmed(false);
    };

    const doTransaction = async () => {
        setDialogMessage(defaultMessage);
        setShowDialog(true);
        setConfirmed(true);
        return Promise.all(operations.map(async (o) => (o instanceof Function ? o() : Promise.resolve(o))))
            .then(operations => dispatch(transact({operations, memo, feeFactor}))
                .then(result => {
                    if (result.payload?.status === 'success') {
                        setDialogMessage('Transaction Completed');
                        if (callback) {
                            callback();
                        }
                    }
                    if (result.error) {
                        setDialogMessage(result.error?.message || 'Transaction ERRORED');
                    }
                })
                .catch(e => {
                    setDialogMessage(e);
                })
            );
    }

    const startTransaction = () => {
        if (confirmMessage === '') {
            return doTransaction();
        }
        setDialogMessage(confirmMessage);
        setConfirmed(false);
        setShowDialog(true);
    }

    return (<Fragment>
        <Button
            {...rest}
            variant={ variant }
            disabled={ disabled || hasPendingTransaction || !account }
            onClick={ startTransaction }
        >
            { buttonText }&nbsp;{ !hideUsing
                ? (<>
                    using&nbsp;<Icon><img
                        src={ (appMeta?.icons || ['/wc.svg'])[0] }
                        style={{width: '100%', marginBottom: 8}}
                        alt={ appMeta?.name || 'WalletConnect' }
                        title={ appMeta?.name || 'WalletConnect' } /></Icon></>)
                : ''
            }
        </Button>
        <Dialog
            open={!!showDialog}
            onClose={handleCloseDialog}
        >
            <DialogTitle>
                WalletConnect transaction
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { dialogMessage }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                { confirmMessage !== '' && !confirmed && (<Button variant={ variant } onClick={doTransaction}>I confitm transaction</Button>)}
                <Button variant={ variant } onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
        </Dialog>        
    </Fragment>);
}

// &nbsp;{ appMeta?.name || 'WalletConnect' }