import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import WalletConnectClient, { SIGN_CLIENT_EVENTS } from '@walletconnect/sign-client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getInternalError, getSdkError } from '@walletconnect/utils';

import { BASE_URL, TRANSACTION_TIMEOUT } from '../../app/constants';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

const PROJECT_ID = '4cbf905c4f954f663a9173ee243fad75';

const METADATA = {
    name: 'AMMExplorer',
    description: 'AMMExplorer.',
    url: BASE_URL,
    icons: ['https://kurilka.org.ua/img/krlk_250x250.png'],
};

const PUBNET = 'stellar:pubnet';

const STELLAR_METHODS = {
    SIGN_AND_SUBMIT: 'stellar_signAndSubmitXDR',
    SIGN: 'stellar_signXDR',
};

const REQUIRED_NAMESPACES = {
    stellar: {
        chains: [PUBNET],
        methods: Object.values(STELLAR_METHODS),
        events: [],
    },
};

let client, session, appMeta;

const listenWalletConnectEvents = () => {
  client.on(SIGN_CLIENT_EVENTS.session_delete, ({ topic }) => onSessionDeleted(topic));
}

function onSessionDeleted(topic) {
  if (session && session.topic === topic) {
      session = null;
      appMeta = null;
  }
}

export const transact = createAsyncThunk(
    'walletconnect/transact',
    async ({operations, memo, feeFactor = 10}, thunkAPI) => {
        const { publicKey, hasPendingTransaction } = thunkAPI.getState().walletconnect;
        if (!publicKey) {
            return Promise.reject('WalletConnect is not connected');
        }

        if (hasPendingTransaction) {
            return Promise.reject('Another transaction is currently in progress');
        }

        thunkAPI.dispatch(setHasPendingTransaction(true));

        try {
            const fee = await server.fetchBaseFee();

            const account = await server.loadAccount(publicKey);

            const tx = new StellarSdk.TransactionBuilder(account, {
                fee: fee * feeFactor, //: StellarSdk.BASE_FEE,
                networkPassphrase: StellarSdk.Networks.PUBLIC
            }).setTimeout(TRANSACTION_TIMEOUT);
            
            if (Array.isArray(operations)) {
                operations.forEach(op => {
                    tx.addOperation(op);
                });
            } else {
                tx.addOperation(operations);
            }
            if (memo) {
                tx.addMemo(StellarSdk.Memo.text(memo));
            }

            const xdr = tx.build().toEnvelope().toXDR('base64');
            
            return client.request({
                    topic: session.topic,
                    chainId: PUBNET,
                    request: {
                        method: STELLAR_METHODS.SIGN_AND_SUBMIT,
                        params: {
                            xdr,
                        },
                    },
                })
                .then(result => {
                    if (thunkAPI.getState().walletconnect.publicKey) {
                        thunkAPI.dispatch(loadWCAccount(thunkAPI.getState().walletconnect.publicKey));
                        // server.loadAccount(thunkAPI.getState().walletconnect.publicKey)
                        //     .then(account => thunkAPI.dispatch(setAccount(account)));
                    }
                    thunkAPI.dispatch(setHasPendingTransaction(false));
                    return result;
                })
                .catch(e => {
                    if (thunkAPI.getState().walletconnect.publicKey) {
                        thunkAPI.dispatch(loadWCAccount(thunkAPI.getState().walletconnect.publicKey));
                        // server.loadAccount(thunkAPI.getState().walletconnect.publicKey)
                        //     .then(account => thunkAPI.dispatch(setAccount(account)));
                    }
                    thunkAPI.dispatch(setHasPendingTransaction(false));
                    return Promise.reject(e);
                });
        } catch(e) {
            thunkAPI.dispatch(setHasPendingTransaction(false));
            return Promise.reject(e);
        }
    }
);

export const initWalletConnect = createAsyncThunk(
    'walletconnect/initWalletConnect',
    async (dummie, thunkAPI) => {
        // let client = thunkAPI.getState().walletconnect.client;

        if (client) {
            return null;
        }

        client = await WalletConnectClient.init({
            // logger: 'debug',
            projectId: PROJECT_ID,
            metadata: METADATA,
        });
        
        listenWalletConnectEvents();

        // console.log(client.session);

        if (!client.session.length) {
            return null;
        }

        session = await client.session.getAll()[0];

        // eslint-disable-next-line no-unused-vars
        let [chain, reference, publicKey] = session.namespaces.stellar.accounts[0].split(':');

        appMeta = session.peer.metadata;
        // console.log(appMeta);
        thunkAPI.dispatch(setAppMeta(appMeta));
        thunkAPI.dispatch(setPublicKey(publicKey.toUpperCase()));

        thunkAPI.dispatch(loadWCAccount(publicKey.toUpperCase()));

        const keypair = StellarSdk.Keypair.fromPublicKey(publicKey.toUpperCase());

        thunkAPI.dispatch(setKeypair(keypair));

        return 'logged';
    }
);

// async restoreConnectionIfNeeded() {
//     if (session) {
//         client = await WalletConnectClient.init({
//             // logger: 'debug',
//             projectId: PROJECT_ID,
//             metadata: METADATA,
//         });
//     }
// }

export const loadWCAccount = createAsyncThunk(
    'walletconnect/loadWCAccount',
    async (accountId, thunkAPI) => {
        server.loadAccount(accountId)
            .then(account => thunkAPI.dispatch(setAccount(account)))
            .catch(() => {
                setTimeout(() => thunkAPI.dispatch(loadWCAccount(accountId)), 1000);
            });
        server
            .offers()
            .forAccount(accountId)
            .call()
            .then(resp => {
                // console.log(resp);
                thunkAPI.dispatch(setOffers(resp.records));
            })
            .catch(() => {
                setTimeout(() => thunkAPI.dispatch(loadWCAccount(accountId)), 1000);
            });
    }
);

export const login = createAsyncThunk(
    'walletconnect/login',
    async (dummie, thunkAPI) => {
        const result = await thunkAPI.dispatch(initWalletConnect());

        if (result.payload === 'logged') {
            return;
        }

        const state = thunkAPI.getState().walletconnect;

        if (state.phase) {
            // ??? 
        }

        if (client.pairing.getAll({ active: true }).length > 3) {
            const deletePromises = [];
            client.pairing
                .getAll({ active: true })
                .slice(0, -3)
                .forEach(pairing => {
                    deletePromises.push(state.client.pairing.delete(pairing.topic, getInternalError('UNKNOWN_TYPE')));
                });

            await Promise.all(deletePromises);
        }

        if (client.pairing.getAll({ active: true }).length) {
            thunkAPI.dispatch(setPhase('connectPairing'));
            thunkAPI.dispatch(setPairings(client.pairing.getAll({ active: true }).reverse()));
            return;
        }

        thunkAPI.dispatch(connect());
    }
);

export const connect = createAsyncThunk(
    'walletconnect/connect',
    async (pairing, thunkAPI) => {
        let currentPhase = thunkAPI.getState().walletconnect.phase;
        if (pairing) {
            thunkAPI.dispatch(setPhase('sessionRequest'));
        }

        try {
            const { uri, approval } = await client.connect({
                pairingTopic: pairing ? pairing.topic : undefined,
                requiredNamespaces: REQUIRED_NAMESPACES,
            });

            if (!pairing) {
                currentPhase = 'QR';
                thunkAPI.dispatch(setPhase('QR'));
                thunkAPI.dispatch(setUri(uri));
            }

            session = await approval();
        } catch (e) {
            thunkAPI.dispatch(setPhase(currentPhase));
            if (session) {
                return Promise.resolve({ status: 'cancel' });
            }
            appMeta = null
            thunkAPI.dispatch(setAppMeta(null));
            if (e.message === 'cancelled') {
                // !!!!!!
                return Promise.resolve({ status: 'cancel' });
            }
            const errorMessage = (
                e.message === 'rejected' ||
                e.message === '' ||
                e.code === getSdkError('USER_REJECTED').code
            ) ?
                'Connection canceled by the user' :
                e.message;
            return Promise.reject(errorMessage);
        }

        appMeta = session.peer.metadata;
        // console.log(appMeta);
        thunkAPI.dispatch(setAppMeta(appMeta));

        // eslint-disable-next-line no-unused-vars
        let [chain, reference, publicKey] = session.namespaces.stellar.accounts[0].split(':');

        thunkAPI.dispatch(setPublicKey(publicKey.toUpperCase()));

        thunkAPI.dispatch(loadWCAccount(publicKey.toUpperCase()));

        const keypair = StellarSdk.Keypair.fromPublicKey(publicKey.toUpperCase());

        thunkAPI.dispatch(setKeypair(keypair));
        thunkAPI.dispatch(setPhase('connected'));

        client.pairing.update(pairing.topic, {
            peerMetadata: appMeta,
        });
    }
);

export const logout = createAsyncThunk(
    'walletconnect/logout',
    async (dummie, thunkAPI) => {
        if (session) {
            await client.disconnect({
                topic: session.topic,
                reason: getSdkError('USER_DISCONNECTED'),
            });
            onSessionDeleted(session.topic);
            // state
            thunkAPI.dispatch(setUri(null));
            thunkAPI.dispatch(setAppMeta(null));
            thunkAPI.dispatch(setPublicKey(null));
            thunkAPI.dispatch(setAccount(null));
            thunkAPI.dispatch(setOffers(null));
            thunkAPI.dispatch(setKeypair(null));
            thunkAPI.dispatch(setPhase(null));
            thunkAPI.dispatch(setPairings(null));
            thunkAPI.dispatch(setHasPendingTransaction(false));
        }
    }
);

export const deletePairing = createAsyncThunk(
  'walletconnect/deletePairing',
  async (topic, thunkAPI) => {
    await client.pairing.delete(topic, getInternalError('UNKNOWN_TYPE'));

    if (client.pairing.getAll({ active: true }).length) {
        thunkAPI.dispatch(setPhase('connectPairing'));
        thunkAPI.dispatch(setPairings(client.pairing.getAll({ active: true }).reverse()));
    } else {
        thunkAPI.dispatch(setPhase(null));
        thunkAPI.dispatch(setPairings(null));
    }
  }
);

export const walletconnectSlice = createSlice({
    name: 'walletconnect',
    initialState: {
        uri: null,
        appMeta: null,
        publicKey: null,
        keypair: null,
        account: null,
        offers: null,

        phase: null,

        pairings: null,

        hasPendingTransaction: false,
    },
    reducers: {
      setUri(state, action) {
        state.uri = action.payload;
      },
      setAppMeta(state, action) {
        state.appMeta = action.payload;
      },
      setPublicKey(state, action) {
        state.publicKey = action.payload;
      },
      setKeypair(state, action) {
        state.keypair = action.payload;
      },

      setAccount(state, action) {
        state.account = action.payload;
      },
      setOffers(state, action) {
        state.offers = action.payload;
      },

      setPhase(state, action) {
        state.phase = action.payload;
      },
      setPairings(state, action) {
        state.pairings = action.payload;
      },

      setHasPendingTransaction(state, action) {
        state.hasPendingTransaction = action.payload;
      },
    },
});

export const {
  setUri,
  setAppMeta,
  setPublicKey,
  setKeypair,

  setAccount,
  setOffers,

  setPhase,
  setPairings,

  setHasPendingTransaction,
} = walletconnectSlice.actions;

export default walletconnectSlice.reducer;
