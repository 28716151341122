import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
    // isConnected,
    getPublicKey,
    signTransaction
} from '@stellar/freighter-api';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import StellarSdk from 'stellar-sdk';
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

export const transact = createAsyncThunk(
  'freighter/transact',
  async ({operations, memo, feeFactor = 10}, thunkAPI) => {
      const { publicKey, hasPendingTransaction } = thunkAPI.getState().freighter;
      if (!publicKey) {
          return Promise.reject('Freighter is not connected');
      }

      if (hasPendingTransaction) {
          return Promise.reject('Another transaction is currently in progress');
      }

      thunkAPI.dispatch(setHasPendingTransaction(true));

      const fee = await server.fetchBaseFee();

      const account = await server.loadAccount(publicKey);

      const tx = new StellarSdk.TransactionBuilder(account, {
          fee: fee * feeFactor, //: StellarSdk.BASE_FEE,
          networkPassphrase: StellarSdk.Networks.PUBLIC
      }).setTimeout(server.transactionTimeout);
      if (Array.isArray(operations)) {
          operations.forEach(op => {
              tx.addOperation(op);
          });
      } else {
          tx.addOperation(operations);
      }
      if (memo) {
          tx.addMemo(StellarSdk.Memo.text(memo));
      }

      const xdr = tx.build().toEnvelope().toXDR('base64');
      try {
          const result = await signTransaction(xdr);
          const signedTX = new StellarSdk.Transaction(result, StellarSdk.Networks.PUBLIC);
          return server.submitTransaction(signedTX)
              .then(result => {
                  if (thunkAPI.getState().freighter.publicKey) {
                      server.loadAccount(thunkAPI.getState().freighter.publicKey)
                          .then(account => thunkAPI.dispatch(setAccount(account)));
                  }
                  thunkAPI.dispatch(setHasPendingTransaction(false));
                  return result;
              })
              .catch(e => {
                  if (thunkAPI.getState().freighter.publicKey) {
                      thunkAPI.dispatch(loadFreighterAccount(thunkAPI.getState().freighter.publicKey));
                    //   server.loadAccount(thunkAPI.getState().freighter.publicKey)
                    //     .then(account => thunkAPI.dispatch(setAccount(account)));
                  }
                  thunkAPI.dispatch(setHasPendingTransaction(false));
                  return Promise.reject(e);
              });
      } catch (e) {
          if (thunkAPI.getState().freighter.publicKey) {
              server.loadAccount(thunkAPI.getState().freighter.publicKey)
                .then(account => thunkAPI.dispatch(setAccount(account)));
          }
          thunkAPI.dispatch(setHasPendingTransaction(false));
          return Promise.reject(e);
      }
  }
);

export const connect = createAsyncThunk(
    'freighter/connect',
    async (dummie, thunkAPI) => {
        try {
            const publicKey = await getPublicKey();
            const keypair = StellarSdk.Keypair.fromPublicKey(publicKey);
            thunkAPI.dispatch(setPublicKey(publicKey));
            server.loadAccount(publicKey)
              .then(account => thunkAPI.dispatch(setAccount(account)))
              .catch(() => {});
            thunkAPI.dispatch(setKeypair(keypair));
            return publicKey;
        } catch (e) {
            thunkAPI.dispatch(setError(e));
            return null;
        }
    }
);

export const logout = createAsyncThunk(
    'freighter/logout',
    async (dummie, thunkAPI) => {
        thunkAPI.dispatch(setPublicKey(null));
        thunkAPI.dispatch(setAccount(null));
        thunkAPI.dispatch(setOffers(null));
        thunkAPI.dispatch(setKeypair(null));
    }
);

export const loadFreighterAccount = createAsyncThunk(
    'walletconnect/loadFreighterAccount',
    async (accountId, thunkAPI) => {
        server.loadAccount(accountId)
            .then(account => thunkAPI.dispatch(setAccount(account)))
            .catch(() => {
                setTimeout(() => thunkAPI.dispatch(loadFreighterAccount(accountId)), 1000);
            });
        server
            .offers()
            .forAccount(accountId)
            .call()
            .then(resp => {
                // console.log(resp);
                thunkAPI.dispatch(setOffers(resp.records));
            })
            .catch(() => {
                setTimeout(() => thunkAPI.dispatch(loadFreighterAccount(accountId)), 1000);
            });
    }
);

export const freighterSlice = createSlice({
    name: 'freighter',
    initialState: {
        publicKey: null,
        keypair: null,
        account: null,
        offers: null,
        error: null,
        hasPendingTransaction: false,
    },
    reducers: {
      setPublicKey(state, action) {
        state.publicKey = action.payload ? action.payload.toUpperCase() : null;
      },
      setKeypair(state, action) {
        state.keypair = action.payload;
      },
      setAccount(state, action) {
        state.account = action.payload;
      },
      setOffers(state, action) {
        state.offers = action.payload;
      },
      setError(state, action) {
        state.error = action.payload;
      },

      setHasPendingTransaction(state, action) {
        state.hasPendingTransaction = action.hasPendingTransaction;
      },
    },
});

export const {
  setPublicKey,
  setKeypair,
  setAccount,
  setOffers,
  setError,
  setHasPendingTransaction,
} = freighterSlice.actions;

export default freighterSlice.reducer;