import React, {Fragment} from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import AssetsPairByReserves from '../../features/assets/AssetsPairByReserves.jsx';
import Reserve from '../../features/assets/Reserve.jsx';
import Link from '@mui/material/Link';

export default function WalletPoolBadge({poolId}) {
    const auth = useSelector((state) => state.wallet.auth);
    const wallet = useSelector((state) => state.wallet.account.id);
    const walletPool = useSelector((state) => state.wallet.pools[poolId]);
    const pool = useSelector((state) => state.pools.pools[poolId]);
    const lastPrice = useSelector((state) => state.prices.lastPrice);

    if (!pool) {
        return (<Fragment />);
    }

    const walletReserves = pool.reserves.map(r => ({
        ...r,
        amount: ((walletPool || {}).balance/pool.total_shares*r.amount).toFixed(7)
    }));
   
    const poolPrice = lastPrice[walletReserves[0].asset] && lastPrice[walletReserves[1].asset]
        ? lastPrice[walletReserves[0].asset]*walletReserves[0].amount + lastPrice[walletReserves[1].asset]*walletReserves[1].amount
        : 0;

    return (
        <Fragment>
            <Link component={ RouterLink } color="primary" to={ auth.granted ? `/pool/${pool.id}/${wallet}` : `/pool/${pool.id}` }>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    <AssetsPairByReserves reserves={ pool.reserves } />
                </Typography>
            </Link>
            <Typography noWrap variant="body2">
                <Typography component="span" color="primary">Balance:</Typography>&nbsp;{ (walletPool || {}).balance }&nbsp;({ ((walletPool || {}).balance/pool.total_shares*100).toFixed(2) }%)
            </Typography>
            <Typography component="span" color="primary">Reserves:</Typography>
            <Typography variant="body2">
                {
                    walletReserves
                    .map(r => (<Fragment key={r.asset}><Reserve reserve={r} /><br /></Fragment>))
                }
            </Typography>
            { poolPrice > 0 && (<Typography component="p" variant="h6" mt={1}>
                {poolPrice.toFixed(7)}&nbsp;USDC
            </Typography>) }
        </Fragment>
    );
}

//  onClick={(e => {e.preventDefault(); dispatch(choosePool(pool.id)); return false;})}