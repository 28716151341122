import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Icon,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { setWallet } from '../../app/rootSlice';
import { fetchAccountById } from '../../features/wallet/walletSlice';
import { login, connect, deletePairing, logout } from './walletconnectSlice';

export default function WalletConnect() {
    const {phase, uri, publicKey, pairings, appMeta} = useSelector((state) => state.walletconnect);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!phase && !uri && !publicKey) {
        dispatch(login());
    }

    // console.log(phase, uri, publicKey, pairings, appMeta);

    const drawWalet = () => {
        dispatch(setWallet(publicKey));
        dispatch(fetchAccountById(publicKey));
        navigate(`/wallet/${publicKey}`);
    };

    return (
        <Grid item xs={12}>
            <Paper
                sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                }}
            >
                <Typography component="h2" variant="h4" color="primary" gutterBottom>
                    WalletConnect
                </Typography>

                { phase === 'QR' && uri && (
                    <Grid sx={{ mt: 0.1, mb: 1 }} container spacing={2} justifyContent="center">
                            <QRCode value={uri} size={256} renderAs="svg" />
                    </Grid>
                ) }
                {/* phase === 'QR' && uri && (<Typography component="div" variant="p">
                    { uri }
            </Typography>) */}

                { publicKey && (<>
                    <Typography component="h3" variant="h5" color="primary" gutterBottom>
                        Connected
                    </Typography>
                    <Grid sx={{ mt: 0.1, mb: 1 }} container spacing={2} justifyContent="center">
                        <Grid item xs="auto" sx={{ mt: 1}}>
                            <Icon><img src={ appMeta.icons[0] } style={{width: '100%', marginBottom: 8}} alt={ appMeta.name } title={ appMeta.name } /></Icon>
                        </Grid>
                        <Grid item xs sx={{ mt: 1}}>
                            <Typography component="div" variant="p">
                                { publicKey }
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button variant="outlined" onClick={ drawWalet }>Draw</Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Button variant="outlined" onClick={ () => dispatch(logout()) }>Logout</Button>
                        </Grid>
                    </Grid>
                </>) }

                { phase === 'connectPairing' && pairings && pairings.length > 0 && (<>
                    <Typography component="h3" variant="h5" color="primary" gutterBottom>
                        Active sessions
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" stickyHeader>
                            <TableBody>
                                {pairings.map(p => (
                                    <TableRow key={ p.topic }>
                                        <TableCell>
                                            <Icon sx={{mr: 2, mb: -1}}><img
                                                src={ p.peerMetadata.icons[0] }
                                                style={{width: '100%', marginBottom: 8}}
                                                alt={ p.peerMetadata.name }
                                                title={ p.peerMetadata.name } /></Icon>
                                            { p.peerMetadata.description }
                                        </TableCell>
                                        <TableCell sx={{textAlign:'right'}}>
                                            <Button variant="outlined" onClick={ () => dispatch(connect(p)) }>Connect</Button>
                                            <Button variant="outlined" onClick={ () => dispatch(deletePairing(p.topic)) }>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{ mt: 1, mb: 1 }} container spacing={2} justifyContent="center">
                        <Button variant="contained" onClick={ () => dispatch(connect()) }>NEW CONNECTION</Button>
                    </Grid>
                </>)}
                <Dialog
                    open={ phase === 'sessionRequest' }
                >
                    <DialogTitle>
                        WalletConnect session request
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Session request has been sent to your wallet application for confirmation.
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Paper>
        </Grid>
    );
}