import React, {Fragment} from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

export default function WalletTotalBallance() {
    const wallet = useSelector((state) => state.root.wallet);
    const pools = useSelector((state) => state.pools.pools);
    const walletPools = useSelector((state) => state.wallet.pools);
    const walletBalances = useSelector((state) => state.wallet.balances);
    const lastPrice = useSelector((state) => state.prices.lastPrice);

    if (!wallet) {
        return (<Fragment />);
    }

    const walletPoolsTotal = Object.values(walletPools).reduce(
        (total, wPool) => {
            const pool = pools[wPool.liquidity_pool_id];
            if (!pool) {
                return total;
            }
            const walletReserves = pool.reserves.map(r => ({
                ...r,
                amount: (wPool.balance/pool.total_shares*r.amount).toFixed(7)
            }));

            return total + parseFloat(lastPrice[walletReserves[0].asset] && lastPrice[walletReserves[1].asset]
                ? lastPrice[walletReserves[0].asset]*walletReserves[0].amount + lastPrice[walletReserves[1].asset]*walletReserves[1].amount
                : 0);
        },
        0
    );

    const walletBalancesTotal = walletBalances.reduce(
        (total, b) => {
            if (!['native', 'credit_alphanum4', 'credit_alphanum12'].includes(b.asset_type)) {
                return total;
            }
            const asset = b.asset_type === 'native' ? 'native' : `${b.asset_code}:${b.asset_issuer}`;
            return total + (parseFloat(lastPrice[asset] * b.balance) || 0);
        },
        0
    );

    return (
        <Fragment>
            <Typography noWrap variant="body2">
                <Typography component="span" color="primary">Balance:</Typography>&nbsp;{ (walletPoolsTotal + walletBalancesTotal).toFixed(7) }&nbsp;USDC
            </Typography>
        </Fragment>
    );
}
