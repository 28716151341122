import React, {Fragment} from 'react';
import { useSelector } from 'react-redux';
import {
    Divider,
    Typography
 } from '@mui/material';
 import AssetCodeIcon from '../../features/assets/AssetCodeIcon.jsx';

export default function WalletPoolBadge({balance}) {
    const pools = useSelector((state) => state.pools.pools);
    const walletPools = useSelector((state) => state.wallet.pools);
    const lastPrice = useSelector((state) => state.prices.lastPrice);
    const asset = balance.asset_type === 'native' ? 'native' : `${balance.asset_code}:${balance.asset_issuer}`;
    const poolsWithAsset = Object.values(pools).filter(pool => (walletPools[pool.id] && pool.reserves.find(r => (r.asset === asset))));

    const balanceInPools = poolsWithAsset.length > 0
        ? poolsWithAsset.reduce((total, {id, total_shares, reserves}) => {
            return total + parseFloat(reserves.filter(r => r.asset === asset)[0].amount)*(walletPools[id] || {}).balance/total_shares;
            },
            0
        )
        : 0;

    const balancePrice = lastPrice[asset] ? lastPrice[asset]*(balanceInPools + parseFloat(balance.balance)) : 0;

    return (
        <Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                { balance.asset_type === 'native'
                    ? <AssetCodeIcon asset={ 'native' } />
                    : <AssetCodeIcon asset={ `${balance.asset_code}:${balance.asset_issuer}` } />
                }
            </Typography>

            <Typography noWrap variant="body2">
                <Typography component="span" color="primary">Balance:</Typography>&nbsp;{ balance.balance }
            </Typography>
            {
                balanceInPools > 0 && (
                    <>
                        <Typography noWrap variant="body2">
                            <Typography component="span" color="primary">In {poolsWithAsset.length} pools:</Typography>&nbsp;{ balanceInPools.toFixed(7) }
                        </Typography>
                        <Typography noWrap variant="body2">
                            <Typography component="span" color="primary">Total:</Typography>&nbsp;{ (balanceInPools + parseFloat(balance.balance)).toFixed(7) }
                        </Typography>
                    </>
                )
            }
            <Divider sx={{ flex: 1 }} />
            { balancePrice > 0 && (<Typography component="p" variant="h6" mt={1}>
                {balancePrice.toFixed(7)}&nbsp;USDC
            </Typography>) }
            
        </Fragment>
    );
}
