import React from 'react';
import { Link as RouterLink  } from 'react-router-dom';
import {
    Link,
    Typography
} from '@mui/material';
import { APP_NAME, VERSION, CONTACT_EMAIL } from '../app/constants';

export default function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" component={ RouterLink } to="/">
            { `${APP_NAME}(${VERSION})` }
            </Link>{'. '}
            <Link color="inherit" href={ 'mailto:' + CONTACT_EMAIL }>Contact us</Link>{'. '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}