import React from 'react';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import chroma from 'chroma-js';

ChartJS.register(ArcElement, Tooltip, Legend);

const borderColor = [
    '#aaaaaa'
];

const options = {
    plugins: {
        title: {
            display: true,
            text: 'Wallet pools',
        },
    },
};

export default function PoolsPie() {
    const pools = useSelector((state) => state.pools.pools);
    const walletPools = useSelector((state) => state.wallet.pools);
    const lastPrice = useSelector((state) => state.prices.lastPrice);

    let labels = [];
    let dataPoints = [];
    
    Object.values(walletPools)
        .forEach(wp => {
            const pool = pools[wp.liquidity_pool_id];
            if (!pool) {
                return;
            }
            labels.push(pool.reserves.map(({asset}) => asset === 'native' ? 'XLM' : asset.split(':')[0]).join('/'));

            const walletReserves = pool.reserves.map(r => ({
                ...r,
                amount: (wp || {}).balance/pool.total_shares*r.amount
            }));

            dataPoints.push(lastPrice[walletReserves[0].asset] && lastPrice[walletReserves[1].asset]
                ? lastPrice[walletReserves[0].asset]*walletReserves[0].amount + lastPrice[walletReserves[1].asset]*walletReserves[1].amount
                : 0);
        });

    let data = {
        labels,
        datasets: [
            {
                label: 'In USDC',
                data: dataPoints,
                backgroundColor: chroma.scale(['00429d', '96ffea', 'ffffe0', 'ff005e', '93003a']).colors(dataPoints.length),
                borderColor,
                borderWidth: 1,
            }
        ]
    };

    return <Pie data={data} options={options} />;//<></>;//
}