import React, {Fragment, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Grid,
    Pagination,
    Paper,
    Stack,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import AssetCodeIcon from '../assets/AssetCodeIcon.jsx';
import CustomAsset from '../assets/CustomAsset.jsx';
import Reserve from '../../features/assets/Reserve.jsx';
import {
    findPoolsByAssets,
    fetchAddCustomPool
} from './poolsSlice.js';

function getAssetsPair(firstAsset, secondAsset) {
    let assets = [];
    if (firstAsset) {
        assets.push(firstAsset);
    }
    if (secondAsset) {
        assets.push(secondAsset);
    }
    return assets;
}

const onPage = 10;

export default function CustomPool() {
    const [firstAsset, setFirstAsset] = useState('');
    const [secondAsset, setSecondAsset] = useState('');
    const [page, setPage] = useState(1);
    const poolsByAssets = useSelector(state => state.pools.poolsByAssets);
    const readyPools = useSelector(state => state.pools.common.concat(state.pools.custom));
    const dispatch = useDispatch();

    const assets = getAssetsPair(firstAsset, secondAsset);
    const foundPools = poolsByAssets[assets.join(',')] || [];

    if (foundPools.length <= onPage && page > 1) {
        setPage(1);
    }

    const maxPage = Math.ceil(foundPools.length / onPage);

    if (maxPage > 0 && maxPage < page) {
        setPage(maxPage);
    }

    const changePage = (e, val) => setPage(val);

    const findCustomPools = () => {
        if (assets.length === 0) {
            return;
        }
        dispatch(findPoolsByAssets(assets));
    };

    const addCustomPool = poolId => () => dispatch(fetchAddCustomPool(poolId));

    return (
        <Fragment>
            <Grid item xs={12}>
                <Paper
                    sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    }}
                >
                    <Typography component="h2" variant="h4" color="primary">
                        Custom pool search
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper
                    sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    }}
                >
                    <Typography component="h4" variant="h5">
                        First asset
                    </Typography>
                    <CustomAsset onChose={ setFirstAsset } />
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper
                    sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    }}
                >
                    <Typography component="h4" variant="h5">
                        Second asset
                    </Typography>
                    <CustomAsset onChose={ setSecondAsset } />
                </Paper>
            </Grid>
            { (firstAsset || secondAsset) && (
                <Grid item xs={12}>
                    <Paper
                        sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        }}
                    >
                        <Typography component="h4" variant="h5">
                            Find pools
                        </Typography>
                        <Stack direction="row" alignItems="center">
                            <Typography component="h5" variant="h6" sx={{pr:1}}>Choosen assets:</Typography>
                            { firstAsset && (<Box sx={{mr: 1, mt: 1}}><AssetCodeIcon asset={ firstAsset } /></Box>) }
                            { secondAsset && (<AssetCodeIcon asset={ secondAsset } />) }
                        </Stack>
                        <Button variant="outlined" onClick={ findCustomPools }>Find pools by chosen asset(s)</Button>
                    </Paper>
                </Grid>
            ) }
            { foundPools.length === 0 && (
                <Grid item xs={12}>
                    <Paper sx={{p: 1, textAlign: 'center'}}><Typography component="p" variant="body">Pools not found</Typography></Paper>
                </Grid>
            ) }
            {
                (foundPools.length) > 0 && (
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th">Asset A</TableCell>
                                        <TableCell component="th">Asset B</TableCell>
                                        <TableCell component="th">Trustlines</TableCell>
                                        <TableCell component="th">Reserves</TableCell>
                                        <TableCell component="th" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        foundPools.slice((page-1)*onPage, (page-1)*onPage + onPage).map(pool => (
                                            <TableRow key={ pool.id }>
                                                <TableCell size="small">
                                                    <AssetCodeIcon asset={ pool.reserves[0].asset } showSite={ true }/>
                                                </TableCell>
                                                <TableCell size="small">
                                                    <AssetCodeIcon asset={ pool.reserves[1].asset } showSite={ true }/>
                                                </TableCell>
                                                <TableCell size="small">
                                                    { pool.total_trustlines }
                                                </TableCell>
                                                <TableCell size="small">
                                                    {
                                                        pool.reserves
                                                            .map(r => (<Fragment key={r.asset}><Reserve reserve={r} /><br /></Fragment>))
                                                    }
                                                </TableCell>
                                                <TableCell size="small">
                                                    {
                                                        !readyPools.includes(pool.id) && (
                                                            <Button variant="outlined" onClick={ addCustomPool(pool.id) }>Add custom pool</Button>
                                                        )
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        { foundPools.length > 10 && (
                            <Paper sx={{mt: 1, p: 1}}>
                                <Pagination count={maxPage} page={page} onChange={ changePage } variant="outlined" color="primary" />
                            </Paper>
                        ) }
                    </Grid>
                )
            }
        </Fragment>
    );
}