import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LZString from 'lz-string';
import { CACHE_VERSION } from '../../app/constants';
// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

const USDC_ASSET_CODE = 'USDC';
const USDC_ASSET_ISSUER = 'GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN';
export const USDC_ASSET = `${USDC_ASSET_CODE}:${USDC_ASSET_ISSUER}`;

const unpacked = LZString.decompress(localStorage.getItem('priceHistoryCache'));
const parsed = unpacked ? JSON.parse(unpacked) : null;

const initialState = {
  priceHistory: (parsed && parsed._VERSION >= CACHE_VERSION
      ? parsed
      : {
          _VERSION: CACHE_VERSION
      }),
  _COUNTER: 0,
  priceHistoryStatuses: {},
  priceHistoryLock: {},
  lastPrice: {
    [USDC_ASSET]: 1
  },
  lastPriceStatuses: {
    [USDC_ASSET]: 'ready'
  },
  lastPriceTimestamp: {
    [USDC_ASSET]: (new Date()).getTime()
  },
  lastPriceStreams: {}
};

// const counter = new StellarSdk.Asset("USD", "GDUKMGUGDZQK6YHYA5Z6AY2G4XDSZPSZ3SW5UN3ARVMO6QSRDWP5YLEX");
const counter = new StellarSdk.Asset(USDC_ASSET_CODE, USDC_ASSET_ISSUER);

const resolutionDay = 86400000;

export const fetchAssetPriceHistory = createAsyncThunk(
    'prices/fetchAssetPriceHistory',
    async (asset, thunkAPI) => {
      if (asset === USDC_ASSET) {
        return;
      }

      const state = thunkAPI.getState();
      if (state.prices.priceHistoryLock[asset] && state.prices.poolHistoryLock[asset] > ((new Date()).getTime() - 120000)) {
          return;
      }
      thunkAPI.dispatch(lockPriceHistoryLoading(asset));

      const base = asset === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(...(asset.split(':')));

      let priceHistory = state.prices.priceHistory[asset] || {};
      if (Object.keys(priceHistory).length > 0) {
        const oldestTs = Math.min(...Object.keys(priceHistory).map(ts => (parseInt(ts))));
        server
          .tradeAggregation(base, counter, 0, oldestTs, resolutionDay, 0)
          .limit(200)
          .order('desc')
          .call()
          .then(priceHistoryPage => {
            const priceHistory = thunkAPI.getState().prices.priceHistory[asset] || {};
            thunkAPI.dispatch(mergePriceHistory({asset, priceHistoryPage}));
            if (priceHistoryPage.records.length === 200
              && priceHistoryPage.next
              && priceHistoryPage.records.filter(r => !!priceHistory[r.timestamp]).length < 2
            ) {
                thunkAPI.dispatch(fetchPriceHistoryNext({asset, next: priceHistoryPage.next}));
            }
          })
          .catch(err => {
            // eslint-disable-next-line eqeqeq
            if (err?.response?.status == 503) {
                return thunkAPI.dispatch(fetchAssetPriceHistory(asset));
            } else {
                console.log(err);
            }
          });
      }

     // fresh data
      const tradeAgregationFresh = await server
          .tradeAggregation(base, counter, 0, 0, resolutionDay, 0)
          .cursor('now')
          .limit(200)
          .order('desc')
          .call()
          .catch(err => {
            // eslint-disable-next-line eqeqeq
            if (err?.response?.status == 503) {
                return thunkAPI.dispatch(fetchAssetPriceHistory(asset));
            } else {
                console.log(err);
            }
          });

      priceHistory = thunkAPI.getState().prices.priceHistory[asset] || {};
      thunkAPI.dispatch(mergePriceHistory({asset, priceHistoryPage: tradeAgregationFresh}));
      if (tradeAgregationFresh.next
        && tradeAgregationFresh.records.length === 200
        && tradeAgregationFresh.records.filter(r => !!priceHistory[r.timestamp]).length < 2
      ) {
        thunkAPI.dispatch(fetchPriceHistoryNext({asset, next: tradeAgregationFresh.next}));
      }
      
      return tradeAgregationFresh;
    }
);

const fetchPriceHistoryNext = createAsyncThunk(
  'prices/fetchPriceHistoryNext',
  async ({asset, next}, thunkAPI) => {
      thunkAPI.dispatch(lockPriceHistoryLoading(asset));

      const priceHistoryPage = await next()
        .catch(err => {
          // eslint-disable-next-line eqeqeq
          if (err?.response?.status == 503) {
              thunkAPI.dispatch(fetchPriceHistoryNext({asset, next}));
          } else {
              console.log(err);
          }
        });
     
      const priceHistory = thunkAPI.getState().prices.priceHistory[asset] || {};
      thunkAPI.dispatch(mergePriceHistory({asset, priceHistoryPage}));
      if (priceHistoryPage.records.length === 200
        && priceHistoryPage.next
        && priceHistoryPage.records.filter(r => !!priceHistory[r.timestamp]).length < 2
      ) {
          thunkAPI.dispatch(fetchPriceHistoryNext({asset, next: priceHistoryPage.next}));
      }

      return priceHistoryPage;
  }
);

export const fetchAssetLastPrice = createAsyncThunk(
    'prices/fetchAssetLastPrice',
    async (asset, thunkAPI) => {
      const state = thunkAPI.getState();
      if (asset === USDC_ASSET) {
        return;
      }
      if (new Date().getTime() - (state.prices.lastPriceTimestamp[asset] || 0) < 30000) {
        return;
      }
      const base = asset === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(...(asset.split(':')));
      const query = server.trades().forAssetPair(base, counter).cursor("now");
      await query.call()
        .then((resp) => resp.records.length > 0
          ? thunkAPI.dispatch(setLastPrice(resp.records[0]))
          : resp.prev()
            .then(resp => thunkAPI.dispatch(setLastPrice(resp.records[0]))));
      // return query.stream({ onmessage: resp => thunkAPI.dispatch(setLastPrice(resp)) });
    }
);

export const pricesSlice = createSlice({
    name: 'prices',
    initialState,
    reducers: {
      lockPriceHistoryLoading: (state, action) => {
          state.priceHistoryLock[action.payload] = (new Date()).getTime();
      },
      setLastPrice(state, action) {
        const trade = action.payload;
        const asset = trade.base_asset_type === 'native' ? 'native' : `${trade.base_asset_code}:${trade.base_asset_issuer}`;
        const price = trade.price ? trade.price.n/trade.price.d : 0;
        if (price) {
          state.lastPrice[asset] = price; 
          state.lastPriceTimestamp[asset] = (new Date(trade.ledger_close_time) || new Date()).getTime();
        }
      },
      mergePriceHistory(state, action) {
        const {asset, priceHistoryPage} = action.payload;

        state.priceHistory[asset] = {
          ...(state.priceHistory[asset] || {}),
          ...Object.fromEntries(
            priceHistoryPage.records.map(p => ([
              p.timestamp,
              {
                timestamp: parseInt(p.timestamp),
                trade_count: parseInt(p.trade_count),
                base_volume: parseFloat(p.base_volume),
                counter_volume: parseFloat(p.counter_volume),
                avg: parseFloat(p.avg),
                high: parseFloat(p.high),
                low: parseFloat(p.low),
                open: parseFloat(p.open),
                close: parseFloat(p.close)
              }
            ]))
          )
        };

        if (++state._COUNTER >= 50 || priceHistoryPage.records.length < 200) {
            state._COUNTER = 0;
            localStorage.setItem('priceHistoryCache', LZString.compress(JSON.stringify(state.priceHistory)));
        }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchAssetPriceHistory.pending, (state, action) => {
            state.priceHistoryStatuses[action.meta.arg.asset] = 'pending';
      });
      builder.addCase(fetchAssetPriceHistory.rejected, (state, action) => {
            state.priceHistoryStatuses[action.meta.arg.asset] = 'error';
      });
      builder.addCase(fetchAssetPriceHistory.fulfilled, (state, action) => {
          if (!action.payload) {
              return;
          }
          state.priceHistoryStatuses[action.meta.arg.asset] = 'ready';

          state.priceHistory[action.meta.arg.asset] = {
            ...(state.priceHistory[action.meta.arg.asset] || {}),
            ...Object.fromEntries(
              action.payload.records.map(p => ([
                p.timestamp,
                {
                  timestamp: parseInt(p.timestamp),
                  trade_count: parseInt(p.trade_count),
                  base_volume: parseFloat(p.base_volume),
                  counter_volume: parseFloat(p.counter_volume),
                  avg: parseFloat(p.avg),
                  high: parseFloat(p.high),
                  low: parseFloat(p.low),
                  open: parseFloat(p.open),
                  close: parseFloat(p.close)
                }
              ]))
            )
          };
      });

      builder.addCase(fetchAssetLastPrice.pending, (state, action) => {
        state.lastPriceStatuses[action.meta.arg] = 'pending';
      });
      builder.addCase(fetchAssetLastPrice.rejected, (state, action) => {
            state.lastPriceStatuses[action.meta.arg] = 'error';
      });
      builder.addCase(fetchAssetLastPrice.fulfilled, (state, action) => {
          if (!action.payload) {
              return;
          }
          state.lastPriceStatuses[action.meta.arg] = 'ready';
          state.lastPriceStreams[action.meta.arg] = action.payload;
      });
    }
});

const { setLastPrice, lockPriceHistoryLoading, mergePriceHistory } = pricesSlice.actions;

export default pricesSlice.reducer;