import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Icon,
    Stack,
    Typography
} from '@mui/material';
import { fetchAsset } from './assetsSlice';

export default function AssetCodeIcon({asset, hideCodes, showSite}) {
    const assetData = useSelector((state) => state.assets.assets[asset]);
    const dispatch = useDispatch();
    if (!assetData) {
        setTimeout(() => dispatch(fetchAsset(asset)), 50);
    }

    return (<Stack direction="row" alignItems="center" sx={{display: 'inline-flex'}}>
        { assetData?.image
            ? <Icon sx={{textAlign: 'center', mr: (hideCodes ? '1px' : 1)}}><img src={ assetData?.image } style={{width: '100%', marginBottom: 6}} alt={ assetData.asset_code } /></Icon>
            : (hideCodes ? getAssetCode(asset).substring(0, 1) : <></>)
        }
        { !hideCodes && getAssetCode(asset) }
        { !hideCodes && showSite && assetData?.site && (<Typography sx={{ ml: '3px' }} variant="bodySmall" element="i">({assetData.site})</Typography>) }
    </Stack>);
}

function getAssetCode(asset) {
    return (asset === 'native' ? 'XLM' : asset.split(':').shift());
}