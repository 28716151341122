import { useSelector } from 'react-redux';
import {
    Box,
    Grid,
    Paper
} from '@mui/material';
import WalletPoolBadge from './WalletPoolBadge.jsx';
import PoolsPie from './PoolsPie.jsx';

export default function WalletPoolsDashboard() {
    const walletPools = useSelector((state) => state.wallet.pools);

    return (<>
        {
            Object.keys(walletPools).length > 0 && (
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                        height: 675,
                        textAlign: 'center'
                        }}
                    >
                        <Box sx={{p:2, height: 675, maxHeight:675, maxWidth:640, display: 'inline-block', width: '100%' }}>
                            <PoolsPie />
                        </Box>
                    </Paper>
                </Grid>
            )
        }
        <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
            { 
                Object.keys(walletPools).slice(0, 6).map(poolId => (<Grid key={ poolId } item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 210,
                        }}
                    >
                        <WalletPoolBadge poolId={ poolId } />
                    </Paper>
                </Grid>))
            }
            </Grid>
        </Grid>
        { 
            Object.keys(walletPools).length > 6 && Object.keys(walletPools).slice(6).map(poolId => (<Grid key={ poolId } item xs={12} md={6} lg={3}>
                <Paper
                    sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    }}
                >
                    <WalletPoolBadge poolId={ poolId } />
                </Paper>
            </Grid>))
        }
    </>);
}