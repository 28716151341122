// import { useSelector } from 'react-redux';
import {
    Box,
    Typography
} from '@mui/material';
import {
    PAY_ASSET,
    PAY_ASSET_LOCK_AMOUNT,
    // PAY_ASSET_LOCK_PERIOD,
    PAY_ASSET_RETURN_CLAIM_WALLET
} from '../../app/constants';
import PayQR from './PayQR.jsx';
import CreateClaimableGrants from './CreateClaimableGrants.jsx';

export default function LockBalanceForm() {
    // const rootWallet = useSelector((state) => state.root.wallet);

    return (
        <>
        <Box sx={{mb: 2}}>
            <Typography variant="h5" gutterBottom component="div">
                You want to get acces to extra services by create locked ballance:
            </Typography>
            <Typography variant="p" component="div">
            Within 5 minutes after the funds are credited to the specified wallet, our script will automatically return the sent amount in the form of a claimable balance.
            </Typography>
            <Typography variant="p" component="div">
            The funds will be sent to the wallet from which the payment was made. These funds will be locked.
            </Typography>
            <Typography variant="p" component="div">
            The presence of such a locked balance in the wallet is a condition for granting access to the service.
            </Typography>
            <Typography variant="p" component="div">
            You can unlock the balance for use after 30 days.
            </Typography>
            <Typography variant="p" component="div">
            Also, you can not unlock them immediately after 30 days to maintain access to the service.
            </Typography>
        </Box>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="p" component="div">
                You may pay { PAY_ASSET_LOCK_AMOUNT } { PAY_ASSET.asset_code } to the wallet <Typography variant='p' component="strong">{ PAY_ASSET_RETURN_CLAIM_WALLET }</Typography>
            </Typography>
        
            <Typography variant="p" component="div">
                You can pay using the link QR code:
            </Typography>
            <PayQR wallet={ PAY_ASSET_RETURN_CLAIM_WALLET } amount={ PAY_ASSET_LOCK_AMOUNT } asset={ PAY_ASSET } canPayConnected={ true } />
        </Box>
        <CreateClaimableGrants />
        </>
    );
}
/* <Typography variant="p" component="div">
{ memo }
<TextField
    sx={{ width: 300 }}
    size="small"
    label="Memo"
    variant="standard"
    value={ memo } />
</Typography>
*/