import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isConnected } from '@stellar/freighter-api';
import {
    Button,
    Grid,
    Icon,
    Link,
    Paper,
    Typography
} from '@mui/material';
import { setWallet } from '../../app/rootSlice';
import { fetchAccountById } from '../../features/wallet/walletSlice';
import { connect, logout } from './freighterSlice';

export default function FreighterConnect() {
    const {publicKey} = useSelector((state) => state.freighter);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const drawWalet = () => {
        dispatch(setWallet(publicKey));
        dispatch(fetchAccountById(publicKey));
        navigate(`/wallet/${publicKey}`);
    };

    return (
        <Grid item xs={12}>
            <Paper
                sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                }}
            >
                <Typography component="h2" variant="h4" color="primary" gutterBottom>
                    Freighter
                </Typography>

                { !isConnected() ? (<><Grid sx={{ mt: 1, mb: 1 }} container spacing={2} justifyContent="center">
                        <Typography component="div" variant="p">
                            Freighter extension is not installed.
                        </Typography>
                    </Grid>
                    <Grid sx={{ mt: 1, mb: 1 }} container spacing={2} justifyContent="center">
                        <Typography component="div" variant="p">
                            <Link href="https://www.freighter.app/" target="_blank" rel="nofollow noopener noreferrer">Install Freighter</Link>
                        </Typography>
                    </Grid></>)
                    : (
                        publicKey
                        ? (<><Typography component="h3" variant="h5" color="primary" gutterBottom>
                                Connected
                            </Typography>
                            <Grid sx={{ mt: 0.1, mb: 1 }} container spacing={2} justifyContent="center">
                                <Grid item xs="auto" sx={{ mt: 1}}>
                                    <Icon><img src="/freighter.svg" style={{width: '100%', marginBottom: 8}} alt="Freighter" title="Freighter" /></Icon>
                                </Grid>
                                <Grid item xs sx={{ mt: 1}}>
                                    <Typography component="div" variant="p">
                                        { publicKey }
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button variant="outlined" onClick={ drawWalet }>Draw</Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button variant="outlined" onClick={ () => dispatch(logout()) }>Logout</Button>
                                </Grid>
                            </Grid>
                        </>)
                        : (<Grid sx={{ mt: 1, mb: 1 }} container spacing={2} justifyContent="center">
                            <Button
                                variant="contained"
                                sx={{ verticalAlign: 'bottom' }}
                                onClick={ () => {dispatch(connect())} }
                            >
                                Connect with Freighter
                            </Button>
                        </Grid>)
                    )
                }

            </Paper>
        </Grid>
    );
}