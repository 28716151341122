import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
  ListSubheader
}           from '@mui/material';
import CommonPoolLeftMenuItem from './CommonPoolLeftMenuItem.jsx';

export default function LeftCommonPoolsMenu({isOpen}) {
    const commonPools = useSelector((state) => state.pools.common);
    const pools = useSelector((state) => state.pools.pools);

    if (commonPools.length === 0 || Object.keys(pools).length === 0) {
        return (<Fragment />);
    }

    const commonPoolsWithData = Object.values(pools).filter(p => (commonPools.includes(p.id)));
    const commonPoolsAssets = commonPoolsWithData.reduce((assets, cp) => {
            return assets.concat(cp.reserves.map(({asset}) => (asset)).filter(asset => (!assets.includes(asset))));
        },
        []
    )
    .sort()
    .map(asset => ({
        asset,
        pools: commonPoolsWithData.filter(cp => cp.reserves.map(r => (r.asset)).includes(asset))
    }));

    return (
        <Fragment>
            <ListSubheader component="div" inset>
                Popular Pools:
            </ListSubheader>
            { 
                commonPoolsAssets.map(cp => (<CommonPoolLeftMenuItem key={cp.asset} assetData={ cp } isExpanded={ isOpen } />))
            }
        </Fragment>
    );
}
