export function getReservesPrice(priceHistory, dateStr, reserves) {
    return reserves.reduce((total, {asset, amount}) => {
            let price = getAssetPrice(priceHistory, dateStr, asset);
            return isNaN(price) ? NaN : total + price * parseFloat(amount);
        },
        0
    );
}

export function getReservesByRatesPrice(exchangeRates, reserves) {
    return reserves.reduce((total, {asset, amount}) => {
            let price = exchangeRates[asset];
            return isNaN(price) ? NaN : total + price * parseFloat(amount);
        },
        0
    );
}

export function getAssetPrice(priceHistory, dateStr, asset) {
    if (asset === 'USDC:GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN') {
        return 1;
    }
    let timestamp = (new Date(dateStr)).setUTCHours(0,0,0,0);
    if (!priceHistory[asset]) {
        return NaN;
    }
    if (!priceHistory[asset][timestamp]) {
        timestamp = Math.max(...Object.keys(priceHistory[asset]).filter(t => (t < timestamp)));
    }
    return (priceHistory[asset][timestamp] || {}).avg || NaN;
}