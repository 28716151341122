import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,

    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import AssetCodeIcon from '../assets/AssetCodeIcon.jsx';
import AssetCode from '../assets/AssetCode.jsx';
import WCTransact from '../walletconnect/WCTransact.jsx';
import FreighterTransact from '../freighter/FreighterTransact.jsx';
import {
    isBalanceCertainAsset,
    assetObjFromStr
} from '../../lib/assets';
import { fetchPoolById }  from '../pools/poolsSlice';
import { fetchPoolHistory } from '../poolsHistory/poolsHistorySlice';


// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
// const server = new StellarSdk.Server('https://horizon.stellar.org');

function offersReducer(total, {amount}) {
    return total + parseFloat(amount);
}

function assetHR(asset) {
    return asset?.asset_type === 'native' ? 'XLM' : asset?.asset_code;
}

function floatFixed(amount) {
    return parseFloat(parseFloat(amount).toFixed(7));
}

function percenter(percent, amount, changer) {
    return () => changer({
        target: {
            value: percent /100 * amount
        }
    }); 
}

export default function LiquidityIncrease({pool}) {
    const wcAccount = useSelector((state) => state.walletconnect.account);
    const freighterAccount = useSelector((state) => state.freighter.account);
    const wcOffers = useSelector((state) => state.walletconnect.offers);
    const freighterOffers = useSelector((state) => state.freighter.offers);
    const [showDialog, setShowDialog] = useState(false);
    const [assetAAmount, setAssetAAmount] = useState(0);
    const [assetBAmount, setAssetBAmount] = useState(0);
    const dispatch = useDispatch();

    const connectedAccount = wcAccount || freighterAccount;
    const connectedAccountOffers = (wcAccount ? wcOffers : freighterOffers) || [];

    const Transact = wcAccount ? WCTransact : FreighterTransact;
    const rateA2B = parseFloat(pool.reserves[1].amount) / parseFloat(pool.reserves[0].amount);
    const rateB2A = parseFloat(pool.reserves[0].amount) / parseFloat(pool.reserves[1].amount);

    const handleShowDialog = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const changeAAmount = e => {
        setAssetAAmount( floatFixed(e.target.value) );
        setAssetBAmount( floatFixed(parseFloat(e.target.value) * rateA2B) );
    };

    const changeBAmount = e => {
        setAssetBAmount( floatFixed(e.target.value) );
        setAssetAAmount( floatFixed(parseFloat(e.target.value) * rateB2A) );
    };

    const assetA = assetObjFromStr(pool.reserves[0].asset);
    const assetB = assetObjFromStr(pool.reserves[1].asset);
    
    const assetABalance = parseFloat(connectedAccount.balances.filter(b => isBalanceCertainAsset(b, assetA)).shift()?.balance || 0);
    const assetAinOffers = connectedAccountOffers.filter(o => isBalanceCertainAsset(o.selling, assetA)).reduce(offersReducer, 0);
    const assetAMax = assetABalance - assetAinOffers;
    const assetBBalance = parseFloat(connectedAccount.balances.filter(b => isBalanceCertainAsset(b, assetB)).shift()?.balance || 0);
    const assetBinOffers = connectedAccountOffers.filter(o => isBalanceCertainAsset(o.selling, assetB)).reduce(offersReducer, 0);
    const assetBMax = assetBBalance - assetBinOffers;

    const poolBalance = connectedAccount.balances.filter(b => (b?.liquidity_pool_id === pool.id)).shift();

    const assetAAmountIsErrored = assetAAmount > (assetABalance - assetAinOffers);
    const assetBAmountIsErrored = assetBAmount > (assetBBalance - assetBinOffers);

    let operations = [];

    if (!poolBalance) {
        const lpAsset = new StellarSdk.LiquidityPoolAsset(
            (assetA?.asset_type === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(assetA.asset_code, assetA.asset_issuer)),
            (assetB?.asset_type === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(assetB.asset_code, assetB.asset_issuer)),
            30
        );
        operations.push(StellarSdk.Operation.changeTrust({asset: lpAsset}));
    }
    if (assetAAmount > 0 && assetBAmount > 0) {
        operations.push(StellarSdk.Operation.liquidityPoolDeposit({
            liquidityPoolId: pool.id,
            maxAmountA: parseFloat(assetAAmount).toFixed(7),
            maxAmountB: parseFloat(assetBAmount).toFixed(7),
            minPrice: floatFixed(0.98*assetAAmount/assetBAmount), // 2%
            maxPrice: floatFixed(1.02*assetAAmount/assetBAmount), // 2%
        }));
    }

    const onIncreased = () => {
        dispatch(fetchPoolById(pool.id));
        dispatch(fetchPoolHistory(pool.id))
        setShowDialog(false);
    };

    return (
        <>
            <Button
                sx={{mr: 1, mb:1}}
                variant="contained"
                onClick={ handleShowDialog }
            >
                Increase Liquidity
            </Button>
            <Dialog
                open={!!showDialog}
                onClose={ handleCloseDialog }
            >
                <DialogTitle>
                    Increase Liquidity
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" alignItems="flex-end" spacing={1}>
                        <TextField size="small" type="number" label="Asset A" variant="standard" error={ assetAAmountIsErrored } value={ assetAAmount } onChange={ changeAAmount } />
                        <AssetCodeIcon asset={ pool.reserves[0].asset } showSite={ true } />
                    </Stack>
                    <Stack direction="row" alignItems="flex-end" spacing={1} sx={{mt: 1, mb: 2}}>
                        <Grid item xs="auto" sx={{mb: 1}}>
                            <Typography variant="body2" component="span">Available: </Typography>
                            <Typography variant="body2" component="b">{ assetAMax.toFixed(7) } {
                                assetHR(assetA)
                            }</Typography>
                        </Grid>
                        <Grid item xs={true} sx={{textAlign:'right'}} zeroMinWidth>
                            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={1} sx={{ mb: 1}}>
                                <Button variant="outlined" size="small" onClick={ percenter(25, assetAMax, changeAAmount) }>
                                    25%
                                </Button><Button variant="outlined" size="small" onClick={ percenter(50, assetAMax, changeAAmount) }>
                                    50%
                                </Button><Button variant="outlined" size="small" onClick={ percenter(75, assetAMax, changeAAmount) }>
                                    75%
                                </Button><Button variant="outlined" size="small" onClick={ percenter(100, assetAMax, changeAAmount) }>
                                    100%
                                </Button>
                            </Stack>
                        </Grid>
                    </Stack>
                    
                    <Stack direction="row" alignItems="flex-end" spacing={1}>
                        <TextField size="small" type="number" label="Asset B" variant="standard" error={ assetBAmountIsErrored } value={ assetBAmount } onChange={ changeBAmount } />
                        <AssetCodeIcon asset={ pool.reserves[1].asset } showSite={ true } />
                    </Stack>
                    <Stack direction="row" alignItems="flex-end" spacing={1} sx={{mt: 1, mb: 2}}>
                        <Grid item xs="auto" sx={{mb: 1}}>
                            <Typography variant="body2" component="span">Available: </Typography>
                            <Typography variant="body2" component="b">{ assetBMax.toFixed(7) } {
                                assetHR(assetB)
                            }</Typography>
                        </Grid>
                        <Grid item xs={true} sx={{textAlign:'right'}} zeroMinWidth>
                            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={1} sx={{ mb: 1}}>
                                <Button variant="outlined" size="small" onClick={ percenter(25, assetBMax, changeBAmount) }>
                                    25%
                                </Button><Button variant="outlined" size="small" onClick={ percenter(50, assetBMax, changeBAmount) }>
                                    50%
                                </Button><Button variant="outlined" size="small" onClick={ percenter(75, assetBMax, changeBAmount) }>
                                    75%
                                </Button><Button variant="outlined" size="small" onClick={ percenter(100, assetBMax, changeBAmount) }>
                                    100%
                                </Button>
                            </Stack>
                        </Grid>
                    </Stack>
                    <Box sx={{ p: 1, border:1, borderColor: 'info.light', borderRadius: 1 }}>
                        <Typography variant="body1" component="div">Rates: </Typography>
                        <Typography variant="body2" component="div">1 <AssetCode asset={ pool.reserves[0].asset } />  ≈ {
                            rateA2B.toFixed(7)
                        } <AssetCode asset={ pool.reserves[1].asset } /></Typography>
                        <Typography variant="body2" component="div">1 <AssetCode asset={ pool.reserves[1].asset } />  ≈ {
                            rateB2A.toFixed(7)
                        } <AssetCode asset={ pool.reserves[0].asset } /></Typography>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Transact
                        buttonText="Increase Liquidity"
                        operations={ operations }
                        feeFactor={ 100 }
                        callback={ onIncreased }
                        disabled= {
                            parseFloat(assetAAmount) === 0
                            || parseFloat(assetBAmount) === 0
                            || assetAAmountIsErrored
                            || assetBAmountIsErrored
                            || operations.length === 0
                        } />
                    <Button variant="outlined" onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}