import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,

    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {
    PAY_ASSET,
    PAY_ASSET_STRING,
    PAY_ASSET_HODL_AMOUNT,
    PAY_ASSET_LOCK_AMOUNT,
    PAY_VARIANTS
} from '../../app/constants';
import AssetCodeIcon from '../assets/AssetCodeIcon.jsx';
import AssetCode from '../assets/AssetCode.jsx';
import AssetSelector from '../assets/AssetSelector.jsx';
import WCTransact from '../walletconnect/WCTransact.jsx';
import FreighterTransact from '../freighter/FreighterTransact.jsx';
import {
    isBalanceCertainAsset,
    assetObjFromStr
} from '../../lib/assets';
import {
    fetchAssetLastPrice
} from '../prices/pricesSlice';
import { fetchAccountById } from './walletSlice';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

const slippage = 2; // 2%

function offersReducer(total, {amount}) {
    return total + parseFloat(amount);
}

function floatFixed(amount) {
    return parseFloat(parseFloat(amount).toFixed(7));
}

const payAssetObj = new StellarSdk.Asset(PAY_ASSET.asset_code, PAY_ASSET.asset_issuer);

function getStellarAssetObj(asset) {
    return asset === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(...(asset.split(':')));
}

function getSendPath(asset, amount) {
    return server.strictSendPaths(
        getStellarAssetObj(asset),
        parseFloat(amount).toFixed(7),
        [payAssetObj]
    )
    .call()
    .then(({records}) => {
        if (!records.length) {
            return null;
        }
        return records.reduce((acc, item) =>
            (Number(acc.destination_amount) > Number(item.destination_amount) ? acc : item));
    });
}

function getRecievePath(asset, amount) {
    return server.strictReceivePaths(
        [getStellarAssetObj(asset)],
        payAssetObj,
        parseFloat(amount).toFixed(7)
    )
    .call()
    .then(({records}) => {
        if (!records.length) {
            return null;
        }
        return records.reduce((acc, item) =>
            (Number(acc.source_amount) < Number(item.source_amount) ? acc : item));
    });
}

const addPayAssetOperation = StellarSdk.Operation.changeTrust({
    asset: payAssetObj,
    limit: undefined,
});

export default function Swap2PayAsset() {
    const rootWallet = useSelector((state) => state.root.wallet);
    const wcAccount = useSelector((state) => state.walletconnect.account);
    const freighterAccount = useSelector((state) => state.freighter.account);
    const wcOffers = useSelector((state) => state.walletconnect.offers);
    const freighterOffers = useSelector((state) => state.freighter.offers);
    const [showDialog, setShowDialog] = useState(false);
    const [asset2Swap, setAsset2Swap] = useState('native');
    const [asset2SwapIsLastEdited, setAsset2SwapIsLastEdited] = useState(false);
    const [assetAmount, setAssetAmount] = useState(0);
    const [payAssetAmount, setPayAssetAmount] = useState(0);
    const lastPrices = useSelector((state) => state.prices.lastPrice);

    const dispatch = useDispatch();

    const connectedAccount = wcAccount || freighterAccount;
    const connectedAccountOffers = (wcAccount ? wcOffers : freighterOffers) || [];

    if (!connectedAccount) {
        return <></>;
    }
    const Transact = wcAccount ? WCTransact : FreighterTransact;

    const asset2SwapPrice = lastPrices[asset2Swap] || 0;
    const payAssetPrice = lastPrices[PAY_ASSET_STRING] || 0;

    if (asset2SwapPrice === 0) {
        dispatch(fetchAssetLastPrice(asset2Swap));
    }

    if (payAssetPrice === 0) {
        dispatch(fetchAssetLastPrice(PAY_ASSET_STRING));
    }

    const assets = connectedAccount.balances
        .filter(b => b.asset_type !== 'liquidity_pool_shares' && !isBalanceCertainAsset(b, PAY_ASSET))
        .map(b => (b.asset_type === 'native' ? 'native' : `${b.asset_code}:${b.asset_issuer}`));

    const handleShowDialog = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const changeAmount = e => {
        setAssetAmount( floatFixed(e.target.value) );
        setAsset2SwapIsLastEdited(true);
        dispatch(fetchAssetLastPrice(asset2Swap));
        if (e.target.value > 0) {
            getSendPath(asset2Swap, e.target.value)
                .then(path => {
                    setPayAssetAmount(path.destination_amount);
                });
        } else {
            setPayAssetAmount(0);
        }

    };

    const changePayAssetAmount = e => {
        setPayAssetAmount( floatFixed(e.target.value) );
        setAsset2SwapIsLastEdited(false);
        dispatch(fetchAssetLastPrice(PAY_ASSET_STRING));
        if (payAssetPrice && asset2SwapPrice && e.target.value > 0) {
            setAssetAmount(e.target.value * payAssetPrice / asset2SwapPrice);
        }

        if (e.target.value > 0) {
            getRecievePath(asset2Swap, e.target.value)
                .then(path => {
                    setAssetAmount(path.source_amount);
                });
        } else {
            setAssetAmount(0);
        }
    };

    const choseAsset = asset => {
        setAsset2Swap(asset);
        setAsset2SwapIsLastEdited(false);
        dispatch(fetchAssetLastPrice(asset));
        if (!payAssetAmount) {
            return;
        }
        if (parseFloat(payAssetAmount) > 0) {
            getRecievePath(asset, payAssetAmount)
                .then(path => {
                    setAssetAmount(path.source_amount);
                });
        }
    };

    const asset2SwapBalance = parseFloat(connectedAccount.balances.filter(b => isBalanceCertainAsset(b, assetObjFromStr(asset2Swap))).shift()?.balance || 0);
    const asset2SwapInOffers = connectedAccountOffers.filter(o => isBalanceCertainAsset(o.selling, assetObjFromStr(asset2Swap))).reduce(offersReducer, 0);
    const asset2SwapMax = asset2SwapBalance - asset2SwapInOffers;
    const payAssetAccountBalanceObj = connectedAccount.balances.filter(b => isBalanceCertainAsset(b, PAY_ASSET)).shift();
    const payAssetBalance = payAssetAccountBalanceObj ? parseFloat(payAssetAccountBalanceObj.balance || 0) : 0;
    const payAssetInOffers = connectedAccountOffers.filter(o => isBalanceCertainAsset(o.selling, PAY_ASSET)).reduce(offersReducer, 0);
    const payAssetMax = payAssetBalance - payAssetInOffers;

    const assetAmountIsErrored = assetAmount > asset2SwapMax - (asset2Swap === 'native' ? 1 : 0);

    let amountVariants = PAY_VARIANTS.map(({amount}) => (amount));
    if (!amountVariants.includes(PAY_ASSET_HODL_AMOUNT)) {
        amountVariants.push(PAY_ASSET_HODL_AMOUNT);
    }
    if (!amountVariants.includes(PAY_ASSET_LOCK_AMOUNT)) {
        amountVariants.push(PAY_ASSET_LOCK_AMOUNT);
    }

    const useMaxAssetAmount = () => {
        changeAmount({
            target: {
                value: (Math.max(asset2SwapMax - (asset2Swap === 'native' ? 1 : 0), 0)).toFixed(7)
            }
        })
    }

    const onSwaped = () => {
        if (rootWallet === connectedAccount.account_id) {
            dispatch(fetchAccountById(connectedAccount.account_id));
        }
        // setShowDialog(false);
    };

    let operations = payAssetAccountBalanceObj ? [] : [addPayAssetOperation];
    if (parseFloat(assetAmount) > 0 && parseFloat(payAssetAmount) > 0 && !assetAmountIsErrored) {
        operations.push(
            asset2SwapIsLastEdited
            ? () => (
                getSendPath(asset2Swap, assetAmount)
                .then(path => (
                    StellarSdk.Operation.pathPaymentStrictSend({
                        sendAsset: getStellarAssetObj(asset2Swap),
                        sendAmount: parseFloat(assetAmount).toFixed(7),
                        path: path.path.map(a => (a?.asset_type === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(a.asset_code, a.asset_issuer))),
                        destAsset: payAssetObj,
                        destMin: (payAssetAmount * (100 - slippage) / 100).toFixed(7),
                        destination: connectedAccount.account_id,
                    })
                ))
            )
            : () => (
                getRecievePath(asset2Swap, payAssetAmount)
                .then(path => (
                    StellarSdk.Operation.pathPaymentStrictReceive({
                        sendAsset: getStellarAssetObj(asset2Swap),
                        sendMax: (assetAmount * (100 + slippage) / 100).toFixed(7),
                        path: path.path.map(a => (a?.asset_type === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(a.asset_code, a.asset_issuer))),
                        destAsset: payAssetObj,
                        destAmount: parseFloat(payAssetAmount).toFixed(7),
                        destination: connectedAccount.account_id,
                    })
                ))
            )
        );
    }

    return (
        <>
            <Button variant="outlined" onClick={ handleShowDialog }>Swap some wallet asset to &nbsp; <AssetCodeIcon asset={ PAY_ASSET_STRING } /></Button>
            <Dialog
                open={!!showDialog}
                onClose={ handleCloseDialog }
            >
                <DialogTitle>
                    Swap
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" alignItems="flex-end" spacing={1}>
                        <TextField
                            size="small"
                            type="number"
                            label="Amount"
                            variant="standard"
                            error={ assetAmountIsErrored }
                            value={ assetAmount }
                            onChange={ changeAmount } />
                        <AssetSelector asset={ asset2Swap } assets={ assets }  onChose={ choseAsset } />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1} sx={{mt: 1, mb: 2}}>
                            <Typography variant="body2" component="span">Available: </Typography>
                            {/* <Typography variant="body2" component="b">{ asset2SwapMax.toFixed(7) }</Typography> */}
                            <Button variant="outlined" size="small" onClick={ useMaxAssetAmount }>{ asset2SwapMax.toFixed(7) }</Button>
                            <AssetCodeIcon asset={ asset2Swap } showSite={ true } />
                    </Stack>

                    <Stack direction="row" alignItems="flex-end" spacing={1} sx={{mb: 2}}>
                        <TextField
                            size="small"
                            type="number"
                            label={ `${PAY_ASSET.asset_code} amount` }
                            variant="standard"
                            value={ payAssetAmount }
                            onChange={ changePayAssetAmount } />
                        <AssetCodeIcon asset={ PAY_ASSET_STRING } showSite={ true } />
                    </Stack>
                    
                    <Grid item xs="auto" sx={{mb: 1}}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="body2" component="span">Available: </Typography>
                            <Typography variant="body2" component="b">{ payAssetMax.toFixed(7) }</Typography>
                            <AssetCodeIcon asset={ PAY_ASSET_STRING } showSite={ true } />
                        </Stack>
                    </Grid>
                    <Stack direction="row" alignItems="flex-end" spacing={1} sx={{mt: 1, mb: 2}}>
                        { amountVariants.map(amount => (
                            <Button key={ amount } variant="outlined" size="small" onClick={ () => changePayAssetAmount({target: {value: amount}}) }>
                                { amount } { PAY_ASSET.asset_code }
                            </Button>
                        )) }
                    </Stack>

                    { asset2SwapPrice > 0 && payAssetPrice > 0 && (
                        <Typography variant="body2" component="div">
                            1&nbsp;<AssetCode asset={ asset2Swap } />&nbsp;≈&nbsp;{ (asset2SwapPrice/payAssetPrice).toFixed(7) }&nbsp;<AssetCode asset={ PAY_ASSET_STRING } />
                            <br />
                            1&nbsp;<AssetCode asset={ PAY_ASSET_STRING } />&nbsp;≈&nbsp;{ (payAssetPrice/asset2SwapPrice).toFixed(7) }&nbsp;<AssetCode asset={ asset2Swap } />
                        </Typography>
                    )}
                    <Typography variant="body2" component="div">Slippage: { slippage }%</Typography>
                </DialogContent>
                <DialogActions>
                    <Transact
                        buttonText={ <>Swap <AssetCode asset={ asset2Swap } /> to <AssetCode asset={ PAY_ASSET_STRING } /></> }
                        operations={ operations }
                        callback={ onSwaped }
                        confirmMessage={ <>Do you confirm swap { parseFloat(assetAmount).toFixed(7) } <AssetCode asset={ asset2Swap } /> to { parseFloat(payAssetAmount).toFixed(7) } <AssetCode asset={ PAY_ASSET_STRING } />?</> }
                        disabled= {
                            parseFloat(assetAmount) === 0
                            || parseFloat(payAssetAmount) === 0
                            || assetAmountIsErrored
                            || operations.length === 0
                        } />
                    <Button variant="outlined" onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}