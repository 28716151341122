import React, {Fragment} from 'react';
import {
    Stack
} from '@mui/material';
import AssetCodeIcon from './AssetCodeIcon.jsx';

export default function AssetsPairByReserves({reserves, hideCodes}) {
    return (
        <Stack direction="row" alignItems="center" sx={{ display: 'inline-flex', ml: hideCodes ? -1 : 0 }}>
            { reserves.map((r, i) => (<Fragment key={i}>{ i > 0 && (hideCodes ? '|' : <span>&nbsp;/&nbsp;</span>) }<AssetCodeIcon {...r} hideCodes={ hideCodes } /></Fragment>)) }
        </Stack>
    );
}