import React, {Fragment, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Navigate } from 'react-router';
import {
    Box,
    Grid,
    Paper
} from '@mui/material';
import {
    getWalletPoolHistoryState,
    getPoolHistorySlice
} from '../../lib/walletPoolDataSets';
import { fetchPoolHistory } from '../poolsHistory/poolsHistorySlice';
import { fetchPoolById }  from '../pools/poolsSlice';
import { fetchAccountById } from './walletSlice';
import DatesPeriodSelector from '../../components/DatesPeriodSelector.jsx';
import WalletPoolTable from './WalletPoolTable.jsx';
import PollWalletSharesChart from './PollWalletSharesChart.jsx';
import ReservesPriceChart from '../prices/ReservesPriceChart.jsx';
import WalletPoolHeader from './WalletPoolHeader.jsx';

export default function WalletPoolAnalise() {
    const chosenPool = useParams()?.poolId; //useSelector(state => state.root.poolId);
    const accountId = useParams()?.accountId;
    const walletState = useSelector(state => state.wallet);
    const pool = useSelector(state => state.pools.pools[chosenPool]);
    const wallet = useSelector(state => state.root.wallet);
    // const walletBalance = useSelector(state => state.wallet.pools[chosenPool]);
    const fullPoolHistory = useSelector(state => state.poolsHistory.poolsHistory[chosenPool]) || {};
    const priceHistory = useSelector(state => state.prices.priceHistory) || {};
    const dispatch = useDispatch();

    const poolHistory = {
        states: [],
        lastState: fullPoolHistory.lastState
    };

    for (let i = fullPoolHistory.states?.length || 0; i--;) {
        if (poolHistory.states.length > 0 && i !== 0
            && new Date(fullPoolHistory.states[i].effect.created_at).setUTCHours(0,0,0,0)
                === new Date(poolHistory.states[0].effect.created_at).setUTCHours(0,0,0,0)
            && fullPoolHistory.states[i].effect.account !== wallet
        ) {
            continue;
        }
        poolHistory.states.unshift(fullPoolHistory.states[i]);
    }

    const [startDate, setStartDate] = useState(new Date(poolHistory.states[0]?.effect?.created_at || '01/01/2015'));
    const [endDate, setEndDate] = useState(new Date());

    // Change wallet by form
    if (wallet && wallet !== accountId) {
        return (<Navigate  to={`/pool/${chosenPool}/${wallet}`} replace={ false } />);
    }
    
    if (accountId !== walletState.account?.account_id && walletState.fetchStatus !== 'pending') {
        dispatch(fetchAccountById(accountId));
        return <Fragment />;
    }
    if (!pool) {
        dispatch(fetchPoolById(chosenPool));
        return <Fragment />;
    }

    setTimeout(() => dispatch(fetchPoolHistory(chosenPool)), 10);

    if (!poolHistory.states) {
        return <Fragment />;
    }

    const walletPoolHistory = [];

    while (walletPoolHistory.length < poolHistory.states.length) {
        let prevState = poolHistory.states[walletPoolHistory.length - 1];
        let currentState = poolHistory.states[walletPoolHistory.length];
        let prevWalletState = walletPoolHistory[walletPoolHistory.length-1];

        walletPoolHistory.push(
            getWalletPoolHistoryState(
                prevState,
                currentState,
                priceHistory,
                pool,
                prevWalletState,
                wallet
            )
        );
    }

    if (poolHistory.lastState) {
        walletPoolHistory.push(
            getWalletPoolHistoryState(
                poolHistory.states[poolHistory.states.length -1],
                poolHistory.lastState,
                priceHistory,
                pool,
                walletPoolHistory[walletPoolHistory.length-1],
                wallet
            )
        );
    }

    const dateSelectorChanged = (interval) => {
        setStartDate(interval.startDate);
        setEndDate(interval.endDate);
    }

    const [walletPoolHistorySlice, diffHistoryItem] = getPoolHistorySlice(
        walletPoolHistory,
        startDate,
        endDate
    );

    return (
        <Fragment>
            <WalletPoolHeader />

            <DatesPeriodSelector startDateStr={poolHistory.states[0]?.effect?.created_at} onChange={ dateSelectorChanged } />

            { walletPoolHistorySlice.length > 0 && (
                <>
                    <Grid item xs={12}>
                        <Paper sx={{height:550, textAlign: 'center'}}>
                            <Box sx={{ width: '100%', maxWidth:1100, height:550, display: 'inline-block' }}>
                                <PollWalletSharesChart  walletPoolHistory={ walletPoolHistorySlice } diffHistoryItem={ diffHistoryItem } />
                            </Box>
                        </Paper>
                    </Grid>
                    <WalletPoolTable
                        walletPoolHistory={ walletPoolHistorySlice }
                        diffHistoryItem={ diffHistoryItem } />
                </>
            ) }

            <Grid item xs={12}>
                <Paper sx={{height:550, textAlign: 'center'}}>
                    <Box sx={{ width: '100%', maxWidth:1100, height:550, display: 'inline-block' }}>
                        <ReservesPriceChart reserves={ pool.reserves } startDate={ startDate } endDate={ endDate } />
                    </Box>
                </Paper>
            </Grid>
        </Fragment>
    );
}
