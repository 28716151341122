import React, {useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    TextField,
    Typography
} from '@mui/material';
import {
    PAY_ASSET,
    PAY_ASSET_LOCK_AMOUNT,
    PAY_ASSET_LOCK_PERIOD
} from '../../app/constants';
import WCTransact from '../../features/walletconnect/WCTransact.jsx';
import FreighterTransact from '../../features/freighter/FreighterTransact.jsx';
import { fetchAccountById } from '../../features/wallet/walletSlice';
import AssetCode from '../../features/assets/AssetCode.jsx';
import {
    isBalanceCertainAsset
} from '../../lib/assets';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
// const server = new StellarSdk.Server('https://horizon.stellar.org');

export default function CreateClaimableGrants() {
    const wcAccount = useSelector((state) => state.walletconnect.account);
    const freighterAccount = useSelector((state) => state.freighter.account);
    const walletAccount = useSelector(state => state.wallet.account);
    const dispatch = useDispatch();
    const rootWallet = useSelector((state) => state.root.wallet);
    const [claimantId, setClaimantId] = useState((wcAccount ? wcAccount.account_id : freighterAccount?.account_id) || rootWallet || '');

    if (!wcAccount && !freighterAccount) {
        return <></>;
    }

    // const claimantId = wcAccount ? wcAccount.account_id : freighterAccount.account_id;

    const amount = PAY_ASSET_LOCK_AMOUNT;

    const payedAsset = (wcAccount || freighterAccount).balances.filter(b => isBalanceCertainAsset(b, PAY_ASSET))[0];

    if (!payedAsset) {
        return <Button variant="outlined" disabled={ true }>The required asset is absent in your wallet</Button>;
    }

    if (parseFloat(payedAsset.balance) < parseFloat(amount)) {
        return <Button variant="outlined" disabled={ true }>You have only { payedAsset.balance } <AssetCode asset={ PAY_ASSET } /> in wallet. { amount } is needed.</Button>;
    }

    const operation = StellarSdk.Operation.createClaimableBalance({
        asset: new StellarSdk.Asset(PAY_ASSET.asset_code, PAY_ASSET.asset_issuer),
        amount: amount.toString(),
        claimants: [
            new StellarSdk.Claimant(
                claimantId,
                StellarSdk.Claimant.predicateNot(
                    StellarSdk.Claimant.predicateBeforeRelativeTime((PAY_ASSET_LOCK_PERIOD * 3600 * 24).toString())
                )
            )
        ]
    });

    const reload = () => {
        if (walletAccount?.account_id && (wcAccount?.account_id === walletAccount.account_id || freighterAccount?.account_id === walletAccount.account_id)) {
            dispatch(fetchAccountById(walletAccount.account_id));
        }
    };

    const handleChangeWallet = (e) => {
        setClaimantId(e.target.value);
    };

    return (
        <>
            <Typography variant="h6" gutterBottom component="div">
            You can also pay { PAY_ASSET_LOCK_AMOUNT } { PAY_ASSET.asset_code } for access directly from your connected wallet:
            </Typography>
            <Box sx={{ textAlign: 'center', mb: 2, mt: 2 }}>
                <Typography variant='p' component="div" sx={{ display: 'inline-block', pt: 2}}>Enter the wallet address you want to grant access to:</Typography>
                <TextField sx={{ ml: 2, width: 610, mb: 3 }} size="small" label="Wallet" variant="standard" value={ claimantId } onChange={ handleChangeWallet } />
                <Typography sx={{ fontWeight: 'bolder', mb: 4 }} variant='b' component="div">Attention! If this is not your wallet, the funds after unlocking will remain on it. There will be no automatic return.</Typography>
                <Typography variant='p' component="div">
                    { wcAccount && <WCTransact buttonText="Create claimable grants" operations={ [operation] } callback={ reload } /> }
                    { freighterAccount && <FreighterTransact buttonText="Create claimable grants" operations={ [operation] } callback={ reload } /> }
                </Typography>
            </Box>
        </>
    );
}