import { 
    // createEntityAdapter,
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';
import { fetchAssetLastPrice, fetchAssetPriceHistory } from '../prices/pricesSlice';
import LZString from 'lz-string';
import { CACHE_VERSION } from '../../app/constants';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

// const poolsAdapter = createEntityAdapter({
//     // Assume IDs are stored in a field other than `book.id`
//     selectId: (pool) => pool.id,
//     // Keep the "all IDs" array sorted based on book titles
//     sortComparer: (a, b) => a.id.localeCompare(b.id),
// });

const unpacked = LZString.decompress(localStorage.getItem('customPools'));
const parsed = unpacked ? JSON.parse(unpacked) : null;

const initialState = {
    custom: (parsed && parsed._VERSION >= CACHE_VERSION ? parsed.pools : []),
    pools: {},
    poolsByAssets: {},
    common: [],
    lock:{},
    fetchStatuses: {},
};

function storeCache(pools) {
  localStorage.setItem('customPools', LZString.compress(JSON.stringify(
      {
          _VERSION: CACHE_VERSION,
          pools
  })));
}

export const fetchCustomPools = createAsyncThunk(
  'pool/fetchCustomPools',
  async (dummie, thunkAPI) => {
    const state = thunkAPI.getState();
    state.pools.custom.forEach(poolId => thunkAPI.dispatch(fetchPoolById(poolId)));
    return state.pools.custom;
  }
);

export const fetchCommonPools = createAsyncThunk(
  'pool/fetchCommonPools',
  async (dummie, thunkAPI) => {
    const state = thunkAPI.getState();
    if (state.pools.common.length > 0 || (state.pools.lock.common && state.pools.lock.common > ((new Date()).getTime() - 120000))) {
        return;
    }
    thunkAPI.dispatch(lockPoolLoading('common'));
    const commonPools = await fetch('/pools/index.json').then(resp => (resp.json()));
    commonPools.forEach(p => thunkAPI.dispatch(fetchPoolById(p)));
    return commonPools;
  }
);

export const fetchAddCustomPool = createAsyncThunk(
    'pool/fetchAddCustomPool',
    async (poolId, thunkAPI) => {
        const state = thunkAPI.getState();
        if (!state.pools.custom.includes(poolId)) {
            thunkAPI.dispatch(addCustomPool(poolId));
        }
        thunkAPI.dispatch(fetchPoolById(poolId));
    }
);

export const findPoolsByAssets = createAsyncThunk(
    'pool/findPoolsByAssets',
    async (assets, thunkAPI) => {
      const state = thunkAPI.getState();
      const key = assets.join(',');
      if (state.pools.poolsByAssets[key]) {
          return new Promise(resolve => resolve(state.pools.poolsByAssets[key]));
      }
      let pools = await server.liquidityPools().forAssets(assets).limit(200).call();
      if (pools.records.length > 0) {
          thunkAPI.dispatch(addPoolsByAssets({key, pools: pools.records}));
      }
      while (pools.records.length === 200 && pools.next) {
          pools = await pools.next();
          if (pools.records.length > 0) {
              thunkAPI.dispatch(addPoolsByAssets({key, pools: pools.records}));
          }
      }
    }
);

export const fetchPoolById = createAsyncThunk(
    'pool/fetchPoolById',
    async (poolId, thunkAPI) => {
        const pool = await server.liquidityPools().liquidityPoolId(poolId).call();
        const prices = thunkAPI.getState().prices;
        pool.reserves.forEach(({asset}) => {
            if (!prices.lastPrice[asset] && !prices.lastPriceStatuses[asset]) {
                thunkAPI.dispatch(fetchAssetLastPrice(asset));
            }
            thunkAPI.dispatch(fetchAssetPriceHistory(asset));
        });
        return pool;
    }
);

export const poolsSlice = createSlice({
    name: 'pools',
    // initialState: poolsAdapter.getInitialState({
    //     fetchStatuses: {},
    // }),
    initialState,
    reducers: {
      lockPoolLoading: (state, action) => {
          state.lock[action.payload] = (new Date()).getTime();
      },
      addPoolsByAssets: (state, action) => {
          const key = action.payload.key;
          if (!state.poolsByAssets[key]) {
              state.poolsByAssets[key] = [];
          }
          state.poolsByAssets[key] = state.poolsByAssets[key].concat(action.payload.pools).filter(
            (v, i, arr) => (arr.indexOf(v) === i)
          );
      },
      addCustomPool: (state, action) => {
          if (!state.custom.includes(action.payload)) {
              state.custom.push(action.payload);
              storeCache([].concat(state.custom));
          }
      },
      forgetCustomPool: (state, action) => {
          // eslint-disable-next-line eqeqeq
          state.custom = state.custom.filter(cp => cp != action.payload);
          storeCache([].concat(state.custom));
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchPoolById.pending, (state, action) => {
            state.fetchStatuses[action.meta.arg] = 'pending';
      });
      builder.addCase(fetchPoolById.rejected, (state, action) => {
            state.fetchStatuses[action.meta.arg] = 'error';
      });
      builder.addCase(fetchPoolById.fulfilled, (state, action) => {
          if (!action.payload) {
              return;
          }
          state.fetchStatuses[action.meta.arg] = 'ready';
          state.pools[action.payload.id] = action.payload;
        //   poolsAdapter.addOne(state, action);

      });
      builder.addCase(fetchCommonPools.fulfilled,(state, action) => {
          if (!action.payload) {
            return;
          }
          state.common = action.payload;
      });
    }
});

export const { lockPoolLoading, addPoolsByAssets, addCustomPool, forgetCustomPool } = poolsSlice.actions;

export default poolsSlice.reducer;
