import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LZString from 'lz-string';
import { CACHE_VERSION } from '../../app/constants';
import { mergePoolHistory } from '../../lib/poolHistory';
// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

const unpacked = LZString.decompress(localStorage.getItem('pollsHistoryCache'));
const parsed = unpacked ? JSON.parse(unpacked) : null;

const initialState = {
    poolsHistory: (parsed && parsed._VERSION >= CACHE_VERSION
        ? parsed
        : {
            _VERSION: CACHE_VERSION
        }),
    _COUNTER: 0,
    poolsHistoryStatuses: {},
    poolHistoryLock: {},
    forgotten: {}
};

export const fetchCachedPoolHistory = createAsyncThunk(
    'prices/fetchCachedPoolHistory',
    async (poolId, thunkAPI) => {
        const cachedPoolHistory = await fetch(`/pools/${poolId}.json`)
            .then(async(resp) => {
                return (resp.ok
                ? {
                   ...(await resp.json()),
                   _SERVER_CACHED: true
                }
                : {
                    states: [],
                    lastState: null,
                });
            })
            .catch(e => ({
                states: [],
                lastState: null,
            }));
        thunkAPI.dispatch(setCachedPoolHistory({poolId, cachedPoolHistory}));
        thunkAPI.dispatch(unLockPoolLoading(poolId));
        thunkAPI.dispatch(fetchPoolHistory(poolId));
    }
);

export const fetchPoolHistory = createAsyncThunk(
    'prices/fetchPoolHistory',
    async (poolId, thunkAPI) => {
        const state = thunkAPI.getState();
        if (state.poolsHistory.poolHistoryLock[poolId] && state.poolsHistory.poolHistoryLock[poolId] > ((new Date()).getTime() - 120000)) {
            return;
        }
        thunkAPI.dispatch(lockPoolLoading(poolId));

        const poolHistory = state.poolsHistory.poolsHistory[poolId];

        if (!poolHistory) {
            return thunkAPI.dispatch(fetchCachedPoolHistory(poolId));
        }

        const query = server
            .effects()
            .forLiquidityPool(poolId);

        if (poolHistory?.lastState?.effect?.paging_token) {
            query.cursor(poolHistory.lastState.effect.paging_token);
        }

        const poolHistoryPage = await query
            .limit(200)
            .call()
            .catch(err => {
                // eslint-disable-next-line eqeqeq
                if (err?.response?.status == 503) {
                    thunkAPI.dispatch(unLockPoolLoading(poolId));
                    thunkAPI.dispatch(fetchPoolHistory(poolId));
                } else {
                    console.log(err);
                }
              });

        if (thunkAPI.getState().poolsHistory.forgotten[poolId] && thunkAPI.getState().poolsHistory.forgotten[poolId] > ((new Date()).getTime() - 120000)) {
            return;
        }
        thunkAPI.dispatch(fillPoolHistory({poolId, poolHistoryPage}));

        if ((poolHistoryPage.records.length === 200
                || new Date() - new Date(poolHistoryPage.records[poolHistoryPage.records.length - 1]?.created_at) > 86400000
            ) && poolHistoryPage.next
        ) {
            thunkAPI.dispatch(fetchPoolHistoryNext({poolId, next: poolHistoryPage.next}));
        } else {
            // thunkAPI.dispatch(unLockPoolLoading(poolId));
        }

        return poolHistoryPage;
    }
);

const fetchPoolHistoryNext = createAsyncThunk(
    'prices/fetchPoolHistoryNext',
    async ({poolId, next}, thunkAPI) => {
        thunkAPI.dispatch(lockPoolLoading(poolId));
        const poolHistoryPage = await next()
            .catch(err => {
                // eslint-disable-next-line eqeqeq
                if (err?.response?.status == 503) {
                    thunkAPI.dispatch(fetchPoolHistoryNext({poolId, next}));
                } else {
                    console.log(err);
                }
            });
        
        if (thunkAPI.getState().poolsHistory.forgotten[poolId] && thunkAPI.getState().poolsHistory.forgotten[poolId] > ((new Date()).getTime() - 120000)) {
            return;
        }
        
        thunkAPI.dispatch(fillPoolHistory({poolId, poolHistoryPage}));

        if ((poolHistoryPage.records.length === 200
                || new Date() - new Date(poolHistoryPage.records[poolHistoryPage.records.length - 1]?.created_at) > 86400000
            ) && poolHistoryPage.next
        ) {
            thunkAPI.dispatch(fetchPoolHistoryNext({poolId, next: poolHistoryPage.next}));
        } else {
            // thunkAPI.dispatch(unLockPoolLoading(poolId));
            // console.log('else');
        }

        return poolHistoryPage;
    }
);

export const poolsHistorySlice = createSlice({
    name: 'poolsHistory',
    initialState,
    reducers: {
        lockPoolLoading: (state, action) => {
            state.poolHistoryLock[action.payload] = (new Date()).getTime();
        },
        unLockPoolLoading: (state, action) => {
            state.poolHistoryLock[action.payload] = 0;
        },
        setCachedPoolHistory: (state, action) => {
            const {poolId, cachedPoolHistory} = action.payload;
            state.poolsHistory[poolId] = cachedPoolHistory;
        },
        fillPoolHistory: (state, action) => {
            const {poolId, poolHistoryPage} = action.payload;
            if (!state.poolsHistory[poolId]) {
                state.poolsHistory[poolId] = {
                    states: [],
                    lastState: null,
                };
            }
            state.poolsHistory[poolId] = mergePoolHistory(poolId, state.poolsHistory[poolId], poolHistoryPage);
            if (++state._COUNTER >= 100 || poolHistoryPage.records.length < 200) {
                state._COUNTER = 0;
                storeCache(state.poolsHistory);
            }
        },
        forgetPoolHistory: (state, action) => {
            delete state.poolsHistory[action.payload];
            state.forgotten[action.payload] = (new Date()).getTime();
            storeCache(state.poolsHistory);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPoolHistory.pending, (state, action) => {
            state.poolsHistoryStatuses[action.meta.arg] = 'pending';
        });
        builder.addCase(fetchPoolHistory.rejected, (state, action) => {
            state.poolsHistoryStatuses[action.meta.arg] = 'error';
        });
        builder.addCase(fetchPoolHistory.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }
            const poolId = action.meta.arg;
            state.poolsHistoryStatuses[poolId] = 'ready';
        });
        builder.addCase(fetchPoolHistoryNext.pending, (state, action) => {
            state.poolsHistoryStatuses[action.meta.arg.poolId] = 'pending';
        });
        builder.addCase(fetchPoolHistoryNext.rejected, (state, action) => {
                state.poolsHistoryStatuses[action.meta.arg.poolId] = 'error';
        });
        builder.addCase(fetchPoolHistoryNext.fulfilled, (state, action) => {
                if (!action.payload) {
                    return;
                }
                const poolId = action.meta.arg.poolId;
                state.poolsHistoryStatuses[poolId] = 'ready';
        });

        builder.addCase(fetchCachedPoolHistory.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }
            const poolId = action.meta.arg.poolId;
            state.poolsHistoryStatuses[poolId] = 'ready';
        });
    }
});

function storeCache(poolsHistory) {
    let toCache = Object.fromEntries(
        Object.entries(poolsHistory)
            .filter(([poolId, poolsHistory]) => !poolsHistory?._SERVER_CACHED)
    );
    localStorage.setItem('pollsHistoryCache', LZString.compress(JSON.stringify(toCache)));
}
    
export const { setCachedPoolHistory, fillPoolHistory, lockPoolLoading, unLockPoolLoading, forgetPoolHistory } = poolsHistorySlice.actions;

export default poolsHistorySlice.reducer;