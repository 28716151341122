import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Navigate, useParams } from "react-router-dom";
import {
    fetchAccountById,
    // checkPermsByPay,
    // checkPermsByHodl
} from './walletSlice';
import { setWallet } from '../../app/rootSlice';

export default function RequireWalletAuth({children}) {
    const wallet = useSelector(state => state.root.wallet);
    const auth = useSelector((state) => state.wallet.auth);
    const accountId = useParams()?.accountId;
    const dispatch = useDispatch();
    const location = useLocation();

    if (!wallet && accountId) {
        dispatch(setWallet(accountId));
        dispatch(fetchAccountById(accountId));
    }

// Temporary no auth
    if (!auth.granted) {
        return (<Navigate to="/auth" state={{ from: location }} />);
    }

    return children;
}