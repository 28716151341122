// import pack from '../../package.json';
export const APP_NAME = 'AMM Explorer'; //pack.appName;
export const VERSION = '1.6.1'; //pack.version;
export const CACHE_VERSION = 10200;
export const CONTACT_EMAIL = 'info@kurilka.org.ua';
export const BASE_URL = 'https://ammexplorer.kurilka.org.ua';

export const GRANT_ALL = false;

export const TRANSACTION_TIMEOUT = 30;

export const PAY_ASSET = {
    asset_type: 'credit_alphanum4',
    asset_code: 'KRLK',
    asset_issuer: 'GD445ZRFZUG2M3UW2VV4JWMFEJOXVDHDMN5AIZM4NDODIDPEM5XRDACM'
};

export const PAY_ASSET_STRING = `${PAY_ASSET.asset_code}:${PAY_ASSET.asset_issuer}`;

export const PAY_ASSET_HODL_AMOUNT = 200;
export const PAY_ASSET_HODL_PERIOD = 30;

export const PAY_ASSET_LOCK_AMOUNT = 1000;
export const PAY_ASSET_LOCK_PERIOD = 30;
export const PAY_ASSET_RETURN_CLAIM_WALLET = 'GBRWCK7D4B2YSOBBGZHSNCGRMOHU52JZDOTMCYPZVK63AIVPSXSX2BSW';

export const PAY_WALLET = 'GD45MV2BB5N7UH57BMWJ7UUBPI5F5UYQX3UXGXK74UXIBGWJN6W2N2DQ';
export const PAY_VARIANTS = [
    {
        amount: 10,
        duration: 1
    },
    {
        amount: 60,
        duration: 7
    },
    {
        amount: 200,
        duration: 30
    }
];