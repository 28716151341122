import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormGroup,
    FormControlLabel,
    IconButton,
    ListItem,
    ListItemText,
    ListSubheader
}           from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LeftMenuItem from '../../components/LeftMenuItem.jsx';
import AssetsPairByReserves from '../../features/assets/AssetsPairByReserves.jsx';
import {forgetCustomPool} from './poolsSlice.js';
import {forgetPoolHistory} from '../poolsHistory/poolsHistorySlice.js';

export default function LeftCustomPoolsMenu({isOpen}) {
    const customPools = useSelector((state) => state.pools.custom);
    const pools = useSelector((state) => state.pools.pools);
    const [poolToDel, setPoolToDel] = useState(false);
    const [forgetChecked, setForgetChecked] = useState(true);
    const dispatch = useDispatch();

    if (customPools.length === 0 || Object.keys(pools).length === 0) {
        return (<Fragment />);
    }

    const customPoolsWithData = Object.values(pools).filter(p => (customPools.includes(p.id)));//.map(p => pools[p]);

    const handleCloseDialog = () => {
        setPoolToDel(false);
    };

    const forgetPool = () => {
        dispatch(forgetCustomPool(poolToDel));
        if (forgetChecked) {
            dispatch(forgetPoolHistory(poolToDel));
        }
        handleCloseDialog();
    }

    return (
        <Fragment>
            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
                Custom Pools:
            </ListSubheader>
            {
                customPoolsWithData.map(pool => {
                    return (<ListItem
                        key={ pool.id }
                        sx={{p: 0}}
                        secondaryAction={
                            isOpen ? <IconButton sx={{ mr: -1, mt: -1 }} onClick={ () => setPoolToDel(pool.id) }><DeleteIcon /></IconButton> : <></>
                        }
                    >
                        <LeftMenuItem
                            
                            title={ pool.reserves.map(({asset}) => (asset === 'native' ? 'XLM' : asset.split(':').shift())).join('/') }
                            to={ `/pool/${pool.id}` }
                        >
                            <ListItemText primary={ <AssetsPairByReserves reserves={ pool.reserves } hideCodes={ !isOpen } /> } />
                        </LeftMenuItem>
                    </ListItem>);
                })
            }
            <Dialog
                open={!!poolToDel}
                onClose={handleCloseDialog}
            >
                <DialogTitle>
                    Forget the pull
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Remove a pull from the "Custom pools" list?
                    </DialogContentText>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={ forgetChecked } onChange={ () => setForgetChecked(!forgetChecked) } />}
                            label="Clear pool history cache" />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={forgetPool} autoFocus>
                        Forget
                    </Button>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
