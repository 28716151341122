import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
    Grid,
    Paper,
    Typography
} from '@mui/material';
import AssetsPairByReserves from '../../features/assets/AssetsPairByReserves.jsx';
import PoolLiquidityManagement from './PoolLiquidityManagement.jsx';

export default function WalletPoolHeader() {
    const chosenPool = useParams()?.poolId;
    const pool = useSelector(state => state.pools.pools[chosenPool]);

    return (
        <Grid item xs={12} lg={12}>
            <Paper
                sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                }}
            >
                <Grid container>
                    <Grid item xs="auto" sx={{mr:1}}>
                        <Typography component="h2" variant="h4" color="primary">
                            <AssetsPairByReserves reserves={ pool.reserves } />
                        </Typography>
                    </Grid>
                    <PoolLiquidityManagement />
                </Grid>
            </Paper>
        </Grid>
    );
}