import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import QRCode from 'qrcode.react';
import {
    PAY_ASSET
} from '../app/constants';
import {
    Link,
    Typography
} from '@mui/material';
import WCTransact from '../features/walletconnect/WCTransact.jsx';
import FreighterTransact from '../features/freighter/FreighterTransact.jsx';
import { fetchAccountById } from '../features/wallet/walletSlice';
import {
    isBalanceCertainAsset
} from '../lib/assets';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

const operation = StellarSdk.Operation.changeTrust({
    asset: new StellarSdk.Asset(PAY_ASSET.asset_code, PAY_ASSET.asset_issuer),
    limit: undefined,
});

const DEFAULT_LINK = 'web+stellar:tx?xdr=AAAAAgAAAAD51ldBD1v6H78LLJ%2FSgXo6XtMQvulzXV%2FlLoCayW%2BtpgAAAGQCtcnYAAAABwAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAAAAAAAABgAAAAFLUkxLAAAAAPnO5iXNDaZultVrxNmFIl16jONjegRlnGjcNA3kZ28Rf%2F%2F%2F%2F%2F%2F%2F%2F%2F8AAAAAAAAAAA%3D%3D&replace=sourceAccount%3AX%2CseqNum%3AY%3BX%3Aaccount%20to%20which%20add%20the%20trustline%2CY%3Asequence%20number%20of%20your%20account';

export default function AddAsset() {
    const wcAccount = useSelector((state) => state.walletconnect.account);
    const freighterAccount = useSelector((state) => state.freighter.account);
    const walletAccount = useSelector(state => state.wallet.account);
    const dispatch = useDispatch();

    const account = walletAccount || wcAccount || freighterAccount;

    const accountHasPayAsset = account ? account.balances.filter(b => isBalanceCertainAsset(b, PAY_ASSET)).length > 0 : false;

    let link = DEFAULT_LINK;
    
    if (account) {
        const tx = new StellarSdk.TransactionBuilder(account, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
        }).setTimeout(server.transactionTimeout)
        .addOperation(operation)
        .build();
    
        link = `web+stellar:tx?xdr=${encodeURIComponent(tx.toEnvelope().toXDR('base64'))}&replace=sourceAccount%3AX%2CseqNum%3AY%3BX%3Aaccount%20to%20which%20add%20the%20trustline%2CY%3Asequence%20number%20of%20your%20account`;
    }

    const reload = () => {
        if (walletAccount?.account_id && (wcAccount?.account_id === walletAccount.account_id || freighterAccount?.account_id === walletAccount.account_id)) {
            dispatch(fetchAccountById(walletAccount.account_id));
        }
    };

    const Transact = wcAccount ? WCTransact : FreighterTransact;

    return (<>
        <QRCode value={link} size={256} renderAs="svg" />
        { (!wcAccount && !freighterAccount)
            ? ( <Typography variant="p" component="div">
                    <Link href={link} target="_blank" rel="nofollow noopener noreferrer">Add { PAY_ASSET.asset_code } asset</Link>
                </Typography>
            )
            : ( 
                <Typography variant="p" component="div">
                    { !accountHasPayAsset && (<Transact buttonText={ `Add ${PAY_ASSET.asset_code} asset` } operations={ [operation] } callback={ reload } /> )}
                </Typography>
            )
        }
    </>);
}