import React, {Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, NavLink, useLocation }  from 'react-router-dom';
import { useParams, Navigate } from 'react-router';
import {
    Box,
    Grid,
    Paper,
    Tabs,
    Tab
} from '@mui/material';
// import MenuButton from '../../components/MenuButton.jsx';
import { fetchAccountById } from './walletSlice';
import WalletPoolsDashboard from './WalletPoolsDashboard.jsx';
import WalletAssetsDashboard from './WalletAssetsDashboard.jsx';
import WalletClaimableDashboard from './WalletClaimableDashboard.jsx';

export default function WalletDashboard() {
    const walletState = useSelector(state => state.wallet);
    const wallet = useSelector(state => state.root.wallet);
    const accountId = useParams()?.accountId;
    const dispatch = useDispatch();

    const { pathname } = useLocation()
    let tab = 'pools';

    if (/\/assets$/.test(pathname)) {
        tab = 'assets';
    } else if (/\/claimable$/.test(pathname)) {
        tab = 'claimable';
    }

    if (wallet && wallet !== accountId) {
        return (<Navigate  to={ `/wallet/${wallet}` + (tab !== 'pools' ? `/${tab}` : '') } replace={ false } />);
    }

    if (accountId !== walletState.account?.account_id && walletState.fetchStatus !== 'pending') {
        dispatch(fetchAccountById(accountId));
    }

    if (!walletState.account) {
        return (<Fragment />);
    }

    return (
        <Fragment>
                <Grid item xs={12} lg={12}>
                    <Paper>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
                            <Tabs value={ tab } centered>
                                <Tab label="Pools" value={ 'pools' } to={ '' } component={ NavLink } />
                                <Tab label="Assets" value={ 'assets' } to={ 'assets' } component={ NavLink } />
                                <Tab label="Claimable balances" value={ 'claimable' } to={ 'claimable' } component={ NavLink } />
                            </Tabs>
                        </Box>
                    </Paper>
                </Grid>

                <Routes>
                    <Route index element={ <WalletPoolsDashboard /> } />
                    <Route path="assets" element={ <WalletAssetsDashboard /> } />
                    <Route path="claimable" element={ <WalletClaimableDashboard /> } />
                </Routes> 
        </Fragment>
    );
}
