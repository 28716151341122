import React, {Fragment} from 'react';
import { useSelector } from 'react-redux';
import {
    CircularProgress,
    // LinearProgress,
    Stack,
    Typography
} from '@mui/material';
import {
    PAY_ASSET
} from '../../app/constants';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

function WalletHodl({auth}) {
    if (!auth.hasPayAsset || !auth.hodl?.duration || (auth.payed?.validUntil && auth.payed.validUntil > new Date())) {
        return <Fragment />;
    }

    return <Typography variant="span" component="span">(Hodl { auth.hodl.minBalance > 0 && <>{ auth.hodl.minBalance.toFixed(2) } { PAY_ASSET.asset_code } </> }{ auth.hodl.duration } days)&nbsp;</Typography>;
}

function WalletPay({auth}) {
    if (!auth.payed?.amount || !auth.payed?.validUntil || auth.payed.validUntil < new Date()) {
        return <Fragment />;
    }

    return (
        <Typography variant="span" component="span">(Paid until {
            auth.payed.validUntil.toLocaleDateString('en-gb', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }) })
        </Typography>
    );
}

function WalletLock({auth}) {
    if (!auth.lock?.amount) {
        return <Fragment />;
    }

    return <Typography variant="span" component="span">(Locked { auth.lock.amount > 0 && <>{ auth.lock.amount.toFixed(2) } { PAY_ASSET.asset_code } </> })&nbsp;</Typography>;
}

export default function WalletGrants() {
    const wallet = useSelector((state) => state.root.wallet);
    const auth = useSelector((state) => state.wallet.auth);

    if (!wallet || wallet !== auth.accountId) {
        return (<Fragment />);
    }

    return (
        <Fragment>
            <Typography noWrap variant="body2" component="div">
                <Stack direction="row" alignItems="center" sx={{display: 'inline-flex'}}><Typography variant="span" component="span" color="primary">Grants:</Typography>&nbsp;
                {   !auth.granted
                    && (auth.checkStatus.byPay === 'pending' || auth.checkStatus.byHodl === 'pending' || auth.checkStatus.byLock === 'pending')
                    && (<CircularProgress size={ 20 } />)
                }
                { !!auth.granted && <><CheckIcon color="success" /><WalletHodl auth={ auth } /><WalletPay auth={ auth } /><WalletLock auth={ auth } /></> }
                {
                    !auth.granted
                    && auth.checkStatus.byPay !== 'pending' && auth.checkStatus.byHodl !== 'pending' && auth.checkStatus.byLock !== 'pending'
                    && (<><CloseIcon color="error" /><WalletHodl auth={ auth } /> <Link component={ RouterLink } color="primary" to="/auth">get it</Link></>) }
                </Stack>
            </Typography>
        </Fragment>
    );
}
