import React, {
    // useRef,
    // useEffect
} from 'react';
// import qrcodegen from "@ribpay/qr-code-generator";
import QRCode from 'qrcode.react';
// import { toSvgString, drawCanvas } from "@ribpay/qr-code-generator/utils";
import {
    Link,
    Typography
} from '@mui/material';
import PayConnectedButton from '../../components/PayConnectedButton.jsx';

// const QRC = qrcodegen.QrCode;

export default function PayQR({wallet, amount, asset, memo, canPayConnected = true}) {
    const link = `web+stellar:pay?destination=${wallet}&amount=${amount}&asset_code=${asset.asset_code}&asset_issuer=${asset.asset_issuer}&memo=${ memo }&memo_type=MEMO_TEXT`;
    // const qr0 = QRC.encodeText(link,
    //     QRC.Ecc.HIGH);

    // const svg = toSvgString(qr0, 4, "#FFFFFF", "#000000");

    // const canvasRef = useRef(null);

    // useEffect(() => {
    //     const canvas = canvasRef.current;
    //     drawCanvas(qr0, 3, 4, "#FFFFFF", "#000000", canvas);
    // }, [wallet, amount, asset, memo]);

    return <>
        <Typography variant="p" component="div"><Link href={ link } target="_blank" rel="nofollow noopener noreferrer">web+stellar:pay</Link></Typography>
        
        <QRCode value={link} size={256} renderAs="svg" />
        { canPayConnected && (
            <Typography variant="p" component="div">
                <PayConnectedButton wallet={ wallet } amount={ amount } asset={ asset } memo={ memo } />
            </Typography>
        ) }
    </>;
}/* <img width="256" height="256" src={ 'data:image/svg+xml;base64, ' + window.btoa(svg) } />

<canvas ref={canvasRef} />*/
