import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Box,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    FormControlLabel,

    Grid,
    Paper,
    Switch,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    PAY_ASSET,
    PAY_ASSET_STRING
} from '../../app/constants';
import {
    isBalanceCertainAsset
} from '../../lib/assets';
import AssetCode from '../assets/AssetCode.jsx';
import AssetCodeIcon from '../assets/AssetCodeIcon.jsx';
import WCTransact from '../walletconnect/WCTransact.jsx';
import FreighterTransact from '../freighter/FreighterTransact.jsx';
import { fetchAccountById } from './walletSlice';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

const slippage = 2; // 2%

const portion = 20;

function notBeforeDateFromPredicate(predicate) {
    if (predicate?.not?.abs_before) {
        const date = new Date(predicate.not.abs_before);
        return date > new Date() ? date : null;
    }

    if (predicate?.and) {
        return predicate.and.map(notBeforeDateFromPredicate).reduce((maxDate, date) => ((date && (!maxDate || maxDate < date)) ? date : maxDate), null);
    }

    if (predicate?.or) {
        return predicate.or.map(notBeforeDateFromPredicate).reduce((minDate, date) => ((date && (!minDate || minDate > date)) ? date : minDate), null);
    }
    return null;
}

function getClaimDate(cb, accountId) {
    const asClaimant = cb.claimants.find(({destination}) => (destination === accountId));
    if (!asClaimant?.predicate) {
        return null;
    }
    return notBeforeDateFromPredicate(asClaimant.predicate);
}

const payAssetObj = new StellarSdk.Asset(PAY_ASSET.asset_code, PAY_ASSET.asset_issuer);

function getStellarAssetObj(asset) {
    return asset === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(...(asset.split(':')));
}

let pathsLoading = {};

function getSendPath(asset, amount, forse = false) {
    if (pathsLoading[asset] && !forse) {
        return Promise.reject('alredy loading')
    }
    pathsLoading[asset] = true;
    return server.strictSendPaths(
        getStellarAssetObj(asset),
        amount,
        [payAssetObj]
    )
    .call()
    .then(({records}) => {
        pathsLoading[asset] = false;
        if (!records.length) {
            return null;
        }
        return records.reduce((acc, item) =>
            (Number(acc.destination_amount) > Number(item.destination_amount) ? acc : item));
    });
}

const addPayAssetOperation = StellarSdk.Operation.changeTrust({
    asset: payAssetObj,
    limit: undefined,
});

export default function Claimable2PayAsset() {
    const accountId = useSelector((state) => state.wallet.accountId);
    const [isSwitchCleanUnswapableChecked, setSwitchCleanUnswapable] = useState(true);
    const [paths, setPaths] = useState({});
    const balances = useSelector((state) => state.wallet.balances)
        .filter(b => b.asset_type !== 'liquidity_pool_shares')
        .map(b => ({
            ...b,
            asset: b.asset_type === 'native' ? 'native' : `${b.asset_code}:${b.asset_issuer}`
        }));
    const toConvertBalances = useSelector((state) => state.wallet.claimableBalances)
        .filter(cb => (!balances.find(b => b.asset === cb.asset)
            && (isSwitchCleanUnswapableChecked || paths[cb.asset] !== null)
            && cb.assset !== PAY_ASSET_STRING
            && (getClaimDate(cb, accountId) < new Date()))
        )
        .sort((a,b) => (new Date(b.last_modified_time) - new Date(a.last_modified_time)))
        .slice(0, portion);

    const wcAccount = useSelector((state) => state.walletconnect.account);
    const freighterAccount = useSelector((state) => state.freighter.account);
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);

    const setPath = (asset, path) => setPaths(prevState => ({
        ...prevState,
        [asset]: path
    }));
    
    toConvertBalances.forEach(({asset, amount}) => {
        if (paths[asset] || paths[asset] === null) {
            return;
        }
        getSendPath(asset, amount)
            .then(path => setPath(asset, path))
            .catch(err => {if (err !== 'alredy loading') {console.error(err)}});
    });

    const connectedAccount = wcAccount || freighterAccount;
    if (!connectedAccount || connectedAccount.account_id !== accountId || (toConvertBalances.length === 0 && isSwitchCleanUnswapableChecked)) {
        return <></>;
    }

    const walletHasPayAsset = connectedAccount.balances.filter(b => isBalanceCertainAsset(b, PAY_ASSET)).length > 0;

    const Transact = wcAccount ? WCTransact : FreighterTransact;

    const handleShowDialog = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const onSwaped = () => {
        dispatch(fetchAccountById(accountId));
    };

    let operations = walletHasPayAsset ? [] : [addPayAssetOperation];
    if (showDialog) {
        toConvertBalances.forEach(b => {
            operations.push(StellarSdk.Operation.changeTrust({
                asset: getStellarAssetObj(b.asset),
                limit: undefined,
            }));
            operations.push(StellarSdk.Operation.claimClaimableBalance({balanceId: b.id}));
            operations.push(() => (
                getSendPath(b.asset, b.amount, true)
                    .then(path => (path !== null
                        ? StellarSdk.Operation.pathPaymentStrictSend({
                            sendAsset: getStellarAssetObj(b.asset),
                            sendAmount: b.amount,
                            path: path.path.map(a => (a?.asset_type === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(a.asset_code, a.asset_issuer))),
                            destAsset: payAssetObj,
                            destMin: (parseFloat(path.destination_amount) * (100 - slippage) / 100).toFixed(7),
                            destination: connectedAccount.account_id,
                        })
                        : StellarSdk.Operation.payment({
                            destination: b.asset.split(':').pop(),
                            asset: getStellarAssetObj(b.asset),
                            amount:b.amount,
                        })
                    ))
            ));
            operations.push(StellarSdk.Operation.changeTrust({
                asset: getStellarAssetObj(b.asset),
                limit: '0',
            }));
        });
    }

    const handleChangeSwitchCleanUnswapable = (event) => {
        setSwitchCleanUnswapable(event.target.checked);
    };

    return (<>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <Paper sx={{p:2, textAlign: 'center'}}>
                    <Button variant="contained" onClick={ handleShowDialog }>
                        Swap balances of unadded assets to &nbsp;<AssetCodeIcon asset={ PAY_ASSET_STRING } />
                    </Button>
                </Paper>
            </Grid>
        </Grid>
        <Dialog
                open={!!showDialog}
                onClose={ handleCloseDialog }
            >
                <DialogTitle>
                    Swap first { toConvertBalances.length } claimable balances
                </DialogTitle>

                <DialogContent>
                    <Box sx={{ p: 1 }}><FormControlLabel
                        control={<Android12Switch onChange={ handleChangeSwitchCleanUnswapable } checked={ isSwitchCleanUnswapableChecked } />}
                        label="Send unswapable claimable balances to issuer"
                    /></Box>
                    <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th">Asset</TableCell>
                                    <TableCell component="th" align="right">Amount</TableCell>
                                    <TableCell component="th" align="right">Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { toConvertBalances.map(
                                    b => (
                                        <TableRow key={b.id}>
                                            <TableCell><AssetCodeIcon asset={ b.asset } showSite={ true } /></TableCell>
                                            <TableCell align="right">{ b.amount }</TableCell>
                                            <TableCell align="right">{ paths[b.asset] !== null
                                                ? <>{ paths[b.asset]?.destination_amount } <AssetCode asset={PAY_ASSET_STRING} /></>
                                                : 'unavailable'
                                            }</TableCell>
                                        </TableRow>
                                    )
                                ) }
                                { toConvertBalances.length > 0
                                    ? (<TableRow>
                                        <TableCell>
                                            <Typography variant="body2" component="div">Slippage: { slippage }%</Typography>
                                        </TableCell>
                                        <TableCell />
                                        <TableCell>
                                            ≈&nbsp;{ toConvertBalances
                                                .reduce((total, {asset}) => (total + parseFloat(paths[asset]?.destination_amount || 0)), 0) }
                                            <AssetCode asset={PAY_ASSET_STRING} />
                                        </TableCell>
                                    </TableRow>)
                                    : (
                                        <TableRow>
                                            <TableCell colSpan={ 3 } align="center">You don't have balances to swap</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                </DialogContent>
                <DialogActions>
                    <Transact
                        buttonText={ <>Swap all to <AssetCode asset={ PAY_ASSET_STRING } /></> }
                        operations={ operations }
                        callback={ onSwaped }
                        disabled= {
                            // parseFloat(assetAmount) === 0
                            // || parseFloat(PAY_ASSET_STRINGAmount) === 0
                            // || assetAmountIsErrored
                            // || 
                            operations.length === 0
                        } />
                    <Button variant="outlined" onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
    </>);
}