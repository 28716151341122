import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import AssetCode from '../assets/AssetCode.jsx';

const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Pool and wallet shares and reserves cost in USDC chart',
      },
      tooltip: {
        callbacks: {
        }
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
                return value + 'USDC';
            }
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
};

  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function PollWalletSharesChart({walletPoolHistory, diffHistoryItem}) {
    const startPoolEarned = diffHistoryItem?.poolData?.earnedAmount || 0;
    const startWalletEarned = diffHistoryItem?.walletData?.earnedAmount || 0;
    const labels = walletPoolHistory.map(({date}) => date.toLocaleDateString('en-gb', {year: 'numeric', month: 'numeric', day: 'numeric'}));
    const data = {
        labels,
        datasets: [
          {
            label: 'Pool shares',
            data: walletPoolHistory.map(({poolData}) => (poolData.currentShares)),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y',
          },
          {
            label: 'Wallet shares',
            data: walletPoolHistory.map(({walletData}) => (walletData.currentShares)),
            borderColor: 'rgb(255, 159, 64)',
            backgroundColor: 'rgba(255, 159, 64, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y',
          },
          {
            label: 'Pool reserves in USDC',
            data: walletPoolHistory.map(({poolData}) => (poolData.reservesPrice)),
            borderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y1',
          },
          {
            label: 'Wallet reserves in USDC',
            data: walletPoolHistory.map(({walletData}) => (walletData.reservesPrice)),
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y1',
          },
          {
            label: 'Pool comission earned in USDC',
            data: walletPoolHistory.map(({poolData}) => (poolData.earnedAmount - startPoolEarned)),
            borderColor: 'rgb(153, 102, 255)',
            backgroundColor: 'rgba(153, 102, 255, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y1',
          },
          {
            label: 'Wallet comission earned in USDC',
            data: walletPoolHistory.map(({walletData}) => (walletData.earnedAmount - startWalletEarned)),
            borderColor: 'rgb(175, 132, 212)',
            backgroundColor: 'rgba(175, 132, 212, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y1',
          },
        ],
    };
    options.plugins.tooltip.callbacks.footer = ([{dataIndex}]) => {
      const { exchangeRates } = walletPoolHistory[dataIndex];
      return "Rates\n"
        + AssetCode({asset: Object.keys(exchangeRates)[0]}) + ' = ' + Object.values(exchangeRates)[0].toFixed(7) + " USDC\n"
        + AssetCode({asset: Object.keys(exchangeRates)[1]}) + ' = ' + Object.values(exchangeRates)[1].toFixed(7) + ' USDC';
    };
    return (<Line options={options} data={data} />);
}