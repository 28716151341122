import React from 'react';
import { BrowserRouter, Routes, Route }  from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import WalletDashboard from './features/wallet/WalletDashboard.jsx';
import WalletPoolAnalise from './features/wallet/WalletPoolAnalise.jsx';
import WalletAuth from './features/wallet/WalletAuth.jsx';
import RequireWalletAuth from './features/wallet/RequireWalletAuth.jsx';
import PoolAnalise from './features/pools/PoolAnalise.jsx';
import CustomPool from './features/pools/CustomPool.jsx';
import Layout from './components/Layout';
import Index from './components/Index.jsx';

import WalletConnect from './features/walletconnect/WalletConnect.jsx';
import FreighterConnect from './features/freighter/FreighterConnect.jsx';

const mdTheme = createTheme({
  typography: {
    bodySmall: {
      fontSize: 12
    }
  }
});

export default function App({wallet, chosenPool}) {

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider theme={mdTheme}>
          <Routes>
            <Route path="/" element={ <Layout /> }>
              <Route index element={ <Index /> } />
              <Route path="wallet/:accountId/*" element={ <WalletDashboard /> } />
              <Route path="pool/custom" element={ <CustomPool /> } />
              <Route path="pool/:poolId" element={ <PoolAnalise /> } />
              <Route path="pool/:poolId/:accountId" element={ <RequireWalletAuth><WalletPoolAnalise /></RequireWalletAuth> } />
              <Route path="auth" element={ <WalletAuth /> } />
              <Route path="wc" element={ <WalletConnect /> } />
              <Route path="freighter" element={ <FreighterConnect /> } />
            </Route>
          </Routes>
        
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};
