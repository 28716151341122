import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Grid } from '@mui/material';
import {
    isBalanceCertainAsset,
    assetObjFromStr
} from '../../lib/assets';
import WCTransact from '../walletconnect/WCTransact.jsx';
import FreighterTransact from '../freighter/FreighterTransact.jsx';
import LiquidityIncrease from './LiquidityIncrease.jsx';
import LiquidityRemove from './LiquidityRemove.jsx';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
// const server = new StellarSdk.Server('https://horizon.stellar.org');

function getAddAssetOperation(asset) {
    return StellarSdk.Operation.changeTrust({
        asset: asset.asset_type === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
        limit: undefined,
    });
}

export default function PoolLiquidityManagement() {
    const chosenPool = useParams()?.poolId;
    const pool = useSelector(state => state.pools.pools[chosenPool]);
    const wcAccount = useSelector((state) => state.walletconnect.account);
    const freighterAccount = useSelector((state) => state.freighter.account);
    const connectedAccount = wcAccount || freighterAccount;

    if (!connectedAccount) {
        return <></>;
    }

    const Transact = wcAccount ? WCTransact : FreighterTransact;

    // console.log(pool.reserves);
    // console.log(Object.entries(pool.reserves[0]));

    const assetA = assetObjFromStr(pool.reserves[0].asset);
    const assetB = assetObjFromStr(pool.reserves[1].asset);
    const accountHasAssetA = connectedAccount.balances.filter(b => isBalanceCertainAsset(b, assetA)).length > 0;
    const accountHasAssetB = connectedAccount.balances.filter(b => isBalanceCertainAsset(b, assetB)).length > 0;

    // console.log(assetA, assetB, accountHasAssetA, accountHasAssetB);
    // console.log(connectedAccount);

    if (!accountHasAssetA || !accountHasAssetB) {
        return (
            <Grid item xs={ true } sx={{textAlign: 'right', pt:1}}>
                { !accountHasAssetA && (
                    <Transact buttonText={ `Add ${assetA.asset_code} asset` } operations={ [getAddAssetOperation(assetA)] } sx={{mr:1}} />
                ) }
                { !accountHasAssetB && (
                    <Transact buttonText={ `Add ${assetB.asset_code} asset` } operations={ [getAddAssetOperation(assetB)] } />
                ) }
            </Grid>
        );
    }

    return (
        <Grid item xs={ true } sx={{textAlign: 'right', pt:1}}>
            <LiquidityIncrease pool={ pool } />
            <LiquidityRemove pool={ pool } />
        </Grid>
    );
}