import { NavLink, useMatch } from 'react-router-dom';
import {
  ListItemButton,
}           from '@mui/material';

export default function LeftMenuItem({children, to, ...props}) {
    const match = useMatch(to);

    return (
        <ListItemButton component={ NavLink } selected={ !!match } to={ to } {...props}>
            {children}
        </ListItemButton>
    );
}