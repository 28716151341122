import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
    Divider,
    ListItemText,
    ListSubheader
}           from '@mui/material';

import LeftMenuItem from '../../components/LeftMenuItem.jsx';
import AssetsPairByReserves from '../../features/assets/AssetsPairByReserves.jsx';

export default function LeftPoolsMenu({isOpen}) {
    const wallet = useSelector((state) => state.wallet?.account?.account_id);
    const walletPools = useSelector((state) => state.wallet.pools);
    const pools = useSelector((state) => state.pools.pools);

    if (Object.keys(pools).length === 0 || !wallet) {
        return (<Fragment />);
    }

    return (
        <Fragment>
            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
                Wallet Pools:
            </ListSubheader>
            { 
                Object.values(walletPools).filter(wp => (!!pools[wp.liquidity_pool_id])).map(wp => {
                    const pool = pools[wp.liquidity_pool_id];
                    return (
                        <LeftMenuItem
                            key={ pool.id }
                            title={ pool.reserves.map(({asset}) => (asset === 'native' ? 'XLM' : asset.split(':').shift())).join('/') }
                            to={ `/pool/${pool.id}/${wallet}` }
                        >
                            <ListItemText primary={ <AssetsPairByReserves reserves={ pool.reserves } hideCodes={ !isOpen } /> } />
                        </LeftMenuItem>
                    );
                })
            }
        </Fragment>
    );
}
