export function isBalanceCertainAsset(balance, asset) {
    return Object.entries(asset).filter(([key, value]) => balance[key] === value).length === Object.keys(asset).length;
}

export function assetObjFromStr(assetString) {
    if (assetString === 'native') {
        return {
            asset_type: 'native'
        };
    } 
    const parts = assetString.split(':');
    return {
        asset_code: parts[0],
        asset_issuer: parts[1],
    }
}