import React from 'react';
import {
    Paper,
    Grid,
    Divider,
    Typography
} from '@mui/material';

const badgeStyle = {whiteSpace: 'nowrap', fontSize: 12};

export default function WalletPooolTableBadge({header, children, total}) {
    return (
        <Grid item sx={badgeStyle} xs={12} lg={2}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography color="primary" component="b">
                    { header }
                </Typography>
                <Typography variant="p" gutterBottom component="div">
                    { children }
                </Typography>
                <Divider />
                <Typography variant="p" component="div">
                    { total }
                </Typography>
            </Paper>
        </Grid>
    );
}