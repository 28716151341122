import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Button
} from '@mui/material';
import WCTransact from '../features/walletconnect/WCTransact.jsx';
import FreighterTransact from '../features/freighter/FreighterTransact.jsx';
import { fetchAccountById } from '../features/wallet/walletSlice';
import AssetCode from '../features/assets/AssetCode.jsx';
import {
    isBalanceCertainAsset
} from '../lib/assets';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
// const server = new StellarSdk.Server('https://horizon.stellar.org');

export default function PayConnectedButton({wallet, amount, asset, memo}) {
    const wcAccount = useSelector((state) => state.walletconnect.account);
    const freighterAccount = useSelector((state) => state.freighter.account);
    const walletAccount = useSelector(state => state.wallet.account);
    const dispatch = useDispatch();

    if (!wcAccount && !freighterAccount) {
        return <></>;
    }

    const payedAsset = (wcAccount || freighterAccount).balances.filter(b => isBalanceCertainAsset(b, asset))[0];

    if (!payedAsset) {
        return <Button variant="outlined" disabled={ true }>The required asset is absent in your wallet</Button>;
    }

    if (parseFloat(payedAsset.balance) < parseFloat(amount)) {
        return <Button variant="outlined" disabled={ true }>You have only { payedAsset.balance } <AssetCode asset={ asset } /> in wallet. { amount } is needed.</Button>;
    }

    // TODO orders && XLM commision


    const operation = StellarSdk.Operation.payment({
        destination: wallet,
        asset: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
        amount: amount.toString(),
        withMuxing: false //Boolean(opts.withMuxing),
    });

    const reload = () => {
        if (walletAccount?.account_id && (wcAccount?.account_id === walletAccount.account_id || freighterAccount?.account_id === walletAccount.account_id)) {
            dispatch(fetchAccountById(walletAccount.account_id));
        }
    };

    if (wcAccount) {
        return <WCTransact buttonText="Pay" operations={ [operation] } memo={ memo } callback={ reload } />;
    }

    if (freighterAccount) {
        return <FreighterTransact buttonText="Pay" operations={ [operation] } memo={ memo } callback={ reload } />;
    }

    return <></>;
}