import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,

    Grid,
    Slider,
    Stack,
    Typography
} from '@mui/material';
import AssetCodeIcon from '../assets/AssetCodeIcon.jsx';
import AssetsPairByReserves from '../assets/AssetsPairByReserves.jsx';
import WCTransact from '../walletconnect/WCTransact.jsx';
import FreighterTransact from '../freighter/FreighterTransact.jsx';
import {
    assetObjFromStr
} from '../../lib/assets';
import { fetchPoolById }  from '../pools/poolsSlice';
import { fetchPoolHistory } from '../poolsHistory/poolsHistorySlice';


// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
// const server = new StellarSdk.Server('https://horizon.stellar.org');

const marks = [...Array(5).keys()].map(k => ({
    value: k*25,
    label: `${k*25}%`,
}));

export default function LiquidityRemove({pool}) {
    const wcAccount = useSelector((state) => state.walletconnect.account);
    const freighterAccount = useSelector((state) => state.freighter.account);
    const [showDialog, setShowDialog] = useState(false);
    const [withdrawPercent, setWithdrawPercent] = useState(0);
    const dispatch = useDispatch();

    const connectedAccount = wcAccount || freighterAccount;

    const Transact = wcAccount ? WCTransact : FreighterTransact;

    const handleShowDialog = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const changePercent = (e, newValue) => {
        setWithdrawPercent(newValue);
    };
    
    const poolBalance = connectedAccount.balances.filter(b => (b?.liquidity_pool_id === pool.id)).shift();

    if (!poolBalance) {
        return <></>;
    }

    const withdrawWalletShares = (parseFloat(poolBalance.balance) * withdrawPercent /100).toFixed(7);
    const withdrawAssetAAmount = parseFloat(poolBalance.balance)/parseFloat(pool.total_shares)*withdrawPercent/100*parseFloat(pool.reserves[0].amount);
    const withdrawAssetBAmount = parseFloat(poolBalance.balance)/parseFloat(pool.total_shares)*withdrawPercent/100*parseFloat(pool.reserves[1].amount);

    let operations = [];
    
    if (withdrawPercent > 0) {
        operations.push(StellarSdk.Operation.liquidityPoolWithdraw({
            liquidityPoolId: pool.id,
            amount: withdrawWalletShares,
            minAmountA: (withdrawAssetAAmount * 0.98).toFixed(7),
            minAmountB: (withdrawAssetBAmount * 0.98).toFixed(7),
        }));
    }

    if (parseFloat(withdrawPercent) === parseFloat(100)) {
        const assetA = assetObjFromStr(pool.reserves[0].asset);
        const assetB = assetObjFromStr(pool.reserves[1].asset);
        const lpAsset = new StellarSdk.LiquidityPoolAsset(
            (assetA?.asset_type === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(assetA.asset_code, assetA.asset_issuer)),
            (assetB?.asset_type === 'native' ? StellarSdk.Asset.native() : new StellarSdk.Asset(assetB.asset_code, assetB.asset_issuer)),
            30
        );
        operations.push(StellarSdk.Operation.changeTrust({asset: lpAsset, limit: '0'}));
    }

    const onRemoved = () => {
        dispatch(fetchPoolById(pool.id));
        dispatch(fetchPoolHistory(pool.id))
        setShowDialog(false);
    };

    return (
        <>
            <Button
                sx={{mr: 1, mb:1}}
                variant="contained"
                onClick={ handleShowDialog }
            >
                Remove Liquidity
            </Button>
            <Dialog
                open={!!showDialog}
                onClose={ handleCloseDialog }
            >
                <DialogTitle>
                    Remove Liquidity
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" alignItems="flex-end" spacing={1} sx={{mt: 1}}>
                        <Grid item xs="auto">
                            <Typography variant="body2" component="span">Pool: </Typography>
                        </Grid>
                        <Grid item xs={true} sx={{textAlign:'right'}}>
                            <Typography component="div" variant="p" color="primary">
                                <AssetsPairByReserves reserves={ pool.reserves } />
                            </Typography>
                        </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="flex-end" spacing={1} sx={{mt: 1}}>
                        <Grid item xs="auto" sx={{ pt: 3}}>
                            <Typography variant="body2" component="span">Pool shares: </Typography>
                        </Grid>
                        <Grid item xs={true} sx={{textAlign:'right'}}>
                            <Typography component="div" variant="p" color="primary">
                                { pool.total_shares }
                            </Typography>
                        </Grid>
                    </Stack>
                    <Box sx={{ pt: 2, pb: 2 }}>
                        <Slider
                            valueLabelDisplay="auto"
                            marks={ marks }
                            step={ 1 }
                            min={ 0 }
                            max={ 100 }
                            value={ withdrawPercent }
                            onChange={ changePercent } />
                    </Box>

                    <Box sx={{ p: 1, border:1, borderColor: 'info.light', borderRadius: 1 }}>
                        <Typography variant="body1" component="div">You will recive: { withdrawPercent }%</Typography>
                        <Typography variant="body1" component="div">Pool shares: { withdrawWalletShares }</Typography>
                        <Stack direction="row" alignItems="center" spacing={0} sx={{mt: 1}}><AssetCodeIcon asset={ pool.reserves[0].asset } /><Typography variant="body2" component="div">≈ {
                            withdrawAssetAAmount.toFixed(7)
                        }</Typography></Stack>
                        <Stack direction="row" alignItems="center" spacing={0} sx={{mt: 1}}><AssetCodeIcon asset={ pool.reserves[1].asset } /><Typography variant="body2" component="div">≈ {
                            withdrawAssetBAmount.toFixed(7)
                        }</Typography></Stack>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Transact
                        buttonText="Remove Liquidity"
                        operations={ operations }
                        feeFactor={ 100 }
                        callback={ onRemoved }
                        disabled= { parseFloat(withdrawPercent) === 0 || operations.length === 0 } />
                    <Button variant="outlined" onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}