import React, {Fragment, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
    Box,
    Grid,
    Paper,
    Typography
} from '@mui/material';
import { Navigate } from 'react-router';
import { fetchPoolHistory } from '../poolsHistory/poolsHistorySlice';
import { fetchPoolById }  from '../pools/poolsSlice';
import {
    getWalletPoolHistoryState,
    getPoolHistorySlice
} from '../../lib/walletPoolDataSets';
import DatesPeriodSelector from '../../components/DatesPeriodSelector.jsx';
import AssetsPairByReserves from '../../features/assets/AssetsPairByReserves.jsx';
import PoolTable from './PoolTable.jsx';
import PollSharesChart from './PollSharesChart.jsx';

export default function PoolAnalise() {
    const wallet = useSelector(state => state.root.wallet);
    const auth = useSelector((state) => state.wallet.auth);
    const chosenPool = useParams()?.poolId; //useSelector(state => state.root.poolId);
    const pool = useSelector(state => state.pools.pools[chosenPool]);
    const fullPoolHistory = useSelector(state => state.poolsHistory.poolsHistory[chosenPool]) || {};
    const priceHistory = useSelector(state => state.prices.priceHistory) || {};


    const poolHistory = {
        states: [],
        lastState: fullPoolHistory.lastState
    };

    for (let i = fullPoolHistory.states?.length || 0; i--;) {
        if (poolHistory.states.length > 0 && i !== 0
            && new Date(fullPoolHistory.states[i].effect.created_at).setUTCHours(0,0,0,0)
                === new Date(poolHistory.states[0].effect.created_at).setUTCHours(0,0,0,0)
        ) {
            continue;
        }
        poolHistory.states.unshift(fullPoolHistory.states[i]);
    }

    const [startDate, setStartDate] = useState(new Date(poolHistory.states[0]?.effect?.created_at || '01/01/2015'));
    const [endDate, setEndDate] = useState(new Date());

    const dispatch = useDispatch();

    if (!pool) {
        dispatch(fetchPoolById(chosenPool));
        return <Fragment />;
    }

    setTimeout(() => dispatch(fetchPoolHistory(chosenPool)), 10);

    if (wallet && auth.accountId === wallet && auth.granted) {
        return (<Navigate  to={`/pool/${chosenPool}/${wallet}`} replace={ false } />);
    }

    if (!poolHistory.states) {
        return <Fragment />;
    }

    const poolHistoryStates = [];

    while (poolHistoryStates.length < poolHistory.states.length) {
        let prevState = poolHistory.states[poolHistoryStates.length - 1];
        let currentState = poolHistory.states[poolHistoryStates.length];

        poolHistoryStates.push(getWalletPoolHistoryState(
            prevState,
            currentState,
            priceHistory,
            pool,
            poolHistoryStates[poolHistoryStates.length-1]
        ));
    }

    if (poolHistory.lastState) {
        poolHistoryStates.push(
            getWalletPoolHistoryState(
                poolHistory.states[poolHistory.states.length -1],
                poolHistory.lastState,
                priceHistory,
                pool,
                poolHistoryStates[poolHistoryStates.length-1]
            )
        );
    }

    const dateSelectorChanged = (interval) => {
        setStartDate(interval.startDate);
        setEndDate(interval.endDate);
    }

    const [poolHistorySlice, diffHistoryItem] = getPoolHistorySlice(
        poolHistoryStates,
        startDate,
        endDate
    );    

    return (
        <Fragment>
            <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                    <Typography component="h2" variant="h4" color="primary">
                        <AssetsPairByReserves reserves={ pool.reserves } />
                    </Typography>
                </Paper>
            </Grid>

            <DatesPeriodSelector startDateStr={poolHistory.states[0]?.effect?.created_at} onChange={ dateSelectorChanged } />

            { poolHistoryStates.length > 0 && (
                <>
                    <Grid item xs={12}>
                    <Paper sx={{height:550, textAlign: 'center'}}>
                            <Box sx={{ width: '100%', maxWidth:1100, height:550, display: 'inline-block' }}>
                                <PollSharesChart  poolHistory={ poolHistorySlice } diffHistoryItem={ diffHistoryItem } />
                            </Box>
                        </Paper>
                    </Grid>
                    <PoolTable poolHistory={ poolHistorySlice } diffHistoryItem={ diffHistoryItem } />
                </>
            ) }
        </Fragment>
    );
}
