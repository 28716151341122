import React, {useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box,
    Button,
    Icon,
    // IconButton,
    Menu,
    MenuItem,
    Tooltip
} from '@mui/material';
import NotConnectedButton from './NotConnectedButton.jsx';
import { setWallet } from '../app/rootSlice';
import { fetchAccountById } from '../features/wallet/walletSlice';
import { logout } from '../features/walletconnect/walletconnectSlice';
import { logout as freighterLogout } from '../features/freighter/freighterSlice';

export default function ConnectWalletButton() {
    const [anchorEl, setAnchorEl] = useState(null);
    const {appMeta} = useSelector((state) => state.walletconnect);
    const wcPublicKey = useSelector((state) => state.walletconnect.publicKey);
    const freighterPublicKey = useSelector((state) => state.freighter.publicKey);
    const publicKey = wcPublicKey || freighterPublicKey;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    if (!publicKey) {
        return (
            <NotConnectedButton />
        );
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const drawWalet = () => {
        dispatch(setWallet(publicKey));
        dispatch(fetchAccountById(publicKey));
        // cases ???
        if (location) {}
        navigate(`/wallet/${publicKey}`);
        handleCloseMenu();
    };
    const doLogout = () => {
        dispatch(wcPublicKey ? logout() : freighterLogout());
        handleCloseMenu();
    }

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={ wcPublicKey }>
                <Button
                    onClick={ handleOpenMenu }
                    sx={{ color: 'white', borderColor: 'white' }}
                    variant="outlined"
                >
                    <Icon>
                        <img
                            src={ wcPublicKey ? appMeta.icons[0] : '/freighter.svg' }
                            style={{width: '100%', marginBottom: 8}}
                            alt={ wcPublicKey ? appMeta?.name : 'Freighter' }
                            title={ wcPublicKey ? appMeta?.name : 'Freighter' } />
                    </Icon>&nbsp;{ `${publicKey.substr(0,5)}...${publicKey.substr(-5)}` }
                </Button>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-wcbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
                <MenuItem onClick={ drawWalet }>
                    Draw wallet
                </MenuItem>
                <MenuItem onClick={ doLogout }>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    );
}
