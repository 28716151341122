import { createSlice } from '@reduxjs/toolkit';

export const rootSlice = createSlice({
    name: 'root',
    initialState: {
        wallet: '',
        poolId: null
    },
    reducers: {
        setWallet: (state, action) => {
            state.wallet = action.payload;
        },
        choosePool: (state, action) => {
            state.poolId = action.payload;
        },
    }
});

export const { setWallet, choosePool } = rootSlice.actions;

export default rootSlice.reducer;