import React, {Fragment} from 'react';
import {
    Paper,
    Grid,
    Collapse,
    IconButton,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import {
    ArrowCircleUp,
    ArrowCircleDown,
    CurrencyExchange
} from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Reserve from '../../features/assets/Reserve.jsx';
import AssetCode from '../../features/assets/AssetCode.jsx';
import PooolTableBadge from './PooolTableBadge.jsx';

const textTCStyle = { fontSize: 12, px: '6px' };
const badgeStyle = {whiteSpace: 'nowrap', fontSize: 12};

export default function PoolTableRow({poolHistoryItem, opened = false, diffHistoryItem}) {
    const {
        effect,
        exchangeRates,
        poolData
    } = poolHistoryItem;

    let { 
        currentShares,
        traded,
        tradedAmount,
        earned,
        earnedAmount,
        currentReserves,
        reservesPrice
    } = poolData;

    tradedAmount -= diffHistoryItem?.poolData?.tradedAmount || 0;
    Object.keys(traded).forEach(
        asset => traded[asset] -= (diffHistoryItem?.poolData?.traded || {})[asset] || 0
    );

    earnedAmount -= diffHistoryItem?.poolData?.earnedAmount || 0;
    Object.keys(earned).forEach(
        asset => earned[asset] -= (diffHistoryItem?.poolData?.earned || {})[asset] || 0
    );

    const [open, setOpen] = React.useState(opened);

    return (
        <Fragment>
            <TableRow key={ effect.created_at }>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell size="small" sx={textTCStyle}>{ 
                    poolHistoryItem.date
                        .toLocaleDateString('en-gb', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            // hour: 'numeric',
                            // minute: 'numeric',
                            // second: 'numeric'
                        })
                }</TableCell>
                <TableCell title={ effect.type } size="small" sx={textTCStyle}>{
                    effect.type === 'liquidity_pool_deposited'
                    ? (<ArrowCircleUp />)
                    : (effect.type === 'liquidity_pool_withdrew'
                        ? (<ArrowCircleDown />)
                        : (<CurrencyExchange />)
                    )
                }</TableCell>
                <TableCell size="small" sx={textTCStyle}>{
                    currentReserves
                        .map(r => (<Fragment key={r.asset}><Reserve reserve={r} /><br /></Fragment>))
                }</TableCell>
                <TableCell size="small" sx={textTCStyle}>{ currentShares }</TableCell>
                <TableCell size="small" sx={textTCStyle}>{
                    Object.keys(traded).map(
                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ traded[asset].toFixed(7) }<br /></Fragment>)
                    )
                }</TableCell> 
                <TableCell size="small" sx={textTCStyle}>{
                    Object.keys(earned).map(
                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ earned[asset].toFixed(7) }<br /></Fragment>)
                    )
                }</TableCell>
                {/* <TableCell size="small" sx={textTCStyle} align="right">{
                    // Object.keys(earned).map(
                    //     asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ earned[asset].toFixed(7) }<br /></Fragment>)
                    // )
                    earnedAmount.toFixed(7)
                }&nbsp;USDC</TableCell> */}
                <TableCell size="small" sx={textTCStyle} align="right">{ reservesPrice.toFixed(7) }&nbsp;USDC
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{
                        backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],

                    }}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={9}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid sx={{ mt: 0.1, mb: 1 }} container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        p: 1,
                                        fontSize: 12,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography component="span" sx={badgeStyle}>
                                            {poolHistoryItem.date
                                                .toLocaleDateString('en-gb', {
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric'
                                                })}
                                    </Typography>
                                    <Typography noWrap>
                                        <Typography component="span" color="primary" sx={badgeStyle}>
                                            Pool shares:
                                        </Typography>&nbsp;<Typography component="span" sx={badgeStyle}>
                                            { currentShares }
                                        </Typography>
                                    </Typography>
                                    <Typography>
                                        <Typography component="span" color="primary" sx={badgeStyle}>
                                            Trustlines:
                                        </Typography>&nbsp;
                                        <Typography component="span" sx={{ fontSize: 12 }}>
                                            { effect.liquidity_pool.total_trustlines }
                                        </Typography>
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item sx={badgeStyle} xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        p: 1,
                                        fontSize: 12,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Grid container>
                                        <Grid item sx={badgeStyle} xs={12} lg={6}>
                                            <Typography color="primary" component="b">
                                                Exchange rates:
                                            </Typography>
                                            <Typography variant="p" component="div">
                                                {
                                                    Object.keys(exchangeRates).map(
                                                        asset => (<Fragment key={asset}>1&nbsp;<AssetCode asset={asset} />&nbsp;=&nbsp;{ exchangeRates[asset].toFixed(7) }&nbsp;USDC<br /></Fragment>)
                                                    )
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={badgeStyle} xs={12} lg={6}>
                                            <Typography color="primary" component="b">
                                                &nbsp;
                                            </Typography>
                                            <Typography variant="p" component="div">
                                                1&nbsp;<AssetCode asset={ Object.keys(exchangeRates)[0] } />&nbsp;=&nbsp;{ (Object.values(exchangeRates)[0]/Object.values(exchangeRates)[1]).toFixed(7) }&nbsp;<AssetCode asset={ Object.keys(exchangeRates)[1] } />
                                                <br />
                                                1&nbsp;<AssetCode asset={ Object.keys(exchangeRates)[1] } />&nbsp;=&nbsp;{ (Object.values(exchangeRates)[1]/Object.values(exchangeRates)[0]).toFixed(7) }&nbsp;<AssetCode asset={ Object.keys(exchangeRates)[0] } />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <PooolTableBadge
                                header={ <>Pool reserves:<br /><br /></> }
                                total={ <>{ reservesPrice.toFixed(7) }&nbsp;USDC</> }
                            >
                                {
                                    currentReserves.map(
                                        ({asset, amount}) => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ 
                                            amount
                                        }<br /></Fragment>)
                                    )
                                }
                            </PooolTableBadge>
                            
                            <PooolTableBadge
                                header={ <>Pool trading<br />volume:<br /></> }
                                total={ <>{ tradedAmount.toFixed(7) }&nbsp;USDC</> }
                            >
                                {
                                    Object.keys(traded).map(
                                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{ 
                                            traded[asset].toFixed(7) 
                                        }<br /></Fragment>)
                                    )
                                }
                            </PooolTableBadge>

                            <PooolTableBadge
                                header={ <>Commission<br />earnings:</> }
                                total={ <>{ earnedAmount.toFixed(7) }&nbsp;USDC</> }
                            >
                                {
                                    Object.keys(earned).map(
                                        asset => (<Fragment key={asset}><AssetCode asset={asset} />&nbsp;{
                                            earned[asset].toFixed(7) 
                                        }<br /></Fragment>)
                                    )
                                }
                            </PooolTableBadge>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}
