export function mergePoolHistory(poolId, poolHistory, freshPoolPage) {
    freshPoolPage.records
        .forEach(e => {
            // No sense for us.
            if (!['liquidity_pool_deposited', 'liquidity_pool_withdrew', 'liquidity_pool_trade'].includes(e.type)) {
                return;
            }

            if (e?.liquidity_pool.id !== poolId) {
                return;
            }

            delete(e._links);

            if (!poolHistory.lastState) {
                const dummie = {
                    [e.liquidity_pool.reserves[0].asset]: 0,
                    [e.liquidity_pool.reserves[1].asset]: 0
                };
                poolHistory.lastState = {
                    earned: {
                        ...dummie
                    },
                    traded: {
                        ...dummie
                    },
                    tradesCounter: 0
                }
            }

            // Start of next date
            if (poolHistory.lastState.effect &&
                (new Date(poolHistory.lastState.effect.created_at)).setUTCHours(0,0,0,0)
                    < (new Date(e.created_at)).setUTCHours(0,0,0,0)
            ) {
                if (poolHistory.states.length > 0 && poolHistory.states[poolHistory.states.length-1]?.effect?.id !== poolHistory.lastState.effect.id) {
                    poolHistory.states.push(poolHistory.lastState);
                }
                poolHistory.lastState = {
                    currentReserves: e.liquidity_pool.reserves,
                    currentShares: e.liquidity_pool.total_shares,
                    earned: {...poolHistory.lastState.earned},
                    traded: {...poolHistory.lastState.traded},
                    tradesCounter: poolHistory.lastState.tradesCounter,
                    effect: e
                }
            }

            // if (e.type === 'liquidity_pool_created') {
                // let startTime = new Date(e.created_at).setUTCHours(0,0,0,0);
                // getPriceHistory by e.liquidity_pool.reserves
                // e.liquidity_pool.reserves.forEach(r => getPriceHistory(r.asset, startTime));
            // }
            if (['liquidity_pool_deposited', 'liquidity_pool_withdrew'].includes(e.type) && e.liquidity_pool.id === poolId) {
                poolHistory.states.push({
                    currentReserves: e.liquidity_pool.reserves,
                    currentShares: e.liquidity_pool.total_shares,
                    earned: {...poolHistory.lastState.earned},
                    traded: {...poolHistory.lastState.traded},
                    tradesCounter: poolHistory.lastState.tradesCounter,
                    effect: e
                });
                poolHistory.lastState = {
                    currentReserves: e.liquidity_pool.reserves,
                    currentShares: e.liquidity_pool.total_shares,
                    earned: {...poolHistory.lastState.earned},
                    traded: {...poolHistory.lastState.traded},
                    tradesCounter: poolHistory.lastState.tradesCounter,
                    effect: e
                };
                return;
            }

            if (e.type === 'liquidity_pool_trade') {
                // accumulation of trading info
                poolHistory.lastState
                    .earned[e.sold.asset] += parseFloat(e.sold.amount) * 0.003;
                poolHistory.lastState
                    .traded[e.sold.asset] += parseFloat(e.sold.amount);
                poolHistory.lastState
                    .traded[e.bought.asset] += parseFloat(e.bought.amount);

                poolHistory.lastState.tradesCounter++;
                poolHistory.lastState.effect = e;
                poolHistory.lastState.currentShares = e.liquidity_pool.total_shares;
                poolHistory.lastState.currentReserves = e.liquidity_pool.reserves;
            }
        });
    return poolHistory;
}
