import { useSelector } from 'react-redux';
import {
    Box,
    Grid,
    Paper
} from '@mui/material';
import WalletAssetBadge from './WalletAssetBadge.jsx';
import AssetsPie from './AssetsPie.jsx';

export default function WalletAssetsDashboard() {
    const balances = useSelector((state) => state.wallet.balances);
    const assetsArray = (balances || []).filter(b => (b.asset_type !== 'liquidity_pool_shares'));

    return (<>
        {
            (balances || []).filter(b => (b.asset_type !== 'liquidity_pool_shares')).length > 0 && (
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                        height: 675,
                        textAlign: 'center'
                        }}
                    >
                        <Box sx={{p:2, height: 675, maxHeight:675, maxWidth:640, display: 'inline-block', width: '100%' }}>
                            <AssetsPie />
                        </Box>
                    </Paper>
                </Grid>
            )
        }
        <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
            { 
                assetsArray.slice(0, 6)
                    .map((balance, idx) => (<Grid key={ balance.asset_issuer || 'native' } item xs={12} md={6} lg={6}>
                        <Paper
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 210,
                            }}
                        >
                            <WalletAssetBadge balance={ balance } />
                        </Paper>
                    </Grid>))
            }
            </Grid>
        </Grid>
        { assetsArray.length > 6 && assetsArray.slice(6)
            .map((balance, idx) => (<Grid key={ balance.asset_issuer || 'native' } item xs={12} md={6} lg={3}>
                <Paper
                    sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    }}
                >
                    <WalletAssetBadge balance={ balance } />
                </Paper>
            </Grid>)) 
        }
    </>);
}