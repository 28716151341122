import React from 'react';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import AssetCode from '../assets/AssetCode';
import { getAssetPrice } from '../../lib/assetPrices';

const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Pool reserves assets cost in USDC chart',
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          color: 'rgba(255, 99, 132, 1)',
          text: '',
        },
        ticks: {
            color: 'rgba(255, 99, 132, 1)',
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
                return value + 'USDC';
            }
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          color: 'rgba(54, 162, 235, 1)',
          text: '',
        },
        ticks: {
            color: 'rgba(54, 162, 235, 1)',
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
                return value + 'USDC';
            }
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export default function ReservesPriceChart({reserves, startDate, endDate}) {
    const priceHistory = useSelector(state => state.prices.priceHistory) || {};

    options.scales.y.title.text = AssetCode({asset: reserves[0].asset});
    options.scales.y1.title.text = AssetCode({asset: reserves[1].asset});

    let limitEndDate = new Date(endDate.valueOf());
    limitEndDate.setDate(endDate.getDate() +1);

    let asset1prices = [], asset2prices = [], currentDate = new Date(startDate.valueOf());

    while (currentDate < limitEndDate) {
        asset1prices.push({
            date: new Date(currentDate.valueOf()),
            price: getAssetPrice(priceHistory, currentDate.valueOf(), reserves[0].asset),
        });
        asset2prices.push({
          date: new Date(currentDate.valueOf()),
          price: getAssetPrice(priceHistory, currentDate.valueOf(), reserves[1].asset),
        });
        // Next day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    const labels = asset1prices.map(({date}) => date.toLocaleDateString('en-gb', {year: 'numeric', month: 'numeric', day: 'numeric'}));
    
    const data = {
        labels,
        datasets: [
          {
            label: AssetCode({asset: reserves[0].asset}),
            data: asset1prices.map(({price}) => (price)),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y',
          },
          {
            label: AssetCode({asset: reserves[1].asset}),
            data: asset2prices.map(({price}) => (price)),
            borderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 3,
            yAxisID: 'y1',
          },
        ],
    };
    return (<Line options={options} data={data} />);
}