import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPoolById }  from '../pools/poolsSlice';
import { fetchAssetLastPrice } from '../prices/pricesSlice';
import {
    GRANT_ALL,
    PAY_ASSET,
    PAY_WALLET,
    PAY_ASSET_HODL_AMOUNT,
    PAY_ASSET_HODL_PERIOD,
    PAY_ASSET_LOCK_AMOUNT,
    PAY_ASSET_LOCK_PERIOD,
    PAY_VARIANTS
} from '../../app/constants';
import {
    isBalanceCertainAsset
} from '../../lib/assets';

// Waiting for Webpack ^5.0.0 usage in stellar-sdk
// import * as StellarSdk from 'stellar-sdk';
const StellarSdk = window.StellarSdk;
const server = new StellarSdk.Server('https://horizon.stellar.org');

const payAssetStr = `${PAY_ASSET.asset_code}:${PAY_ASSET.asset_issuer}`;

export const fetchAccountById = createAsyncThunk(
    'wallet/fetchAccountById',
    async (accountId, thunkAPI) => {
      if (!StellarSdk.StrKey.isValidEd25519PublicKey(accountId)) {
        return null;
      }

      const auth = thunkAPI.getState().wallet.auth;
      if (auth.accountId && auth.accountId !== accountId) {
        thunkAPI.dispatch(authCleanup(accountId));
      }

      const account = await server.loadAccount(accountId);

      setTimeout(() => thunkAPI.dispatch(loadClaimableBalances(accountId)), 100);

      // Get pools data
      account.balances.filter(b => (b.asset_type === 'liquidity_pool_shares'))
        .forEach(({liquidity_pool_id}) => {
          thunkAPI.dispatch(fetchPoolById(liquidity_pool_id));
        });
      // Get assets last Prices
      const prices = thunkAPI.getState().prices;
      account.balances.forEach(b => {
        if (['native', 'credit_alphanum4', 'credit_alphanum12'].includes(b.asset_type)) {
          const asset = b.asset_type === 'native' ? 'native' : `${b.asset_code}:${b.asset_issuer}`;
          if (!prices.lastPrice[asset] && !prices.lastPriceStatuses[asset]) {
            thunkAPI.dispatch(fetchAssetLastPrice(asset));
          }
          if (isBalanceCertainAsset(b, PAY_ASSET)) {
                setTimeout(() => thunkAPI.dispatch(checkPermsByHodl(accountId)), 2000);
          }
        }
      });
      setTimeout(() => thunkAPI.dispatch(checkPermsByPay(accountId)), 100);
      setTimeout(() => thunkAPI.dispatch(checkPermsByLock(accountId)), 100);

      return account;
    }
);

export const loadClaimableBalances = createAsyncThunk(
  'wallet/loadClaimableBalances',
  async (accountId, thunkAPI) => {
    let claimableBalances = [];
    let resp = await server.claimableBalances()
      .claimant(accountId)
      .limit(200)
      .join('transactions')
      .call()
      .catch(err => {
        // eslint-disable-next-line eqeqeq
        if (err?.response?.status == 503) {
          setTimeout(() => thunkAPI.dispatch(loadClaimableBalances(accountId)), 100);
          return;
        } else {
            console.log(err);
        }
      });

      do {
        claimableBalances = claimableBalances.concat(resp.records);

        if (resp.records.length < 200 || !resp.next) {
          break;
        }
        resp = await resp.next()
          .catch(err => {
            // eslint-disable-next-line eqeqeq
            if (err?.response?.status == 503) {
              setTimeout(() => thunkAPI.dispatch(loadClaimableBalances(accountId)), 100);
              return;
            } else {
                console.log(err);
            }
          });
      } while (resp?.next);

      thunkAPI.dispatch(setClaimableBalances(claimableBalances));
      // thunkAPI.dispatch(checkPermsByLock({accountId, claimableBalances}));
  }
);

export const checkPermsByPay = createAsyncThunk(
  'wallet/checkPermsByPay',
  async (accountId, thunkAPI) => {
    const walletMemoPart = `w${accountId.substr(1,10)}${accountId.substr(-10)}`;

    let resp = await server
          .transactions()
          .forAccount(PAY_WALLET)
          .cursor('now')
          .limit(200)
          .order('desc')
          .call()
          .catch(err => {
            // eslint-disable-next-line eqeqeq
            if (err?.response?.status == 503) {
              setTimeout(() => thunkAPI.dispatch(checkPermsByPay(accountId)), 100);
              return;
            } else {
                console.log(err);
            }
          });
    // console.log(resp.records);

    let dateLimit = new Date();
    dateLimit.setDate(dateLimit.getDate() - PAY_VARIANTS[PAY_VARIANTS.length - 1].duration);

    if (!resp) {
      return {
        accountId,
        amount: 0,
        validUntil: 0
      };
    }

    let currentDate = new Date();

    do {
      for (let i = 0; i < resp.records.length; i++) {
        const t = resp.records[i];

        currentDate = new Date(t.created_at);
        if (currentDate < dateLimit) {
          return {
            accountId,
            amount: 0,
            validUntil: 0
          };
        }

        const matches = (t?.memo || '').match(/^p(\d{1,2})d(\d{1,3})(w.{20})?$/);
        if (matches) {
          const payVariant = PAY_VARIANTS.find(v => (v.duration === parseInt(matches[1]) && parseFloat(v.amount) === parseFloat(matches[2])));
          if (!payVariant) {
            continue;
          }
          if (matches[3]) {
            if (walletMemoPart !== matches[3]) {
              // alien wallet
              continue;
            }
          }
          let payedLimit = new Date();
          payedLimit.setDate(payedLimit.getDate() - payVariant.duration);
          if (currentDate < payedLimit) {
            continue;
          }

          const ops = await t.operations();
          for (let k = 0; k < ops.records.length; k++) {
            const o = ops.records[k];
            if (isBalanceCertainAsset(o, PAY_ASSET)
              && parseFloat(o.amount) === parseFloat(payVariant.amount)
              && (matches[3] || accountId === o.from)
            ) {
              const validUntil = currentDate;
              validUntil.setDate(currentDate.getDate() + payVariant.duration);
              return {
                accountId,
                amount: payVariant.amount,
                validUntil
              }
            }
          }
        }
      }
      if (resp.records.length < 200) {
        break;
      }
      resp = await resp.next();
    } while (resp?.next);

    return {
      accountId,
      amount: 0,
      validUntil: 0
    };
  });

function daysDuration(date1, date2) {
  return Math.floor((date2.getTime() - date1.getTime())/86400000); //let day = 1000 * 60 * 60 * 24;
}

export const checkPermsByHodl = createAsyncThunk(
  'wallet/checkPermsByHodl',
  async (accountId, thunkAPI) => {
      const state = thunkAPI.getState().wallet;
      const pools = thunkAPI.getState().pools.pools;
      const assetStr = PAY_ASSET.asset_type === 'native' ? 'native' : `${PAY_ASSET.asset_code}:${PAY_ASSET.asset_issuer}`;
      for (let pid in state.pools) {
        if (!pools[pid]) {
          // not all pools are loaded!
          setTimeout(() => thunkAPI.dispatch(checkPermsByHodl(accountId)), 1000);
          return;
        }
      }
      let payAssetPoolsObject = {};
      Object.keys(pools).forEach(poolId => {
        if (state.pools[poolId] && pools[poolId].reserves.find(r => (r.asset === assetStr))) {
          payAssetPoolsObject[poolId] = {
            ...pools[poolId],
            walletBalance: state.pools[poolId].balance
          }
        }
      });
      let balanceInPools = Object.values(payAssetPoolsObject).length > 0
          ? Object.values(payAssetPoolsObject).reduce((total, {id, total_shares, reserves, walletBalance}) => {
              return total + parseFloat(reserves.filter(r => r.asset === assetStr)[0].amount)*walletBalance/total_shares;
              },
              0
          )
          : 0;
      let balance = parseFloat(state.balances.find(b=> isBalanceCertainAsset(b, PAY_ASSET))?.balance || 0);
      let minBalance = balance + balanceInPools;
      if (minBalance < PAY_ASSET_HODL_AMOUNT) {
        return {
          accountId,
          minBalance,
          duration: 0
        };
      }
      let dateLimit = new Date();
      dateLimit.setDate(dateLimit.getDate() - PAY_ASSET_HODL_PERIOD);
      let resp = await server
          .effects()
          .forAccount(accountId)
          .cursor('now')
          .limit(200)
          .order('desc')
          .call()
          .catch(err => {
            // eslint-disable-next-line eqeqeq
            if (err?.response?.status == 503) {
              setTimeout(() => thunkAPI.dispatch(checkPermsByHodl(accountId)), 100);
              return;
            } else {
                console.log(err);
            }
          });

      if (!resp) {
        return {
          accountId,
          minBalance,
          duration: 0
        };
      }

      let currentDate = new Date();
      do {
        for (let i = 0; i < resp.records.length; i++) {
          const e = resp.records[i];
          currentDate = new Date(e.created_at);
          if (currentDate < dateLimit) {
            break;
          }
          if (e.account === accountId) {
            
            if (['account_credited', 'account_debited'].includes(e.type)
              && isBalanceCertainAsset(e, PAY_ASSET)
            ) {
              if (e.type === 'account_debited') {
                balance += parseFloat(e.amount);
              }
              if (e.type === 'account_credited') {
                balance -= parseFloat(e.amount);
              }
            }
            if (['liquidity_pool_deposited', 'liquidity_pool_withdrew'].includes(e.type)
              && !!e.liquidity_pool.reserves.find(r => r.asset === payAssetStr)
            ) {
              if (e.type === 'liquidity_pool_deposited') {
                let amount = parseFloat(e.reserves_deposited.find(r => r.asset === payAssetStr).amount);
                payAssetPoolsObject[e.liquidity_pool.id].walletBalance -= parseFloat(e.shares_received);
                // shares_received
                payAssetPoolsObject[e.liquidity_pool.id].reserves = e.liquidity_pool.reserves.map(
                  r => ({asset: r.asset, amount: parseFloat(r.amount) - parseFloat(e.reserves_deposited.find(er => er.asset === r.asset).amount)})
                );
                payAssetPoolsObject[e.liquidity_pool.id].total_shares = parseFloat(e.liquidity_pool.total_shares) - parseFloat(e.shares_received);

                // balanceInPools -= amount;
                balanceInPools = Object.values(payAssetPoolsObject).reduce((total, {id, total_shares, reserves, walletBalance}) => {
                    return total + parseFloat(reserves.filter(r => r.asset === assetStr)[0].amount)*walletBalance/total_shares;
                    },
                    0
                );
                balance += amount;
              }
              if (e.type === 'liquidity_pool_withdrew') {
                let amount = parseFloat(e.reserves_received.find(r => r.asset === payAssetStr).amount);
                if (!payAssetPoolsObject[e.liquidity_pool.id]) {
                  payAssetPoolsObject[e.liquidity_pool.id] = {
                    walletBalance: 0,
                    reserves: [],
                    total_shares: 0
                  }
                }

                payAssetPoolsObject[e.liquidity_pool.id].walletBalance += parseFloat(e.shares_redeemed);
                payAssetPoolsObject[e.liquidity_pool.id].reserves = e.liquidity_pool.reserves.map(
                  r => ({asset: r.asset, amount: parseFloat(r.amount) + parseFloat(e.reserves_received.find(er => er.asset === r.asset).amount)})
                );
                payAssetPoolsObject[e.liquidity_pool.id].total_shares = parseFloat(e.liquidity_pool.total_shares) + parseFloat(e.shares_redeemed);

                // balanceInPools -= amount;
                balanceInPools = Object.values(payAssetPoolsObject).reduce((total, {id, total_shares, reserves, walletBalance}) => {
                    return total + parseFloat(reserves.filter(r => r.asset === assetStr)[0].amount)*walletBalance/total_shares;
                    },
                    0
                );
                // shares_redeemed

                balance -= amount;
              }
            }

            if ((balance + balanceInPools) < minBalance) {
              minBalance = balance + balanceInPools;
            }
            if (minBalance < PAY_ASSET_HODL_AMOUNT) {
              return {
                accountId,
                minBalance,
                duration: daysDuration(currentDate, new Date())
              };
            }
          }
        }
        if (resp.records.length < 200 || currentDate < dateLimit) {
          break;
        }
        resp = await resp.next();
      } while (resp?.next);

      return {
        accountId,
        minBalance,
        duration: daysDuration(currentDate, new Date())
      };
  }
);

export const checkPermsByLock = createAsyncThunk(
  'wallet/checkPermsByLock',
  async (accountId, thunkAPI) => {
    let resp = await server.claimableBalances()
      .claimant(accountId)
      .asset(new StellarSdk.Asset(PAY_ASSET.asset_code, PAY_ASSET.asset_issuer))
      .limit(200)
      .call()
      .then()
      .catch(err => {
        // eslint-disable-next-line eqeqeq
        if (err?.response?.status == 503) {
          setTimeout(() => thunkAPI.dispatch(checkPermsByLock(accountId)), 100);
          return;
        } else {
            console.log(err);
        }
      });
    let amount = 0;
    const period = PAY_ASSET_LOCK_PERIOD * 3600 * 24000;
    do {
      amount += resp.records.reduce((total, cb) => (total
        + ((cb.claimants && cb.claimants[0]?.predicate?.not?.abs_before
            && new Date(cb.claimants[0].predicate.not.abs_before).getTime() - new Date(cb.last_modified_time).getTime() >= period
          )
          ? parseFloat(cb.amount)
          : 0)
      ),
      0);

      if (resp.records.length < 200) {
        break;
      }
      resp = await resp.next();
    } while (resp?.next);

    return {
      accountId,
      amount,
    };
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    accountId: '',
    account: null,
    balances: [],
    claimableBalances: [],
    pools: {},
    auth: {
      accountId: '',
      checkStatus: {
        byHodl: undefined,
        byPay: undefined,
        byLock: undefined
      },
      granted: false,
      hasPayAsset: false,
      hodl: false, // minBalance, duration
      payed: false,  // validUntil, amount
      lock: false // amount
    },
    fetchStatus: null
  },
  reducers: {
    authCleanup(state, action) {
      state.auth = {
        accountId: '',
        checkStatus: {
          byHodl: undefined,
          byPay: undefined,
          byLock: undefined
        },
        granted: GRANT_ALL,
        hasPayAsset: false,
        hodl: false, // minBalance, duration
        payed: false,  // validUntil, amount
        lock: false // amount
      }
    },
    setClaimableBalances(state, action) {
      state.claimableBalances = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountById.pending, (state) => {
        state.fetchStatus = 'pending';
    });
    builder.addCase(fetchAccountById.rejected, (state) => {
        state.fetchStatus = 'error';
    });
    builder.addCase(fetchAccountById.fulfilled, (state, action) => {
        if (!action.payload) {
            return;
        }
        state.fetchStatus = 'ready';
        state.account = action.payload;
        state.accountId = action.payload.account_id;
        state.balances = action.payload.balances;
        let pools = {};
        action.payload.balances.filter(b => (b.asset_type === 'liquidity_pool_shares'))
            .forEach(balance => (pools[balance.liquidity_pool_id] = {...balance}));
        state.pools = pools;
        state.auth.hasPayAsset = action.payload
          .balances.filter(b => isBalanceCertainAsset(b, PAY_ASSET))
          .length > 0;
        state.auth.accountId = state.account.account_id;
    });

    builder.addCase(checkPermsByHodl.pending, (state) => {
      state.auth.checkStatus.byHodl = 'pending';
    });

    builder.addCase(checkPermsByHodl.rejected, (state) => {
      state.auth.checkStatus.byHodl = 'error';
    });

    builder.addCase(checkPermsByHodl.fulfilled, (state, action) => {
      if (!action.payload) {
        state.auth.checkStatus.byHodl = 'error';
        return;
      }

      state.auth.checkStatus.byHodl = 'ready';
      state.auth.accountId = action.payload.accountId;
      state.auth.hodl = action.payload;

      state.auth.granted = GRANT_ALL
        || (state.auth.lock?.amount >= PAY_ASSET_LOCK_AMOUNT && state.auth.lock?.accountId === action.payload.accountId)
        || (state.auth.payed?.validUntil && state.auth.payed.validUntil > new Date() && state.auth.payed?.accountId === action.payload.accountId)
        || (state.auth.hodl?.minBalance >= PAY_ASSET_HODL_AMOUNT && state.auth.hodl?.accountId === action.payload.accountId)
      ;
    });

    builder.addCase(checkPermsByPay.pending, (state) => {
      state.auth.checkStatus.byPay = 'pending';
    });

    builder.addCase(checkPermsByPay.rejected, (state) => {
      state.auth.checkStatus.byPay = 'error';
    });

    builder.addCase(checkPermsByPay.fulfilled, (state, action) => {
      if (!action.payload) {
        state.auth.checkStatus.byPay = 'error';
        return;
      }
      state.auth.checkStatus.byPay = 'ready';
      state.auth.payed = action.payload;
      state.auth.accountId = action.payload.accountId;

      state.auth.granted = GRANT_ALL
        || (state.auth.lock?.amount >= PAY_ASSET_LOCK_AMOUNT && state.auth.lock?.accountId === action.payload.accountId)
        || (state.auth.payed?.validUntil && state.auth.payed.validUntil > new Date() && state.auth.payed?.accountId === action.payload.accountId)
        || (state.auth.hodl?.minBalance >= PAY_ASSET_HODL_AMOUNT && state.auth.hodl?.accountId === action.payload.accountId)
      ;
    });

    builder.addCase(checkPermsByLock.pending, (state) => {
      state.auth.checkStatus.byLock = 'pending';
    });

    builder.addCase(checkPermsByLock.rejected, (state) => {
      state.auth.checkStatus.byLock = 'error';
    });

    builder.addCase(checkPermsByLock.fulfilled, (state, action) => {
      if (!action.payload) {
        state.auth.checkStatus.byLock = 'error';
        return;
      }

      state.auth.checkStatus.byLock = 'ready';


      state.auth.accountId = action.payload.accountId;
      state.auth.lock = action.payload;
      state.auth.granted = GRANT_ALL
        || (state.auth.lock?.amount >= PAY_ASSET_LOCK_AMOUNT && state.auth.lock?.accountId === action.payload.accountId)
        || (state.auth.payed?.validUntil && state.auth.payed.validUntil > new Date() && state.auth.payed?.accountId === action.payload.accountId)
        || (state.auth.hodl?.minBalance >= PAY_ASSET_HODL_AMOUNT && state.auth.hodl?.accountId === action.payload.accountId)
      ;
    });
  }
});

export const { authCleanup, setClaimableBalances } = walletSlice.actions;

export default walletSlice.reducer;