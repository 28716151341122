import { useSelector } from 'react-redux';
import {
  ListItemIcon,
  ListItemText,
}           from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import LeftMenuItem from './LeftMenuItem.jsx';

export default function LeftMainMenu() {
    const wallet = useSelector(state => state.root.wallet);
    return (
        <>
            <LeftMenuItem to={ wallet ? `/wallet/${wallet}` : '/' }>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </LeftMenuItem>
            <LeftMenuItem to="/pool/custom">
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Custom Pool" />
            </LeftMenuItem>
        </>
    );
}
